import React, { useEffect } from "react";
import MobileLayoutRenderer from "./layout/MobileLayoutRenderer";
import "../../assets/styles/pages/itemandreasonforrefund.scss";
import "react-dropdown/style.css";
import { useState } from "react";
import { connect } from "react-redux";

import { useTranslation } from "react-i18next";
import { Redirect, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { apiJson } from "../../Api";
import { ClipLoader } from "react-spinners";
import { set_loading } from "../../redux/actions/LayoutAction";
import Select from "react-select";
import { getMobileImage } from "../../utils/utils";

const ItemAndReasonForRefund = (props) => {
  const [item, setItem] = useState(undefined);
  const param = useParams();

  const { t } = useTranslation();
  const [redirect, setRedirect] = useState({ value: false });
  const [loading, setLoading] = useState(false);
  const [quantity, setQuantity] = useState("");
  const [description, setDescription] = useState("");
  const refundOptions_en = [
    { value: "Refund to wallet", label: "Refund to wallet" },
    { value: "Refund to bank account", label: "Refund to bank account" },
  ];
  const refundOptions_ar = [
    { value: "رد الأموال إلى المحفظة", label: "رد الأموال إلى المحفظة" },
    { value: "رد الأموال إلى حساب مصرفي", label: "رد الأموال إلى حساب مصرفي" },
  ];
  const [refundMethod, setRefundMethod] = useState(
    props.language === "en" ? refundOptions_en[0] : refundOptions_ar[0]
  );

  let message = "";
  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  useEffect(() => {
    document.title = "Refund Items";

    props.set_loading(true);

    apiJson.orderItemsList.getItems().then((response) => {
      props.set_loading(false);
      if (response.success) {
        setItem(
          response.data.filter((item) => item.id === parseInt(param.id))[0]
        );
      } else if (!response.status) {
        // eslint-disable-next-line
        message = response.message
          ? response.message !== ""
            ? response.message
            : t("errors.wrong")
          : t("errors.wrong");
        showError();
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
              ? response.errors
              : t("errors.wrong");
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        }
      }
    });
  }, []);

  const Create = () => {
    setLoading(true);
    param.name === "disputes"
      ? apiJson.disputes
        .createDispute({
          dispute: {
            order_id: item.order_id,
            line_item_id: item.id,
            vendor_id: item.vendor_id,
            title: "",
            description: description,
          },
          role: "customer",
        })
        .then((response) => {
          if (response.success) {
            setLoading(false);
            setRedirect({
              value: true,
              path:
                "/" + props.countryUrl + "-" + props.language + "/disputes",
            });
          } else if (!response.status) {
            setLoading(false);
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          } else {
            setLoading(false);
            if (response.status >= 400 && response.status < 500) {
              message = response.message
                ? response.message
                : response.errors
                  ? response.errors
                  : t("errors.wrong");
              showError();
            } else {
              message = response.message
                ? response.message !== ""
                  ? response.message
                  : t("errors.wrong")
                : t("errors.wrong");
              showError();
            }
          }
        })
      : apiJson.returnItems
        .createRefund({
          refund: {
            reason: description,
            quantity: parseInt(quantity),
            order_id: item.order_id,
            line_item_id: item.id,
            vendor_id: item.vendor_id,
            refund_method:
              props.language === "ar"
                ? refundMethod.value === refundOptions_ar[0].value
                  ? "wallet"
                  : "bank"
                : refundMethod.value === refundOptions_en[0].value
                  ? "wallet"
                  : "bank",
          },
          address: {
            country: item.customer_address.country,
            state: item.customer_address.state,
            address1: item.customer_address.address1,
            contact_number: item.customer_address.contact_number,
          },
        })
        .then((response) => {
          if (response.success) {
            setLoading(false);
            setRedirect({
              value: true,
              path:
                "/" + props.countryUrl + "-" + props.language + "/returns",
            });
          } else if (!response.status) {
            setLoading(false);
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          } else {
            setLoading(false);
            if (response.status >= 400 && response.status < 500) {
              message = response.message
                ? response.message
                : response.errors
                  ? response.errors
                  : t("errors.wrong");
              showError();
            } else {
              message = response.message
                ? response.message !== ""
                  ? response.message
                  : t("errors.wrong")
                : t("errors.wrong");
              showError();
            }
          }
        });
  };
  useEffect(() => {
    if (props.language?.toLowerCase() === "en") {
      if (refundMethod.value === refundOptions_ar[0].value) {
        setRefundMethod(refundOptions_en[0]);
      } else if (refundMethod.value === refundOptions_ar[1].value) {
        setRefundMethod(refundOptions_en[1]);
      }
    } else {
      if (refundMethod.value === refundOptions_en[0].value) {
        setRefundMethod(refundOptions_ar[0]);
      } else if (refundMethod.value === refundOptions_en[1].value) {
        setRefundMethod(refundOptions_ar[1]);
      }
    }
    // eslint-disable-next-line
  }, [props.language]);

  const onReasonChanged = (e) => {
    setDescription(e.target.value);
  }

  return (
    <>
      {(param.name === "disputes" || param.name === "refund") && (
        <MobileLayoutRenderer
          NavbarTop={true}
          BackBtn={true}
          CancelBtn={false}
          NavbarBottom={false}
          SearchIcon={true}
        >
          {item?.orderable && props.width === "sm" && (
            <div className="item-and-reason-for-refund-main-mobile">
              <div className="list-of-items-refunds">
                <div
                  key={item.ordre_id}
                  style={{
                    width: "70px",
                    height: "70px",
                    marginInlineEnd: "5px",
                    marginInlineStart: "5px",
                  }}
                  className="item d-flex justify-content-center mt-3 mb-3"
                >
                  <img
                    src={getMobileImage(
                      item.orderable.featured_image_resized,
                      item.orderable.featured_image
                    )}
                    alt=""
                    style={{ width: "auto", maxWidth: "70px", height: "70px" }}
                  />
                </div>
                <div
                  className="description-container mt-3"
                  style={{ paddingInlineEnd: "5px", paddingInlineStart: "5px" }}
                >
                  <span className="description">
                    {(item.orderable_type === "Product"
                      ? item.orderable[`product_name_${props.language}`]
                      : "") +
                      " " +
                      item.orderable[`name_${props.language}`]}
                  </span>
                  <p className="order-id m-0">
                    {t("order-items-list.order")} {item.order_id}
                  </p>
                  <p className="order-id mt-0">
                    {t("returns.quantity-purchased")}: {item.quantity}
                  </p>
                </div>
              </div>
              {param.name === "refund" && (
                <div className="third-row py-3d-flex align-items-center ">
                  <span className="desc-label w-30">
                    {t("create-dispute.refund-method")}
                  </span>
                  <div className="w-65">
                    {item.payment_methods?.payment_card ? (
                      <div className="select">
                        <Select
                          value={refundMethod}
                          onChange={(value) => setRefundMethod(value)}
                          options={
                            props.language === "en"
                              ? refundOptions_en
                              : refundOptions_ar
                          }
                          isSearchable={false}
                        />
                      </div>
                    ) : (
                      refundMethod.value
                    )}
                  </div>
                </div>
              )}

              {
                param.name === "refund" && (
                  <div className="third-row py-3">
                    <span
                      className="desc-label"
                      style={{
                        width: param.name === "refund" ? "30%" : "35%",
                      }}
                    >
                      {t("returns.quantity")}
                    </span>
                    <div className="write-desc-box">
                      <input
                        name="quantity"
                        type="number"
                        className="form-control"
                        value={quantity}
                        onChange={(e) => setQuantity(e.target.value)}
                        rows={5}
                        style={{ padding: "5px" }}
                      />
                    </div>
                  </div>
                )
              }

              <div className="third-row py-3">
                <span
                  className="desc-label"
                  style={{
                    width: param.name === "refund" ? "30%" : "35%",
                  }}
                >
                  {param.name === "refund"
                    ? t("create-dispute-mobile.reason")
                    : t("create-dispute-mobile.description")}
                </span>
                <div>
                  <label>
                    <input
                      type="radio"
                      value="Product doesn't meet expectations"
                      checked={description == "Product doesn't meet expectations" ? true : false}
                      onChange={onReasonChanged}
                    />&nbsp;Product doesn't meet expectations
                  </label><br />
                  <label>
                    <input
                      type="radio"
                      value="Inaccurate website description"
                      checked={description == "Inaccurate website description" ? true : false}
                      onChange={onReasonChanged}
                    />&nbsp;Inaccurate website description
                  </label><br />
                  <label>
                    <input
                      type="radio"
                      value="Better price available"
                      checked={description == "Better price available" ? true : false}
                      onChange={onReasonChanged}
                    />&nbsp;Better price available
                  </label><br />
                  <label>
                    <input
                      type="radio"
                      value="Bought by mistake"
                      checked={description == "Bought by mistake" ? true : false}
                      onChange={onReasonChanged}
                    />&nbsp;Bought by mistake
                  </label><br />
                  <label>
                    <input
                      type="radio"
                      value="No longer needed"
                      checked={description == "No longer needed" ? true : false}
                      onChange={onReasonChanged}
                    />&nbsp;No longer needed
                  </label><br />
                  <label>
                    <input
                      type="radio"
                      value="Wrong item was sent"
                      checked={description == "Wrong item was sent" ? true : false}
                      onChange={onReasonChanged}
                    />&nbsp;Wrong item was sent
                  </label><br />
                  <label>
                    <input
                      type="radio"
                      value="Item arrived too late"
                      checked={description == "Item arrived too late" ? true : false}
                      onChange={onReasonChanged}
                    />&nbsp;Item arrived too late
                  </label><br />
                  <label>
                    <input
                      type="radio"
                      value="Didn't approve purchase"
                      checked={description == "Didn't approve purchase" ? true : false}
                      onChange={onReasonChanged}
                    />&nbsp;Didn't approve purchase
                  </label><br />
                  <label>
                    <input
                      type="radio"
                      value="Product and shipping box both damaged"
                      checked={description == "Product and shipping box both damaged" ? true : false}
                      onChange={onReasonChanged}
                    />&nbsp;Product and shipping box both damaged
                  </label><br />
                  <label>
                    <input
                      type="radio"
                      value="Missing or broken parts"
                      checked={description == "Missing or broken parts" ? true : false}
                      onChange={onReasonChanged}
                    />&nbsp;Missing or broken parts
                  </label><br />
                  <label>
                    <input
                      type="radio"
                      value="Received extra item I didn't buy (no refund needed)"
                      checked={description == "Received extra item I didn't buy (no refund needed)" ? true : false}
                      onChange={onReasonChanged}
                    />&nbsp;Missing or broken parts
                  </label><br />
                  <label>
                    <input
                      type="radio"
                      value="Product damaged, but shipping box OK"
                      checked={description == "Product damaged, but shipping box OK" ? true : false}
                      onChange={onReasonChanged}
                    />&nbsp;Product damaged, but shipping box OK
                  </label><br />
                  <label>
                    <input
                      type="radio"
                      value="Incompatible or not useful"
                      checked={description == "Incompatible or not useful" ? true : false}
                      onChange={onReasonChanged}
                    />&nbsp;Incompatible or not useful
                  </label><br />
                  <label>
                    <input
                      type="radio"
                      value="Item defective or doesn't work"
                      checked={description == "Item defective or doesn't work" ? true : false}
                      onChange={onReasonChanged}
                    />&nbsp;Item defective or doesn't work
                  </label>
                </div>
              </div>
              <div onClick={() => Create()} className="inner-second">
                <button className="create-button">
                  {loading ? (
                    <div className="d-flex justify-content-center align-items-center w-100">
                      <ClipLoader color="#fff" loading size={20} />
                    </div>
                  ) : (
                    t("create-dispute-mobile.create")
                  )}
                </button>
              </div>
            </div>
          )}

          {redirect.value && <Redirect push to={redirect.path} />}
        </MobileLayoutRenderer>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    country: state.language.country,
    language: state.language.language,
    direction: state.language.direction,
    countryUrl: state.language.countryUrl,
    width: state.layout.screenWidth,
  };
};

const mapDispatchToProps = { set_loading };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ItemAndReasonForRefund);
