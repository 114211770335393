import React from "react";
import ProductDetailCard from "../../shared/ProductDetailCard";
import { connect } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";
import "../../../../assets/styles/mobile/customer/returns/returnitemmobile.scss";
import { getMobileImage } from "../../../../utils/utils";

const ReturnItemMobile = (props) => {
  const { t } = useTranslation();
  const { item } = props;

  return (
    <>
      <div className="return-item-mobile mb-3 pb-3">
        <div className="header py-3 mt-3 px-3">
          <div className="id-row text-uppercase">
            {t("returns-mobile.ret-id")} {item.id}
          </div>
          <div className="date-row">
            {t("returns-mobile.placed-on")}{" "}
            {moment(new Date(item.created_at)).format("ll")}
          </div>
        </div>
        <div className="pickup-address px-3 py-3">
          <div className="title text-uppercase">
            {t("returns-mobile.pickup-adrs")}
          </div>
          <div className="name mt-3">
            {item.address.first_name}
          </div>
          <div className="address">{item.address.address1}</div>
          <div className="phone">{item.address.contact_number}</div>
        </div>

        <ProductDetailCard
          name={
            item.line_item[`product_name_${props.language}`]
              ? item.line_item[`product_name_${props.language}`] +
                " " +
                item.line_item[`variant_name_${props.language}`]
              : item.line_item[`service_name_${props.language}`]
          }
          brand={item.line_item[`store_name_${props.language}`]}
          price={item.line_item.price}
          currency={item.line_item.currency}
          image_path={getMobileImage(
            item.line_item.featured_image_resized,
            item.line_item.featured_image
          )}
          sku={item.line_item.sku}
          quantity={item.quantity}
          variant_price={item.line_item.variant_price}
        />

        <div className="return-details px-3 pt-1">
          <div>
            {t("returns-mobile.ret-status")}
            {": "}
            <span className="status">{t(`returns.status.${item.status}`)}</span>
          </div>
          <div className="reason-wrapper mt-2">
            {t("returns-mobile.reason")}
            <div className="reason">{item.reason}</div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    language: state.language.language,
  };
};

export default connect(mapStateToProps, null)(ReturnItemMobile);
