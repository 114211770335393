import React, { useState, useEffect } from "react";
import "../../assets/styles/component/mobile/wishlistcardmobile.scss";
import { MdDelete } from "react-icons/md";
import { Row } from "reactstrap";
import { BiCartAlt } from "react-icons/bi";
import { FaRegHeart, FaHeart } from "react-icons/fa";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import Select from "react-select";
import { set_cart_items, set_wish_list } from "../../redux/actions/CartAction";
import { apiJson } from "../../Api";
import { set_loading } from "../../redux/actions/LayoutAction";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import moment from "moment";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { useTranslation } from "react-i18next";
import AssemblyServiceModel from "../customer/AssemblyServiceModel";
import { getCurrencyOfCountry } from "../../utils/utils";
import wrench from "../../assets/images/wrench.png";

const WishlistCardMob = (props) => {
  const { t } = useTranslation();
  const added = t("wishlist-messages.added");
  const removed = t("wishlist-messages.removed");
  const wrong = t("wishlist-messages.wrong");
  const [updatingStartDate, setUpdatingStartDate] = useState(false);
  const [updatingEndDate, setUpdatingEndDate] = useState(false);
  const [startDate, setStartDate] = useState(
    new Date(new Date().setSeconds(0))
  );
  const [isOpen, setIsOpen] = useState(false);
  const [addAssembly, setAddAssembly] = useState(false)

  const [endDate, setEndDate] = useState(
    new Date(new Date(Date.now() + 3600 * 1000 * 24).setSeconds(0))
  );
  const {
    item,
    id,
    name,
    discountPrice,
    price,
    image_path,
    sold_by,
    cartOrWishlist,
    p_quantity,
    type,
    orderable_id,
    sku
  } = props;
  const [quantiyValue, setQuantiyValue] = useState({
    value: p_quantity,
    label: p_quantity,
  });
  let message = "";
  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const showSuccess = () => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const updateStartTime = (val) => {
    setUpdatingStartDate(true);
    apiJson.cart
      .updateProduct(
        id,
        {
          line_item: {
            start_at: new Date(val).toString(),
            end_at: new Date(props.end_at).toString(),
          },
        },
        props.countryId
      )
      .then((response) => {
        setUpdatingStartDate(false);
        if (response.success) {
          props.set_cart_items({
            cart_items: response.data,
          });
          props.getDraftData &&
            response.data.line_items?.length > 0 &&
            props.getDraftData();
        } else if (!response.status) {
          message = t("errors.wrong");
          showError();
        } else {
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
                ? response.errors
                : t("errors.wrong");
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
      });
  };
  const updateEndTime = (val) => {
    setUpdatingEndDate(true);
    apiJson.cart
      .updateProduct(
        id,
        {
          line_item: {
            start_at: new Date(props.start_at).toString(),
            end_at: new Date(val).toString(),
          },
        },
        props.countryId
      )
      .then((response) => {
        setUpdatingEndDate(false);
        if (response.success) {
          props.set_cart_items({
            cart_items: response.data,
          });
          props.getDraftData &&
            response.data.line_items?.length > 0 &&
            props.getDraftData();
        } else if (!response.status) {
          message = t("errors.wrong");
          showError();
        } else {
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
                ? response.errors
                : t("errors.wrong");
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
      });
  };

  const handleChange = (event) => {
    setQuantiyValue(event);
    props.set_loading(true);
    apiJson.cart
      .updateProduct(
        id,
        {
          line_item: {
            quantity: parseInt(event.value),
          },
        },
        props.countryId
      )
      .then((response) => {
        if (response.success) {
          props.set_loading(false);
          props.set_cart_items({
            cart_items: response.data,
          });
        } else if (!response.status) {
          message = wrong;
          showError();
        } else {
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
                ? response.errors
                : t("errors.wrong");
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
      });
  };

  const updateQuantity = (value, assemblyAdded, assembly_id) => {
    setQuantiyValue(value);
    product_quantity = value;
    props.set_loading(true);
    apiJson.cart
      .updateProduct(
        id,
        {
          line_item: {
            quantity: parseInt(value),
            is_assembly_added: assemblyAdded || false,
            assembly_id: assemblyAdded ? assembly_id : null
          },
        },
        props.countryId
      )
      .then((response) => {
        if (response.success) {
          props.set_loading(false);
          props.set_cart_items({
            cart_items: response.data,
          });
        } else if (!response.status) {
          message = wrong;
          showError();
        } else {
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
                ? response.errors
                : t("errors.wrong");
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
      });
  };

  let quantityVal = Array.from({ length: 100 }, (_, i) => {
    return {
      value: i + 1,
      label: i + 1,
    };
  });
  let product_quantity = p_quantity;

  function renderDay(props, currentDate, selectedDate) {
    return (
      <td stlyle={{ fontSize: "13px" }} {...props}>
        {currentDate.date()}
      </td>
    );
  }
  function renderMonth(props, month, year, selectedDate) {
    return <td {...props}>{month}</td>;
  }
  function renderYear(props, year, selectedDate) {
    return <td {...props}>{year % 100}</td>;
  }

  const removeItem = () => {
    props.set_loading(true);
    apiJson.cart.removeItem(id, props.countryId).then((response) => {
      props.set_loading(false);
      if (response.success) {
        props.set_cart_items({
          cart_items: response.data,
        });
      } else if (!response.status) {
        message = wrong;
        showError();
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
              ? response.errors
              : t("errors.wrong");
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        }
      }
    });
  };

  const moveToWishlist = () => {
    props.set_loading(true);
    apiJson.cart
      .moveToWishlist(
        {
          wishlist_item: {
            orderable_type: type === "Product" ? "Variant" : "Service",
            orderable_id: orderable_id,
          },
        },
        props.countryId
      )
      .then((response) => {
        props.set_loading(false);
        if (response.success) {
          if (response.data.added) {
            props.set_cart_items({
              cart_items: {
                ...props.cart_items,
                line_items: props.cart_items.line_items.map((item) =>
                  item.orderable.id !== orderable_id
                    ? item
                    : { ...item, wishlisted_item: true }
                ),
              },
            });
            message = added;
            showSuccess();
          } else {
            props.set_cart_items({
              cart_items: {
                ...props.cart_items,
                line_items: props.cart_items.line_items.map((item) =>
                  item.orderable.id !== orderable_id
                    ? item
                    : { ...item, wishlisted_item: false }
                ),
              },
            });
            message = removed;
            showSuccess();
          }
        } else if (!response.status) {
          message = wrong;
          showError();
        } else {
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
                ? response.errors
                : t("errors.wrong");
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
      });
  };

  const removeFromWishlist = () => {
    props.set_loading(true);
    apiJson.cart
      .moveToWishlist(
        {
          wishlist_item: {
            orderable_type: type === "Product" ? "Variant" : "Service",
            orderable_id: orderable_id,
          },
        },
        props.countryId
      )
      .then((response) => {
        props.set_loading(false);
        if (response.success) {
          props.set_cart_items({
            wish_list: props.wish_list.filter(
              (item) =>
                item.line_item.id !== orderable_id ||
                item.orderable_type !== type
            ),
          });
        } else if (!response.status) {
          message = t("errors.wrong");
          showError();
        } else {
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
                ? response.errors
                : t("errors.wrong");
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
      });
  };

  const addToCart = () => {
    props.set_loading(true);
    apiJson.cart
      .addToCart(
        type === "Product"
          ? {
            line_item: {
              quantity: 1,
              orderable_type: "Variant",
              orderable_id: orderable_id,
            },
          }
          : {
            line_item: {
              orderable_type: "Service",
              orderable_id: orderable_id,

              start_at: new Date(startDate).toString(),
              end_at: new Date(endDate).toString(),
            },
          },
        props.countryId
      )
      .then((response) => {
        props.set_loading(false);
        if (response.success) {
          props.set_cart_items({
            cart_items: response.data,
          });
          message = "added to your cart";
          showSuccess();
        } else if (!response.status) {
          message = t("errors.wrong");
          showError();
        } else {
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
                ? response.errors
                : t("errors.wrong");
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
      });
  };

  useEffect(() => {
    setQuantiyValue({
      label: p_quantity,
      value: p_quantity,
    });
    product_quantity = p_quantity
  }, []); // eslint-disable-line

  return (
    <div className="wishlist-mobile-card px-3" key={props.key}>
      <Row
        noGutters={true}
        className="wishlist-mobile-wrapper justify-content-between align-items-center"
      >
        <div className="left">
          <div className="image-container d-flex justify-content-center">
            <img src={image_path} alt="" className="img img-fluid" />
          </div>
        </div>
        <div className="right">
          {props.cartOrWishlist === "cart" && (
            <div className="title">
              {item && item.orderable[`product_name_${props.language}`]
                ? item.orderable[`product_name_${props.language}`] + " "
                : ""}
              {item && item.orderable[`name_${props.language}`]
                ? item.orderable[`name_${props.language}`]
                : ""}
            </div>
          )}
          {props.cartOrWishlist === "wishlist" && (
            <div className="title pt-3">{name && name}</div>
          )}
          {props.cartOrWishlist === "cart" && props.sku && (
            <div className="title mt-1">{props.sku}</div>
          )}

          {props.cartOrWishlist === "wishlist" && discountPrice < price && (
            <div className="dicount-price striked pt-3">
              {props.country.toLowerCase() === "saudi arabia"
                ? "SAR "
                : props.country.toLowerCase() === "united arab emirates"
                  ? "AED "
                  : "EGP "}
              {price && price}
            </div>
          )}
          <div className="price mt-2">
            {props.country.toLowerCase() === "saudi arabia"
              ? "SAR "
              : props.country.toLowerCase() === "united arab emirates"
                ? "AED "
                : "EGP "}
            {props.cartOrWishlist === "wishlist" ? discountPrice : price}
          </div>
          <div className="store-name pt-2" style={{ fontSize: "13px" }}>
            {t("cart-page.sold-by")}{": "}{sold_by && sold_by}
          </div>
          <div className="store-name" style={{ fontSize: "13px" }}>
            {t("cart-page.sku")}{": "}{sku}
          </div>
            {item?.orderable?.assembly && (
              <AssemblyServiceModel
                isOpen={isOpen}
                isSmall={true}
                setIsOpen={setIsOpen}
                addAssembly={addAssembly}
                setAddAssembly={setAddAssembly}
                assemblyPrice={
                  item?.orderable.assembly.price + " " + getCurrencyOfCountry(props.country)
                }
                showButton={true}
                onUpdate={(isAdd) =>
                  updateQuantity(product_quantity, isAdd, item.orderable.assembly.id)
                }
              />
            )}

          {item?.orderable?.assembly &&
            (item.assembly ? (
              <div
                className="store-name"
                style={{
                  fontSize: "12px",
                  display: "flex",
                  justifyContent: "space-between",
                  borderTop: "1px solid #e5e9f2", 
                  padding: "5px 0px",
                  borderBottom: "1px solid #e5e9f2", 
                }}
              >
                <div>
                  <p style={{ margin: "0" }}>
                    <img src={wrench} height={16} width={16} />{" "}
                    {t("cart-sidebar.assembly-price-included")}
                  </p>
                  <button
                    type="button"
                    className="bg-white"
                    style={{
                      border: "1px solid #d9d9d9",
                      borderRadius: "5px",
                      padding: "4px",
                      color: "grey",
                    }}
                    onClick={() => updateQuantity(product_quantity, false, null)}
                  >
                    <MdDelete className="icon" size={15} />
                    <span className="small-text ml-1">
                      {t("cart-sidebar.remove-assembly")}
                    </span>
                  </button>
                </div>
                <span style={{ fontWeight: 600, color: "#000" }}>
                  {props.country.toLowerCase() === "saudi arabia"
                    ? "SAR "
                    : props.country.toLowerCase() === "united arab emirates"
                    ? "AED "
                    : "EGP "}{" "}
                  {item.assembly?.price}
                </span>
              </div>
            ) : (
              <div>

                <button
                  type="button"
                  className="bg-white"
                  style={{
                    border: "1px solid #d9d9d9",
                    borderRadius: "5px",
                    color: "grey",
                    fontSize: "14px"
                  }}
                  onClick={() => setIsOpen(true)}
                >
                   <img src={wrench} height={16} width={16} />{" "}
                   {t("cart-sidebar.add-assembly")}
                </button>
              </div>
            ))}

          {props.cartOrWishlist === "wishlist" && type === "Service" && (
            <div className="mt-2 mb-1 d-flex flex-column">
              <div className="date-heading mb-1">
                {t("item-detail.start-date") + ":"}
              </div>

              <div style={{ width: "200px" }} className="mb-2">
                <Datetime
                  onChange={(val) => {
                    if (
                      moment(new Date(val)).format("YYYY-MM-DDTHH:mm") <
                      moment(new Date(startDate)).format("YYYY-MM-DDTHH:mm")
                    ) {
                      message = "Start date can not be less than current date";
                      showError();
                    } else {
                      if (
                        moment(new Date(val)).format("YYYY-MM-DDTHH:mm") <
                        moment(new Date(endDate)).format("YYYY-MM-DDTHH:mm")
                      ) {
                        setStartDate(val);
                      } else {
                        message = "Start date should be less than end date";
                        showError();
                      }
                    }
                  }}
                  value={startDate}
                  renderDay={renderDay}
                  renderMonth={renderMonth}
                  renderYear={renderYear}
                />
              </div>
            </div>
          )}
          {props.cartOrWishlist === "wishlist" && type === "Service" && (
            <div className="mt-2 mb-1 d-flex flex-column">
              <div className="date-heading mb-1">
                {t("item-detail.end-date") + ":"}
              </div>

              <div style={{ width: "200px" }} className="mb-2">
                <Datetime
                  onChange={(val) => {
                    if (
                      moment(new Date(val)).format("YYYY-MM-DDTHH:mm") >
                      moment(new Date(startDate)).format("YYYY-MM-DDTHH:mm")
                    ) {
                      setEndDate(val);
                    } else {
                      message = "End date should be greater than start date";
                      showError();
                    }
                  }}
                  value={endDate}
                  renderDay={renderDay}
                  renderMonth={renderMonth}
                  renderYear={renderYear}
                />
              </div>
            </div>
          )}
          <div className="">
            {props.start_at && (
              <div className="mt-2 mb-1 d-flex flex-column">
                <div className="date-heading mb-1">
                  {t("item-detail.start-date") + ":"}
                </div>

                <div style={{ width: "200px" }} className="mb-2">
                  <Datetime
                    inputProps={{
                      disabled: updatingStartDate,
                    }}
                    onChange={(val) => {
                      if (
                        moment(new Date(val)).format("YYYY-MM-DDTHH:mm") <
                        moment(new Date(props.end_at)).format(
                          "YYYY-MM-DDTHH:mm"
                        )
                      ) {
                        updateStartTime(val);
                      } else {
                        message = "Start date should be less than end date";
                        showError();
                      }
                    }}
                    value={new Date(props.start_at)}
                    renderDay={renderDay}
                    renderMonth={renderMonth}
                    renderYear={renderYear}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="">
            {props.end_at && (
              <div className="mb-2 d-flex flex-column">
                <div className="date-heading mb-1">
                  {t("item-detail.end-date") + ":"}
                </div>

                <div style={{ width: "200px" }} className="mb-2">
                  <Datetime
                    inputProps={{
                      disabled: updatingEndDate,
                    }}
                    onChange={(val) => {
                      if (
                        moment(new Date(props.start_at)).format(
                          "YYYY-MM-DDTHH:mm"
                        ) < moment(new Date(val)).format("YYYY-MM-DDTHH:mm")
                      ) {
                        updateEndTime(val);
                      } else {
                        message = "End date should be greater than start date";
                        showError();
                      }
                    }}
                    value={new Date(props.end_at)}
                    renderDay={renderDay}
                    renderMonth={renderMonth}
                    renderYear={renderYear}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="bottom-row d-flex align-items-center w-100 mt-2">
          <div className="left-bottom">
            {props.cartOrWishlist === "cart" && p_quantity && (
              <div className="cart-update-row">
                {!item.assembly && (
                  <div
                    className="minus w-30 text-center d-flex align-items-center justify-content-center plus-minus-btn"
                    style={{
                      borderRadius: "5px 0px 0px 5px",
                      border: "1px solid #d9d9d9"
                    }}
                    onClick={(e) => {
                      if (product_quantity == 1) {
                        e.preventDefault();

                        cartOrWishlist === "cart"
                          ? removeItem()
                          : removeFromWishlist();
                      } else {
                        updateQuantity(product_quantity - 1);
                      }
                    }}
                  >
                    <AiOutlineMinus color="#454545" />
                  </div>
                )}
                <div className="quantity w-40 text-center d-flex align-items-center justify-content-center">
                  <p className="m-0">{product_quantity}</p>
                </div>
                {!item.assembly && (
                  <div
                    className="plus w-30 text-center d-flex align-items-center justify-content-center plus-minus-btn"
                    style={{
                      borderRadius: "0px 5px 5px 0px",
                    }}
                    onClick={() => updateQuantity(product_quantity + 1)}
                  >
                    <AiOutlinePlus color="#454545" />
                  </div>
                )}
              </div>
            )}
            {/* {props.cartOrWishlist === "cart" && p_quantity && (
              <Select
                className="quantiy-select"
                classNamePrefix="control"
                defaultValue={quantiyValue}
                options={quantityVal}
                onChange={handleChange}
              />
            )} */}
          </div>
          <div className="right-bottom d-flex align-items-center">
            <div className={props.language == "ar" ? "right-button-ar" : "right-button"}>
              <button
                onClick={(e) => {
                  e.preventDefault();

                  cartOrWishlist === "cart" ? removeItem() : removeFromWishlist();
                }}
                type="button"
                className="pointer inner-right-button bg-white d-flex align-items-center"
              >
                <MdDelete className="icon" size={20} />
                <span className={props.language == "ar" ? "small-text-ar" : "small-text"}>{t("wishlist.remove")}</span>
              </button>
            </div>
            <div className="left-button">
              {props.cartOrWishlist === "cart" ? (
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    moveToWishlist();
                  }}
                  type="button"
                  className="inner-left-button bg-white d-flex align-items-center"
                >
                  <span className={props.language == "ar" ? "small-text-ar" : "small-text"}>
                    {t("wishlist.move-to-wishlist")}
                  </span>
                  {props.wishlisted_item ? (
                    <FaHeart className={(props.language == "ar" ? "mr-1" : "ml-1") + " icon"} color="#F64951" size={16} />
                  ) : (
                    <FaRegHeart className={(props.language == "ar" ? "mr-1" : "ml-1") + " icon"} size={16} />
                  )}
                </button>
              ) : (
                <button
                  type="button"
                  onClick={() => addToCart()}
                  className="inner-left-button bg-white d-flex align-items-center"
                >
                  <BiCartAlt className=" icon" size={24} />
                  <span className={props.language == "ar" ? "small-text-ar" : "small-text"}>{t("wishlist.move-to-cart")}</span>
                </button>
              )}
            </div>
          </div>
        </div>
        {/* <div className="bottom-cart">
          {props.cartOrWishlist === "cart" && p_quantity && (
            <Select
              className="quantiy-select"
              classNamePrefix="control"
              defaultValue={quantiyValue}
              options={quantityVal}
              onChange={handleChange}
            />
          )}
          <div className="left-button">
            {props.cartOrWishlist === "cart" ? (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  moveToWishlist();
                }}
                type="button"
                className="inner-left-button bg-white d-flex align-items-center mb-1"
              >
                {props.wishlisted_item ? (
                  <FaHeart className=" icon" color="#F64951" size={20} />
                ) : (
                  <FaRegHeart className=" icon" size={20} />
                )}
                <span className="small-text">
                  {t("wishlist.move-to-wishlist")}
                </span>
              </button>
            ) : (
              <button
                type="button"
                onClick={() => addToCart()}
                className="inner-left-button bg-white d-flex align-items-center mb-1"
              >
                <BiCartAlt className=" icon" size={24} />
                <span className="small-text">{t("wishlist.move-to-cart")}</span>
              </button>
            )}
          </div>
          <div className="right-button">
            <button
              onClick={(e) => {
                e.preventDefault();

                cartOrWishlist === "cart" ? removeItem() : removeFromWishlist();
              }}
              type="button"
              className="pointer inner-right-button bg-white d-flex align-items-center mb-1"
            >
              <MdDelete className=" icon" size={22} />
              <span className="small-text ml-1">{t("wishlist.remove")}</span>
            </button>
          </div>
        </div> */}
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    country: state.language.country,
    language: state.language.language,
    wish_list: state.cart.wish_list,
    countryId: state.language.countryId,
    load: state.layout.loading,
  };
};

const mapDispatchToProps = {
  set_cart_items,
  set_wish_list,
  set_loading,
};

export default connect(mapStateToProps, mapDispatchToProps)(WishlistCardMob);
