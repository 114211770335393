import React, { useEffect, useState, useRef } from "react";
import { set_loading } from "../../../redux/actions/LayoutAction";
import "../../../assets/styles/pages/customer/itemDetail/ItemDetailPage.scss";
import ItemDetailMain from "../../../components/customer/itemDetail/ItemDetailMain";
import ItemDetailMainMobile from "../../../components/customer/itemDetail/ItemDetailMainMobile";
import ItemViewed from "../../../components/customer/itemDetail/ItemsViewed";
import {
  Modal
} from "react-bootstrap";
import { set_single_item } from "../../../redux/actions/SingleItemAction";
import { connect } from "react-redux";
import MobileSearch from "../../../components/mobile/AllCategories/MobileSearch";
import Logo from "../../../assets/images/logo.png";
import { HiArrowLeft } from "react-icons/hi";
import { AiOutlineClose } from "react-icons/ai";
import DateAndTimePickers from "./../../../components/customer/itemDetail/DateAndTimePickers";
import moment from "moment";
import { apiJson } from "../../../Api";

import ClipLoader from "react-spinners/ClipLoader";
import { Link, Redirect, useHistory, useParams, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MobileLayoutRenderer from "../../mobile/layout/MobileLayoutRenderer";
import { useTranslation } from "react-i18next";
import WebsiteHeader from "../../../components/header/WebsiteHeader";
import WholesaleWebsiteHeader from "../../../components/header/BWebsiteHeader";
import WebsiteFooter from "../../../components/Footer/WebsiteFooter";
import $ from "jquery";
import HeaderMobile from "../../../components/mobile/HeaderMobile";
import ProductCardMobile from "../../../components/mobile/Home/ProductCardMobile";
import ProductsSection from "../../../components/customer/itemDetail/ProductsSection";
import ItemReviewsMobile from "../../../components/customer/itemDetail/ItemReviewsMobile";
import { FaWhatsapp } from "react-icons/fa";
import { set_cart_items } from "../../../redux/actions/CartAction";
import VariantsModal from "../../../components/customer/VariantsModal";
import NotifyUser from '../../../components/product/NotifyUser';

const en_translation = require("../../../assets/locales/en/translation.json");
const ar_translation = require("../../../assets/locales/ar/translation.json");

function ItemDetailPage(props) {
  const location = useLocation();
  const startDateRef = useRef(null);
  const endDateRef = useRef(null);
  const [quantityVal, setQuantityVal] = useState(0);
  const [data, setData] = useState(false);

  const [quantity, setQuantity] = useState("1");
  const { t } = useTranslation();

  const [serviceModalIsOpen, setServiceModalIsOpen] = useState(false);
  const [quantityModal, setQuantityModal] = useState(false);
  const { name } = useParams();
  const stage = name.split("-");
  const type = stage[stage.length - 2] === "p" ? "products" : "services";
  const [adding, setAdding] = useState(false);
  const [addingBuyNow, setAddingBuyNow] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [checkout_redirect, setCheckoutRedirect] = useState(false);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(
    new Date(Date.now() + 3600 * 1000 * 24)
  );
  let message = "";
  const history = useHistory();
  const [current_variant_key, setCurrentVariantKey] = useState(0);

  const [variantsModalShow, setVariantsModalShow] = React.useState(false);
  const [selectedProduct, setSelectedProduct] = React.useState(null);
  const [selectedSku, setSelectedSku] = React.useState(null);
  const [showNotifyModel, setShowNotifyModel] = useState(false);
  const [addAssembly, setAddAssembly] = useState(false)

  useEffect(() => {
    setAddAssembly(!!props.item?.assembly?.id)
  }, [props.item?.assembly?.id])

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const showErrorTop = () => {
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  useEffect(() => {
    setData(false);
    window.scroll(0, 0);
    props.set_loading(true);
    apiJson.singleItem
      .getItemData(name, type, props.countryId)
      .then((response) => {
        props.set_loading(false);
        if (response.success) {
          document.title =
            response.data?.store_name_en + " - " + response.data?.name_en;
          let data = [];
          let key = 0;

          if (location.search) {
            let search = window.location.search;
            let params = new URLSearchParams(search);
            let sku = params.get('sku');

            if (sku) {
              response.data.variants.forEach((variant, i) => {
                if (variant.sku == sku) {
                  key = i;
                  setCurrentVariantKey(variant.id);
                }
              });
            }
          }
          else if (location.state?.sku) {
            response.data.variants.forEach((variant, i) => {
              if (variant.sku == location.state.sku) {
                key = i;
                setCurrentVariantKey(variant.id);
              }
            });
          }
          if (!response.data.variants) {
            data = [
              {
                id: response.data.id + "_" + 0,
                img: response.data.featured_image,
              },
            ];
            data = [
              ...data,
              ...response.data.images.map((img, index) => {
                return { id: response.data.id + "_" + (index + 1), img: img };
              }),
            ];
          } else {
            data = [
              {
                id: response.data.id + "_" + 0,
                img: response.data.variants[key].featured_image,
              },
            ];

            if (response.data.variants[key].images) {
              data = [
                ...data,
                ...response.data.variants[key].images.map((img, index) => {
                  return {
                    id: response.data.variants[key].id + "_" + (index + 1),
                    img: img,
                  };
                }),
              ];
            }
          }

          props.set_single_item({
            item: {
              assembly: response.data.assembly,
              productId: response.data.id,
              shipment: response.data.shipment_rule,
              img: data,
              order_id: response.data.variants
                ? response.data.variants[key].id
                : response.data.id,
              variantId: response.data.variants
                ? response.data.variants[key].id
                : 1,
              variantName_en: response.data.variants
                ? response.data.variants[key].name_en
                : "",
              variantName_ar: response.data.variants
                ? response.data.variants[key].name_ar
                : "",
              breadcrumb_category: response.data.breadcrumb_category,
              order_title: response.data[`name_${props.language}`],
              order_vendor: response.data[`store_name_${props.language}`],
              brand_id: response.data.brand_id,
              brand_name: response.data[`brand_name_${props.language}`],
              country_name: response.data[`country_name_${props.language}`],
              category_id: response.data.category_id,
              category_title_en: response.data.category_title_en,
              slug: response.data.slug,
              type: response.data.variants ? "Product" : "Service",
              store_id: response.data.store_id,
              price_before_discount: !response.data.variants
                ? response.data.price
                : response.data.variants[key].price,
              order_quantity: response.data.variants
                ? parseInt(response.data.variants[key].quantity) > 0
                  ? response.data.variants[key].quantity
                  : undefined
                : undefined,
              price_after_discount: !response.data.variants
                ? response.data.discounted_price
                : response.data.variants[key].discounted_price,
              qty1range_from: response.data.variants
                ?
                response.data.variants[key].qty1range_from
                :
                undefined,
              qty1range_to: response.data.variants
                ?
                response.data.variants[key].qty1range_to
                :
                undefined,
              qty1range_price: response.data.variants
                ?
                response.data.variants[key].qty1range_price
                :
                undefined,
              qty2range_from: response.data.variants
                ?
                response.data.variants[key].qty2range_from
                :
                undefined,
              qty2range_to: response.data.variants
                ?
                response.data.variants[key].qty2range_to
                :
                undefined,
              qty2range_price: response.data.variants
                ?
                response.data.variants[key].qty2range_price
                :
                undefined,
              qty3range_from: response.data.variants
                ?
                response.data.variants[key].qty3range_from
                :
                undefined,
              qty3range_to: response.data.variants
                ?
                response.data.variants[key].qty3range_to
                :
                undefined,
              qty3range_price: response.data.variants
                ?
                response.data.variants[key].qty3range_price
                :
                undefined,
              rating: response.data.average_rating,
              wishlisted: response.data.variants
                ? response.data.variants[key].wishlisted
                : response.data.wishlisted,
              rating_count: response.data.reviews.length,
              reviews_count: response.data.reviews.length,
              comments: response.data.reviews,
              product_description_en: response.data.description_en,
              product_description_ar: response.data.description_ar,
              product_whats_in_box_en: response.data.whats_in_box_en,
              product_whats_in_box_ar: response.data.whats_in_box_ar,
              description_en: response.data.variants
                ? response.data.variants[key][`description_en`]
                : response.data[`description_en`],
              description_ar: response.data.variants
                ? response.data.variants[0][`description_ar`]
                : response.data[`description_ar`],
              variants: response.data.variants ? response.data.variants : false,
              sku: response.data.variants
                ? response.data.variants[key].sku
                : undefined,
              upc_code: response.data.variants
                ? response.data.variants[key].upc_code
                : undefined,
              variant_name: response.data.variants
                ? response.data.variants.map(
                  (variant) => variant[`name_${props.language}`]
                )
                : false,
              currency:
                props.country.toLowerCase() === "saudi arabia"
                  ? "SAR"
                  : props.country.toLowerCase() === "united arab emirates"
                    ? "AED"
                    : "EGP",
              benefits_en: response.data.variants
                ? response.data.variants[key].benefits_en
                : response.data.benefits_en,
              benefits_ar: response.data.variants
                ? response.data.variants[key].benefits_ar
                : response.data.benefits_ar,
              features_ar: response.data.variants
                ? response.data.variants[key].features_ar
                : response.data.features_ar,
              features_en: response.data.variants
                ? response.data.variants[key].features_en
                : response.data.features_en,
              how_to_use_ar: response.data.variants
                ? response.data.variants[key].how_to_use_ar
                : response.data.how_to_use_ar,
              how_to_use_en: response.data.variants
                ? response.data.variants[key].how_to_use_en
                : response.data.how_to_use_en,
              video: response.data.video,
              coupon: response.data.coupon,
              is_inquiry: response.data.is_inquiry,
              product_type: response.data.product_type,
              unit: response.data.unit
            },
            itemReviewedProducts: response.data.related_products,
            itemReviewedServices: response.data.related_services,
            itemReviewedTools: response.data.related_tools
          });
          setData(true);
          $("html, body").animate({ scrollTop: 0 }, "fast");

          apiJson.allitems
            .getItems(
              props.countryId,
              "products", //type
              2,
              15, //recordPerPage
              "", //price_high_to_low
              "", //price_low_to_high
              "", //new_arrivals
              [response.data.category_id], //selectedCat
              [], //selectedBrands
              "", //minPrice 
              "", //maxPrice
              [], //selectedStores
              "",//rating
            )
            .then((response) => {
              if (response.success) {
                props.set_single_item({
                  section1_products: response.data.products
                })
              }
            });

          apiJson.allitems
            .getItems(
              props.countryId,
              "products", //type
              1,
              15, //recordPerPage
              "", //price_high_to_low
              "", //price_low_to_high
              "", //new_arrivals
              [], //selectedCat
              [response.data.brand_id], //selectedBrands
              "", //minPrice 
              "", //maxPrice
              [], //selectedStores
              "",//rating
            )
            .then((response) => {
              if (response.success) {
                props.set_single_item({
                  brand_products: response.data.products
                })
              }
            });

          apiJson.allitems
            .getItems(
              props.countryId,
              "products", //type
              1,
              15, //recordPerPage
              "", //price_high_to_low
              "", //price_low_to_high
              "", //new_arrivals
              [191], //selectedCat
              [], //selectedBrands
              "", //minPrice 
              "", //maxPrice
              [], //selectedStores
              "",//rating
            )
            .then((response) => {
              if (response.success) {
                props.set_single_item({
                  hardware_products: response.data.products
                })
              }
            });

          apiJson.allitems
            .getItems(
              props.countryId,
              "products", //type
              1,
              15, //recordPerPage
              "", //price_high_to_low
              "", //price_low_to_high
              "", //new_arrivals
              [], //selectedCat
              [], //selectedBrands
              "", //minPrice 
              "", //maxPrice
              [response.data.store_id], //selectedStores
              "",//rating
            )
            .then((response) => {
              if (response.success) {
                props.set_single_item({
                  seller_products: response.data.products
                })
              }
            });
        } else if (!response.status) {
          // eslint-disable-next-line
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        } else {
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
                ? response.errors
                : t("errors.wrong");
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
      });
  }, [props.language, location.state?.sku, location.search, name, type]);
  const refreshPage = () => {
    if (
      window.location.pathname.split("/").length === 4 &&
      window.location.pathname !==
      "/" + props.countryUrl + "-" + props.language + "/items/products" &&
      window.location.pathname !==
      "/" + props.countryUrl + "-" + props.language + "/items/services"
    ) {
      window.location.reload();
    }
  };
  // eslint-disable-next-line
  // useEffect(() => refreshPage, [name, type]);

  useEffect(() => {
    props.item?.order_quantity &&
      props.width === "sm" &&
      setQuantityVal(
        props.item.order_quantity
          ? Array.from({ length: props.item.order_quantity }, (_, i) => i + 1)
          : undefined
      );
    // eslint-disable-next-line
  }, [props.item?.order_quantity]);

  const addToCart = () => {
    if (props.item.product_type == "wholesale") {
      if (props.item.qty3range_to) {
        if (parseInt(quantity) > parseInt(props.item.qty3range_to)) {
          message = "This product is not available in this quantity. Maximum available stock is " + props.item.qty3range_to + ".";
          showError();
        }
        else {
          addToCartApiMethod();
        }
      }
      else if (props.item.qty2range_to) {
        if (parseInt(quantity) > parseInt(props.item.qty2range_to)) {
          message = "This product is not available in this quantity. Maximum available stock is " + props.item.qty2range_to + ".";
          showError();
        }
        else {
          addToCartApiMethod();
        }
      }
      else {
        if (parseInt(quantity) > parseInt(props.item.qty1range_to)) {
          message = "This product is not available in this quantity. Maximum available stock is " + props.item.qty1range_to + ".";
          showError();
        }
        else {
          addToCartApiMethod();
        }
      }
    }
    else {
      addToCartApiMethod();
    }
  };

  const addToCartApiMethod = () => {
    setAdding(true);
    apiJson.cart
      .addToCart(
        props.item.product_type == "wholesale" ?
          {
            line_item: {
              quantity: parseInt(quantity.label ? quantity.label : quantity),
              orderable_type: "Variant",
              orderable_id: props.item.variantId,
              product_type: "wholesale"
            }
          }
          :
          props.item.type === "Product"
            ? {
              line_item: {
                quantity: parseInt(quantity),
                orderable_type: "Variant",
                orderable_id: props.item.variantId,
                is_assembly_added: addAssembly,
                ...(addAssembly && { assembly_id: props.item.assembly.id })
              },
            }
            : {
              line_item: {
                orderable_type: "Service",
                orderable_id: props.item.order_id,

                start_at: new Date(startDate).toString(),
                end_at: new Date(endDate).toString(),
              },
            },
        props.countryId
      )
      .then((response) => {
        setAdding(false);
        const timer = setTimeout(() => {
          setRedirect(false);
        }, 1000);
        clearTimeout(timer);
        if (response.success) {
          if (
            localStorage.getItem("access_token") ||
            localStorage.getItem("user_key") === response.data.user_key
          ) {
            setRedirect(true);
          } else {
            localStorage.setItem("user_key", response.data.user_key);
            setRedirect(true);
          }
        } else if (!response.status) {
          message = t("errors.wrong");
          showError();
        } else {
          if (response.status >= 400 && response.status < 500) {
            message = response.message;
            showError();
          } else {
            message = t("errors.wrong");
            showError();
          }
        }
      });
  };

  const buyNow = () => {
    if (props.item.product_type == "wholesale") {
      if (props.item.qty3range_to) {
        if (parseInt(quantity) > parseInt(props.item.qty3range_to)) {
          message = "This product is not available in this quantity. Maximum available stock is " + props.item.qty3range_to + ".";
          showError();
        }
        else {
          buyNowApiMethod();
        }
      }
      else if (props.item.qty2range_to) {
        if (parseInt(quantity) > parseInt(props.item.qty2range_to)) {
          message = "This product is not available in this quantity. Maximum available stock is " + props.item.qty2range_to + ".";
          showError();
        }
        else {
          buyNowApiMethod();
        }
      }
      else {
        if (parseInt(quantity) > parseInt(props.item.qty1range_to)) {
          message = "This product is not available in this quantity. Maximum available stock is " + props.item.qty1range_to + ".";
          showError();
        }
        else {
          buyNowApiMethod();
        }
      }
    }
    else {
      buyNowApiMethod();
    }
  };

  const buyNowApiMethod = () => {
    setAddingBuyNow(true);
    apiJson.cart
      .addToCart(
        props.item.product_type == "wholesale" ?
          {
            line_item: {
              quantity: parseInt(quantity.label ? quantity.label : quantity),
              orderable_type: "Variant",
              orderable_id: props.item.variantId,
              product_type: "wholesale"
            }
          }
          :
          props.item.type === "Product"
            ? {
              line_item: {
                quantity: parseInt(quantity),
                orderable_type: "Variant",
                orderable_id: props.item.variantId,
                is_assembly_added: addAssembly,
                ...(addAssembly && { assembly_id: props.item.assembly.id })
              },
            }
            : {
              line_item: {
                orderable_type: "Service",
                orderable_id: props.item.order_id,

                start_at: new Date(startDate).toString(),
                end_at: new Date(endDate).toString(),
              },
            },
        props.countryId
      )
      .then((response) => {
        setAddingBuyNow(false);
        // const timer = setTimeout(() => {
        //   setRedirect(false);
        // }, 1000);
        // clearTimeout(timer);
        if (response.success) {
          if (
            localStorage.getItem("access_token") ||
            localStorage.getItem("user_key") === response.data.user_key
          ) {
            // setRedirect(true);
          } else {
            localStorage.setItem("user_key", response.data.user_key);
            // setRedirect(true);
          }

          props.set_cart_items({ cart_items: response.data });

          if (props.auth) {
            apiJson
              .select.getDefault()
              .then(resp => {
                if (resp.success) {
                  if (resp.data.length > 0) {
                    const request = {
                      order: {
                        address_id: resp.data[0].id,
                        is_delivery: 1
                      },
                    };

                    apiJson.select
                      .varifyAdress(request, props.countryId)
                      .then((response) => {
                        if (response.success) {
                          props.set_cart_items({
                            order: {
                              address: resp.data[0],
                              id: response.data.id,
                              createdAddress: response.data,
                            },
                          });
                          setCheckoutRedirect({
                            value: true,
                            path:
                              "/" + props.countryUrl + "-" + props.language + "/checkout",
                          });
                        } else if (!response.status) {
                          message = t("errors.wrong");
                          showError();
                        } else {
                          if (response.status >= 400 && response.status < 500) {
                            message = response.message
                              ? response.message
                              : response.errors
                                ? response.errors
                                : t("errors.wrong");
                            showError();
                          } else {
                            message = response.message
                              ? response.message !== ""
                                ? response.message
                                : t("errors.wrong")
                              : t("errors.wrong");
                            showError();
                          }
                        }
                      });
                  }
                  else {
                    props.set_cart_items({
                      order: {
                        address: null,
                        id: null,
                        createdAddress: null,
                      },
                    });

                    setCheckoutRedirect({
                      value: true,
                      path:
                        "/" + props.countryUrl + "-" + props.language + "/checkout",
                    });
                  }
                }
              });
          }
          else {
            props.set_cart_items({
              order: {
                address: null,
                id: null,
                createdAddress: null,
              },
            });

            setCheckoutRedirect({
              value: true,
              path:
                "/" + props.countryUrl + "-" + props.language + "/checkout",
            });
          }

        } else if (!response.status) {
          message = t("errors.wrong");
          showError();
        } else {
          if (response.status >= 400 && response.status < 500) {
            message = response.message;
            showError();
          } else {
            message = t("errors.wrong");
            showError();
          }
        }
      });
  };

  const onQuickViewClicked = (product, sku) => {
    setVariantsModalShow(true);
    setSelectedSku(sku);

    apiJson.
      singleItem.getItemData(product.id, product.product_type ? "products" : "services", props.countryId)
      .then(response => {
        response.data.type = "Product";

        setSelectedProduct(response.data);
      });
  }

  return (
    <div className="">
      <VariantsModal
        show={variantsModalShow}
        onHide={() => setVariantsModalShow(false)}
        closeModal={() => setVariantsModalShow(false)}
        product={selectedProduct}
        selected_sku={selectedSku}
      />
      {props.width !== "sm" && (
        <>
          {
            window.location.href.includes("/wholesale") ?
              <WholesaleWebsiteHeader />
              :
              <WebsiteHeader />
          }

          {Object.entries(props.item).length === 0 || !data ? (
            <div className=""></div>
          ) : (
            data ?
              <>

                <div className=" bg-white">
                  <ItemDetailMain
                    onChangeStart={(val) => setStartDate(val)}
                    onChangeEnd={(val) => setEndDate(val)}
                    startDate={startDate}
                    endDate={endDate}
                    type={type}
                    addAssembly={addAssembly}
                    setAddAssembly={setAddAssembly}
                    className="all-page-container m-auto"
                    current_variant_key={current_variant_key}
                  />
                </div>
                <div className="all-page-container ml-auto mr-auto mb-5">
                  <ItemViewed onQuickViewClicked={onQuickViewClicked} type={type} />
                </div>
              </>
              :
              <></>
          )}
          {data && <WebsiteFooter />}
        </>
      )}
      {props.width === "sm" && (
        <MobileLayoutRenderer
          NavbarTop={false}
          BackBtn={false}
          CancelBtn={false}
          NavbarBottom={true}
          noPadding={true}
        >
          <div className="item-detail-page-mobile">
            <div id="top" className="home-mob">
              <HeaderMobile />
            </div>
            {/* <div className="header-to-show-on-mobile">
                <div className="d-flex flex-direction-row align-items-center ">
                  <HiArrowLeft
                    onClick={() => history.goBack()}
                    size={20}
                    color="#3c4049"
                    style={{
                      transform:
                        props.language?.toLowerCase() === "en"
                          ? "unset"
                          : `rotate(
                          180deg
                          )`,
                    }}
                  />
                  <div className="img">
                    <img src={Logo} width="100%" height="100%" alt="" />
                  </div>
                  <div className="search">
                    <MobileSearch placeholder={t("search.placeholder")} />
                  </div>
                </div>
              </div> */}
            {
              data ?
                <>
                  {Object.entries(props.item).length === 0 || !data ? (
                    <></>
                  ) : (




                    <div className=" bg-white">
                      <ItemDetailMainMobile
                        onChangeStart={(val) => setStartDate(val)}
                        onChangeEnd={(val) => setEndDate(val)}
                        startDate={startDate}
                        endDate={endDate}
                        addAssembly={addAssembly}
                        setAddAssembly={setAddAssembly}
                        type={type}
                        className="all-page-container m-auto"
                        current_variant_key={current_variant_key}
                      />
                    </div>

                  )}

                  <div className="all-page-container ml-auto mr-auto mb-5 p-0">

                    <ProductsSection />

                    <ItemReviewsMobile data={props.item} />

                    {/* <ItemViewed type={type} /> */}
                  </div>

                  <div
                    className="text-center bg-white"
                  >
                    <a className="back-to-top p-3" href="#top">Back to Top</a>
                  </div>
                </>
                :
                <></>
            }
          </div>

          {props.item && (
            <div className="quantity-cont-mobile">
              {(props.item?.order_quantity && !props.item?.is_inquiry) ? (
                <span
                  onClick={() => {
                    setQuantityModal(!quantityModal);
                  }}
                  className="qty-cont-mobile"
                >
                  <span className="qty-text">Qty</span>
                  <span className="qty-num">{quantity}</span>
                </span>
              ) : (
                props.item?.product_type == "wholesale" ?
                  <span className="dropdown-cont">
                    <input
                      type="text"
                      className="form-control"
                      value={quantity}
                      style={{
                        width: "60px",
                        height: "50px",
                        margin: "5px"
                      }}
                      onChange={(e) => setQuantity(e.target.value)}
                    />
                  </span>
                  :
                  <></>
              )}

              {props.item.type === "Product" ? (
                props.item.is_inquiry ?
                  <a
                    href={encodeURI(`//api.whatsapp.com/send?phone=971543064845&text=Hi, I would like to inquire about the product. I am interested in : ${props.item?.order_title}. Check here: ${(window.location.href).replace("&", "%26")}`)}
                    target="_blank"
                    className="addtocartwrap-mob">
                    <span
                      // onClick={() => props.item?.order_quantity && addToCart()}
                      className={`${props.item?.order_quantity
                        ? "get-inquiry-mobile"
                        : "get-inquiry-mobile"
                        }`}
                    >
                      <span className="add-to-cart-text w-100">
                        {adding ? (
                          <div className="d-flex justify-content-center align-items-center w-100 h-100">
                            <ClipLoader color="#fff" loading size={20} />
                          </div>
                        ) : props.item.order_quantity ? (
                          <div className="d-flex align-items-center w-100">
                            <FaWhatsapp
                              size={24}
                              color="white"
                            />
                            <p className="my-0 mx-auto">{t("item-detail.get-inquiry")}</p>
                          </div>
                        ) : (
                          <div className="d-flex align-items-center w-100">
                            <FaWhatsapp
                              size={24}
                              color="white"
                            />
                            <p className="my-0 mx-auto">{t("item-detail.get-inquiry")}</p>
                          </div>
                        )}
                      </span>
                    </span>
                  </a>
                  :
                  <div className="addtocartwrap-mob" >
                    <span
                      onClick={() => {
                        if (props.item?.order_quantity || props.item?.product_type == "wholesale") {
                          addToCart()
                        }
                      }}
                      className={`${(props.item?.order_quantity || props.item?.product_type == "wholesale")
                        ? "add-to-cont-mobile"
                        : "out-of-stock"
                        }`}
                    >
                      <span className="add-to-cart-text">
                        {adding ? (
                          <div className="d-flex justify-content-center align-items-center w-100 h-100">
                            <ClipLoader color="#fff" loading size={20} />
                          </div>
                        ) : (props.item.order_quantity || props.item?.product_type == "wholesale") ? (
                          props.language == "en" ?
                            en_translation["item-detail"]["add-to-cart"]
                            :
                            ar_translation["item-detail"]["add-to-cart"]
                        ) : (
                          t("item-detail.sold-out")
                        )}
                      </span>
                    </span>
                    {
                      props.item?.order_quantity || props.item?.product_type == "wholesale" ?
                        <span
                          onClick={() => {
                            if (props.item?.order_quantity || props.item?.product_type == "wholesale") {
                              buyNow()
                            }
                          }}
                          className={`${(props.item?.order_quantity || props.item?.product_type == "wholesale")
                            ? "add-to-cont-mobile buy-now-btn"
                            : "out-of-stock"
                            }`}
                        >
                          <span className="add-to-cart-text">
                            {addingBuyNow ? (
                              <div className="d-flex justify-content-center align-items-center w-100 h-100">
                                <ClipLoader color="#fff" loading size={20} />
                              </div>
                            ) : (props.item.order_quantity || props.item?.product_type == "wholesale") ? (
                              t("item-detail.buy-now")
                            ) : (
                              t("item-detail.out-of-stock")
                            )}
                          </span>
                        </span>
                        :
                        <span onClick={() => setShowNotifyModel(true)} className="notify-me-btn" >
                            <span className="notify-me-btn-text">
                              {adding ? (
                                <div className="d-flex justify-content-center align-items-center w-100 h-100">
                                  <ClipLoader color="#fff" loading size={20} />
                                </div>
                              ) : (

                                t("item-detail.notify-me")
                              )}
                            </span>
                          </span>
                    }
                  </div>
              ) : (
                <span
                  onClick={() => setServiceModalIsOpen(true)}
                  className="add-to-cont-mobile"
                >
                  <span className="add-to-cart-text">
                    {adding ? (
                      <div className="d-flex justify-content-center align-items-center w-100 h-100">
                        <ClipLoader color="#fff" loading size={20} />
                      </div>
                    ) : (
                      props.language == "en" ?
                        en_translation["item-detail"]["order-service"]
                        :
                        ar_translation["item-detail"]["order-service"]
                    )}
                  </span>
                </span>
              )}
            </div>
          )}
          <NotifyUser
            setShowNotifyModel={setShowNotifyModel}
            showNotifyModel={showNotifyModel}
            id={props.item.variantId}
            language={props.language}
            isSmall={true}
          />

          {quantityModal && (
            <div className="qty-val-cont-mobile">
              <div className=" ml-4 mr-2 mt-1 d-flex flex-direction-row  align-items-center justify-content-between">
                <span className="qty-heading">{t("item-detail.quantity")}</span>
                <span onClick={() => setQuantityModal(!quantityModal)}>
                  <AiOutlineClose color="#404553" size="18" />
                </span>
              </div>

              <div className="mt-1 qty-values-cont">
                {quantityVal &&
                  quantityVal.map((num, index) => (
                    <span
                      key={index}
                      onClick={() => {
                        setQuantity(num);
                        setQuantityModal(!quantityModal);
                      }}
                      className="qty-values"
                    >
                      {num}
                    </span>
                  ))}
              </div>
            </div>
          )}
          {redirect && (
            <Redirect
              push
              to={"/" + props.countryUrl + "-" + props.language + "/cart"}
            />
          )}
          {checkout_redirect.value && (
            <>
              <Redirect
                push
                to={{
                  pathname: checkout_redirect.path,
                  state: checkout_redirect.state,
                }}
              />
            </>
          )}
        </MobileLayoutRenderer>
      )}
      <Modal
        show={serviceModalIsOpen}
        onHide={() => setServiceModalIsOpen(false)}
        closeModal={() => setServiceModalIsOpen(false)}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        className="service-modal-mobile"
        centered
      >
        <Modal.Body>
          <div className="p-4">
            <div className="prev-price mb-1">
              {t("item-detail.start-date") + ":"}
            </div>
            <DateAndTimePickers
              ref={startDateRef}
              label="Start date"
              onChange={(val) => {
                if (
                  (props.width !== "sm"
                    ? moment(new Date(endDate)).format("YYYY-MM-DDTHH:mm")
                    : moment(new Date(endDate)).format(
                      "YYYY-MM-DDTHH:mm"
                    )) > moment(new Date(val)).format("YYYY-MM-DDTHH:mm")
                ) {
                  setStartDate(val);
                } else {
                  startDateRef.current.setDateValue(
                    props.width !== "sm" ? startDate : startDate
                  );
                  message = "Start date should be less than end date";
                  props.width !== "sm"
                    ? showError(message)
                    : showErrorTop(message);
                }
              }}
              width="100%"
            />
            <div className="prev-price mb-1">
              <div className="prev-price mb-1">
                {t("item-detail.end-date") + ":"}
              </div>

              <DateAndTimePickers
                ref={endDateRef}
                label="End date"
                onChange={(val) => {
                  if (
                    moment(new Date(val)).format("YYYY-MM-DDTHH:mm") >
                    (props.width !== "sm"
                      ? moment(new Date(startDate)).format("YYYY-MM-DDTHH:mm")
                      : moment(new Date(startDate)).format(
                        "YYYY-MM-DDTHH:mm"
                      ))
                  ) {
                    setEndDate(val);
                  } else {
                    endDateRef.current.setDateValue(
                      props.width !== "sm" ? endDate : endDate
                    );
                    message = "End date should be greater than start date";
                    props.width !== "sm"
                      ? showError(message)
                      : showErrorTop(message);
                  }
                }}
                width="100%"
              />
            </div>
            <span onClick={() => addToCart()} className="continue-btn">
                <span className="add-to-cart-text">
                  {adding ? (
                    <div className="d-flex justify-content-center align-items-center w-100 h-100">
                      <ClipLoader color="#fff" loading size={20} />
                    </div>
                  ) : (
                    props.language == "en" ?
                      en_translation["item-detail"]["continue"]
                      :
                      ar_translation["item-detail"]["continue"]
                  )}
                </span>
              </span>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    item: state.item.item,
    width: state.layout.screenWidth,
    country: state.language.country,
    countryId: state.language.countryId,
    language: state.language.language,
    countryUrl: state.language.countryUrl,
    load: state.layout.loading,
    auth: state.auth.isSignedIn
  };
};

const mapDispatchToProps = {
  set_single_item,
  set_cart_items,
  set_loading,
};
export default connect(mapStateToProps, mapDispatchToProps)(ItemDetailPage);
