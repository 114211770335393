import React, { useEffect, useState } from "react";
import { Modal, Row, Col, Container } from "react-bootstrap";
import { BsX } from "react-icons/bs";
import { set_loading } from "../../../redux/actions/LayoutAction";
import favicon from "../../../assets/images/Alisuq-Palm-Logo-fav.png";
import ProductCard from "./ProductCardMobile";
import MainSlider from "../../homepage/MainSlider";
import VendorMainSlider from "../../../components/vendorShopPage/VendorMainSlider";
import { connect } from "react-redux";
import { set_home_categories } from "../../../redux/actions/HomeAction";
import { set_vendor_data } from "../../../redux/actions/VendorShopAction";
import { apiJson } from "../../../Api";
import { toast } from "react-toastify";
import { Link, Redirect, useParams } from "react-router-dom";
import MobileLayoutRenderer from "../../../pages/mobile/layout/MobileLayoutRenderer";
import { useTranslation } from "react-i18next";
import { set_country } from "../../../redux/actions/LayoutAction";
import { set_cart_items } from "../../../redux/actions/CartAction";
import { set_redirect_home } from "../../../redux/actions/NotFoundAction";
import "../../../assets/styles/component/mobile/Home/HomeMobile.scss";
import "react-toastify/dist/ReactToastify.css";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation } from "swiper/core";
import { getMobileImage } from "../../../utils/utils";
import { set_user } from "../../../redux/actions/AuthAction";
import OffersBanner from "../../../assets/images/offers-banner.png";
import buildingbanner from "../../../assets/images/buildingandconstruction.png";
import ladders from "../../../assets/images/ladders.png";
import accessories from "../../../assets/images/accessories.png";

import offer1 from "../../../assets/images/offer1.png";
import offer2 from "../../../assets/images/offer2.png";
import offer3 from "../../../assets/images/offer3.png";

import clean1 from "../../../assets/images/clean1.png";
import clean2 from "../../../assets/images/clean2.png";
import clean3 from "../../../assets/images/clean3.png";
import clean4 from "../../../assets/images/clean4.png";
import offerpic from "../../../assets/images/tag.png";
import offerpic2 from "../../../assets/images/ouroffers2.png";


import { prop } from "dom7";
import MiddleOffers from "./MiddleOffers";
import MiddleSectionMob from "../../pages/MiddleSectionMob";
import { FiTruck } from "react-icons/fi";
import brandweek1 from "../../../assets/images/brandsWeek/karcher-icon.png";
import brandweek2 from "../../../assets/images/brandsWeek/bissell-icon.png";
import brandweek3 from "../../../assets/images/brandsWeek/Drbeckmann-icon.png";
import brandweek4 from "../../../assets/images/brandsWeek/weicon-icon.png";
import brandweek5 from "../../../assets/images/brandsWeek/blackanddecker-icon.png";
import brandweek6 from "../../../assets/images/brandsWeek/Wd40-icon.png";

import topbrand3 from "../../../assets/images/top-brands/makita.jpeg";
import paintpagebanner from "../../../assets/images/individual-pages/Paint.jpg";
import featured1 from "../../../assets/images/C&BMaterials-banner.jpg";
import featured2 from "../../../assets/images/cleaning.jpg";
import featured3 from "../../../assets/images/Hardware&Tools-banner.jpg";
import featured4 from "../../../assets/images/Paint-banner.jpg";
import featured5 from "../../../assets/images/Appliance-banner.jpg";

import catbot1 from "../../../assets/images/bottomicons/Tools&Supplies.png";
import catbot2 from "../../../assets/images/bottomicons/Crafts.png";
import catbot3 from "../../../assets/images/bottomicons/Epoxy.png";
import catbot4 from "../../../assets/images/bottomicons/Paints&painters.png";
import catbot5 from "../../../assets/images/bottomicons/SprayPaints.png";
import catbot6 from "../../../assets/images/bottomicons/WoodFinishes.png";
import catbot7 from "../../../assets/images/bottomicons/Caulk&Sealant.png";

import catbot8 from "../../../assets/images/bottomicons/screws.png";
import catbot9 from "../../../assets/images/bottomicons/nuts.png";
import catbot10 from "../../../assets/images/bottomicons/bolts.png";
import catbot11 from "../../../assets/images/bottomicons/ropes.png";
import catbot12 from "../../../assets/images/bottomicons/Chains.png";
import catbot13 from "../../../assets/images/bottomicons/screwdriver.png";
import catbot14 from "../../../assets/images/bottomicons/Pliers.png";

import catbot15 from "../../../assets/images/bottomicons/Chisel.png";
import catbot16 from "../../../assets/images/bottomicons/Spanners.png";
import catbot17 from "../../../assets/images/bottomicons/Hammer.png";
import catbot18 from "../../../assets/images/bottomicons/Knife&Scissors.png";
import catbot19 from "../../../assets/images/bottomicons/PlantSnippers.png";
import catbot20 from "../../../assets/images/bottomicons/SafetyGalsses.png";
import catbot21 from "../../../assets/images/bottomicons/handrake.png";
import HeaderMobile from "../HeaderMobile";
import NotifyUser from "../../product/NotifyUser";


const en_translation = require("../../../assets/locales/en/translation.json");
const ar_translation = require("../../../assets/locales/ar/translation.json");



function HomePageMobileComp(props) {
  const [offersModalShow, setOffersModalShow] = React.useState(false);
  const [modalShow, setModalShow] = React.useState(true);
  let message = "";
  const { id } = useParams();
  const { t } = useTranslation();
  const [redirect, setRedirect] = useState({ value: false });
  const [secondary_banners, setSecondaryBanners] = useState([]);
  const [showNotifyModel, setShowNotifyModel] = useState(false);
  const [orderableId, setOrderableId] = useState(null);

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  let sections = [];
  function shuffle(array) {
    let currentIndex = array.length,
      randomIndex;
    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  }

  const setSection = (section, position, allProducts) => {
    switch (position) {
      case 2:
        props.set_home_categories({
          positionTwo:
            section?.data?.length < 1
              ? { ...section, data: allProducts }
              : { ...section, data: section.data },
        });
        break;
      case 3:
        props.set_home_categories({
          positionThree:
            section?.data?.length < 1
              ? { ...section, data: allProducts }
              : { ...section, data: section.data },
        });
        break;
      case 4:
        props.set_home_categories({
          positionFour:
            section?.data?.length < 1
              ? { ...section, data: allProducts }
              : { ...section, data: section.data },
        });
        break;
      case 5:
        props.set_home_categories({
          positionFive:
            section?.data?.length < 1
              ? { ...section, data: allProducts }
              : { ...section, data: section.data },
        });
        break;
      case 6:
        props.set_home_categories({
          positionSix:
            section?.data?.length < 1
              ? { ...section, data: allProducts }
              : { ...section, data: section.data },
        });
        break;
      case 7:
        props.set_home_categories({
          positionSeven:
            section?.data?.length < 1
              ? { ...section, data: allProducts }
              : { ...section, data: section.data },
        });
        break;
      case 8:
        props.set_home_categories({
          positionEight:
            section?.data?.length < 1
              ? { ...section, data: allProducts }
              : { ...section, data: section.data },
        });
        break;
      case 9:
        props.set_home_categories({
          positionNine:
            section?.data?.length < 1
              ? { ...section, data: allProducts }
              : { ...section, data: section.data },
        });
        break;
      case 10:
        props.set_home_categories({
          positionTen:
            section?.data?.length < 1
              ? { ...section, data: allProducts }
              : { ...section, data: section.data },
        });
        break;
      case 11:
        props.set_home_categories({
          positionEleven:
            section?.data?.length < 1
              ? { ...section, data: allProducts }
              : { ...section, data: section.data },
        });
        break;
      case 12:
        props.set_home_categories({
          positionTwelve:
            section?.data?.length < 1
              ? { ...section, data: allProducts }
              : { ...section, data: section.data },
        });
        break;
      case 13:
        props.set_home_categories({
          positionThirteen:
            section?.data?.length < 1
              ? { ...section, data: allProducts }
              : { ...section, data: section.data },
        });
        break;
      default:
        sections.push(section);
    }
  };

  useEffect(() => {
    document.title = "Alisuq";
    props.set_loading(true);
    props.redirect && props?.set_redirect_home(false);
    (localStorage.getItem("access_token") ||
      localStorage.getItem("user_key")) &&
      apiJson.cart.getCartItems(props.countryId).then((response) => {
        if (response.success) {
          props.set_cart_items({ cart_items: response.data });
        } else if (!response.status) {
          // eslint-disable-next-line
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        } else {
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
                ? response.errors
                : t("errors.wrong");
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
      });
    if (props.screen === "home") {
      apiJson.homepage.getBanners(props.countryId).then((response) => {
        if (response.success) {
          let primaryBanners = [];
          let secondaryBanners = [];

          response.data.map((banner) =>
            banner.banner_type === "platform_primary"
              ? primaryBanners.push(banner)
              : secondaryBanners.push(banner)
          );
          props.set_home_categories({
            primaryBanners: primaryBanners,
            secondaryBanners: secondaryBanners,
          });
          setSecondaryBanners(secondaryBanners);
          props.set_loading(false);
        } else if (!response.status) {
          props.set_loading(false);
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        } else {
          props.set_loading(false);
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
                ? response.errors
                : t("errors.wrong");
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
      });
      apiJson.homepage.getCategories(props.countryId).then((response) => {
        if (response.success) {
          props.set_home_categories({ categories: response.data });
        } else if (!response.status) {
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        } else {
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
                ? response.errors
                : t("errors.wrong");
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
      });
      let allProducts = [];

      apiJson.homepage.getFirstSection(props.countryId).then((response) => {
        if (response.success) {
          const temp = {
            id: response.data.section.id,
            title_en: response.data.section.title_en,
            title_ar: response.data.section.title_ar,
            data: [
              ...response.data.section.products,
              ...response.data.section.services,
            ],
          };
          props.set_home_categories({
            positionOne: {
              id: response.data.section.id,
              title_en: response.data.section.title_en,
              title_ar: response.data.section.title_ar,
              data: [
                ...response.data.section.products,
                ...response.data.section.services,
              ],
            },
          });

          let i = 0;
          response.data.section_ids?.map((id) =>
            apiJson.homepage
              .getSections(id, props.countryId)
              .then((section) => {
                ++i;
                if (i === 1) {
                  temp?.data?.length < 1 &&
                    props.set_home_categories({
                      positionOne: { ...temp, data: allProducts },
                    });
                }
                if (section.success) {
                  section.data.title_en !== "Weekly Sale 1" &&
                    section.data.title_en !== "Weekly Sale 2" &&
                    section.data.title_en !== "Combo Sale 1" &&
                    section.data.title_en !== "Combo Sale 2" &&
                    section.data.title_en !== "Brand Favourites 1" &&
                    section.data.title_en !== "Brand Favourites 2" &&
                    section.data.title_en !== "Brand Favourites 3" &&
                    section.data.title_en !== "Offers" && (
                      setSection(
                        {
                          id: section.data.id,
                          title_en: section.data.title_en,
                          title_ar: section.data.title_ar,
                          data: [
                            ...section.data.products,
                            ...section.data.services,
                          ],
                        },

                        section.data.position ? section.data.position : null,
                        shuffle(allProducts)
                      )
                    )
                  if (i === response.data.section_ids.length) {
                    props.set_home_categories({
                      sections: sections,
                    });
                  }
                }
              })
          );
        }
      });

      apiJson.allitems
        .getItems(props.countryId, "products", 1, 10)
        .then((response) => {
          response.success
            ? (allProducts = response.data.products)
            : (allProducts = []);
        });

      apiJson.cart.guestUser(props.countryId).then((response) => {
        if (response.success) {
          props.set_user({
            guestUser: response.data.platform_settings.guest_user_checkout,
            guestSignin: false,
          });
        } else {
          props.set_user({
            guestUser: false,
          });
        }
      });
    } else {
      apiJson.vendorShop.getData(props.countryId, id).then((response) => {
        if (response.success) {
          props.set_vendor_data({
            products: response.data.products,
            services: response.data.services,
          });
        } else if (!response.status) {
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        } else {
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
                ? response.errors
                : t("errors.wrong");
            showError();
          } else {
            message = t("errors.wrong");
            showError();
          }
        }
      });
      apiJson.homepage.getCategories(props.countryId).then((response) => {
        if (response.success) {
          props.set_home_categories({ categories: response.data });
        } else if (!response.status) {
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        } else {
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
                ? response.errors
                : t("errors.wrong");
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
      });
    }
  }, [props.countryId]);

  // eslint-disable-next-line
  function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    return "unknown";
  }

  return (
    <MobileLayoutRenderer
      NavbarTop={false}
      BackBtn={false}
      CancelBtn={false}
      NavbarBottom={true}
    >
      <div className="home-mob">
        <HeaderMobile />

        <div className="body" style={{ background: "#e3e6e6bf" }}>
          {modalShow ? (
            <div className="getAppRow">
              <BsX className="cross-icon" style={{ marginTop: "12px" }} onClick={() => setModalShow(false)} />
              <img
                src={favicon}
                style={{ height: "30px", marginRight: "5px", marginTop: "5px" }}
                alt=""
              />
              <div className="text">
                <p>
                  <b>
                    {
                      props.language == "en" ?
                        en_translation["better-alisuq"]
                        :
                        ar_translation["better-alisuq"]
                    }
                    {/* {t("better-alisuq")} */}
                  </b>
                </p>
                <p>
                  {
                    props.language == "en" ?
                      en_translation["app-exclusive"]
                      :
                      ar_translation["app-exclusive"]
                  }
                  {/* {t("app-exclusive")} */}
                </p>
              </div>
              <Link onClick={() => window.open("https://play.google.com/store/apps/details?id=com.alisuq")} className={
                props.language == "en" ? "ml-auto appOpenBtn" : "mr-auto appOpenBtn"}>
                {
                  props.language == "en" ?
                    en_translation["open"]
                    :
                    ar_translation["open"]
                }
                {/* {t("open")} */}
              </Link>
            </div>
          ) : (
            <></>
          )}
          {props.screen === "vendor" ? <VendorMainSlider /> : <MainSlider />}
          {/* <MiddleOffers /> */}
          <div className="freeshipmob">

            <p className="m-0 text-center freshiptxt">
              <FiTruck size={18} />&nbsp;&nbsp;
              {
                props.language == "en" ?
                  en_translation["homePage"]["free-shipping-text"]
                  :
                  ar_translation["homePage"]["free-shipping-text"]
              }
              {/* {t("homePage.free-shipping-text")} */}
            </p>

          </div>

          <div className="whitecard">
            <div className="sectiontitle-container-left">
              <h2 className="section-title lft catsec">
                {
                  props.language == "en" ?
                    en_translation["homePage"]["our-categories"]
                    :
                    ar_translation["homePage"]["our-categories"]
                }
                <div className="ViewAllbtn catsecbtn">
                  <Link
                    to={{
                      pathname: "/" + props.countryUrl +
                        "-" + props.language +
                        "/all-categories"
                    }}
                  >
                    {t("all-categories.view-all")}
                  </Link>
                </div>
              </h2>
            </div>



            <div className="mt-2 ml-2 mr-2">
              {Object.entries(props.categories).length !== 0 && (
                <Swiper className="swipebul"
                  slidesPerView={4} // or 'auto'
                  slidesPerColumn={2}
                  slidesPerColumnFill="row"
                  spaceBetween={3}
                  pagination={{
                    clickable: true,
                  }}
                  navigation
                >
                  <SwiperSlide
                    onClick={(e) => e.preventDefault()}
                    key="0"
                  >
                    <Link
                      key="0"
                      style={{ textDecoration: "none" }}
                      to={{
                        pathname:
                          "/" +
                          props.countryUrl +
                          "-" +
                          props.language +
                          "/offers"
                      }}
                    >
                      <div className="img-cont">
                        <div className="img">
                          <img
                            src={offerpic2}
                            width="100%"
                            height="100%"
                            alt=""
                            className="img-cat"
                          />
                        </div>
                      </div>
                    </Link>
                  </SwiperSlide>
                  <SwiperSlide
                    onClick={(e) => e.preventDefault()}
                    key="0"
                  >
                    <Link
                      key="0"
                      style={{ textDecoration: "none" }}
                      to={{
                        pathname:
                          "/" +
                          props.countryUrl +
                          "-" +
                          props.language +
                          "/new-offers"
                      }}
                    >
                      <div className="img-cont">
                        <div className="img">
                          <img
                            src={offerpic2}
                            width="100%"
                            height="100%"
                            alt=""
                            className="img-cat"
                          />
                        </div>
                      </div>
                    </Link>
                  </SwiperSlide>
                  {props.categories.slice(0, 100).map((ele, index) => {
                    return (
                      <SwiperSlide
                        onClick={(e) => e.preventDefault()}
                        key={ele.id}
                      >
                        {
                          ele.position >= 11 && (
                            <Link
                              key={ele.id}
                              style={{ textDecoration: "none" }}
                              to={{
                                pathname:
                                  "/" +
                                  props.countryUrl +
                                  "-" +
                                  props.language +
                                  "/items/products",
                                search: "?filter_by_category%5B%5D=" + ele.id
                                // state: {
                                //   category:
                                //     ele.sub_categories?.length > 0
                                //       ? [
                                //         ele.id,
                                //         ...ele.sub_categories.map(
                                //           (subCat) => subCat.id
                                //         ),
                                //       ]
                                //       : [ele.id],
                                // },
                              }}
                            >
                              <div className="img-cont">
                                <div className="img">
                                  <img
                                    src={ele.image}
                                    width="100%"
                                    height="100%"
                                    alt=""
                                    className="img-cat"
                                  />
                                </div>
                              </div>
                            </Link>
                          )
                        }
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              )}
            </div>
          </div>
          <div className="emptyspacermob-small"></div>

          {/* <MiddleSectionMob country={props.countryId} /> */}

          <div className="row align-items-center brands-lg-section">
            <div className="col-12">
              <h2 className="title1">Brands You Can</h2>
              <h2 className="title2">Trust</h2>
              <p>At Alisuq, discover a curated collection of brands that stand for quality, reliability, and innovation. Each brand we showcase is carefully selected to ensure you get only the best in every category.</p>
              <br />
              <Link
                to="brands-az"
                className="view-brands-btn"
              >
                View Brands
              </Link>
              <Link
                to="items/products"
                className="ml-3 shop-now-btn"
              >
                Shop Now
              </Link>
            </div>
            <div
              className="mt-3 col-12"
              style={{
                padding: "0px 20px"
              }}
            >
              <div className="row">
                <div className="col-4 mt-3">
                  <Link
                    to={{
                      pathname: "/" + props.countryUrl +
                        "-" + props.language +
                        "/items/products",
                      search: "?filter_by_brand%5B%5D=586"
                    }}
                  >
                    <img
                      src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/jyTJ5zVuDjW9TuiAkJpMBg.png"
                      alt="dummyoffers"
                      height="auto"
                      width="100%"
                      className="brand-img"
                    />
                  </Link>
                </div>
                <div className="col-4 mt-3">
                  <Link
                    to={{
                      pathname: "/" + props.countryUrl +
                        "-" + props.language +
                        "/items/products",
                      search: "?filter_by_brand%5B%5D=63"

                    }}
                  >
                    <img
                      src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/5tFSvZ45KEwSh8toU7PeA8.jpeg"
                      alt="dummyoffers"
                      height="auto"
                      width="100%"
                      className="brand-img"
                    />
                  </Link>
                </div>
                <div className="col-4 mt-3">
                  <Link
                    to={{
                      pathname: "/" + props.countryUrl +
                        "-" + props.language +
                        "/items/products",
                      search: "?filter_by_brand%5B%5D=343"
                    }}
                  >
                    <img
                      src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/k1v2hVgLk5vD341VM4MxWi.png"
                      alt="dummyoffers"
                      height="auto"
                      width="100%"
                      className="brand-img"
                    />
                  </Link>
                </div>
              </div>
              <div className="row">
                <div className="col-4 mt-3">
                  <Link
                    to={{
                      pathname: "/" + props.countryUrl +
                        "-" + props.language +
                        "/items/products",
                      search: "?filter_by_brand%5B%5D=575"
                    }}
                  >
                    <img
                      src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/8YybkzypSFabXL1DGuNzQ.png"
                      alt="dummyoffers"
                      height="auto"
                      width="100%"
                      className="brand-img"
                    />
                  </Link>
                </div>
                <div className="col-4 mt-3">
                  <Link
                    to={{
                      pathname: "/" + props.countryUrl +
                        "-" + props.language +
                        "/items/products",
                      search: "?filter_by_brand%5B%5D=290"
                    }}
                  >
                    <img
                      src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/89Wo3T64Zcb53cJyvHNqad.png"
                      alt="dummyoffers"
                      height="auto"
                      width="100%"
                      className="brand-img"
                    />
                  </Link>
                </div>
                <div className="col-4 mt-3">
                  <Link
                    to={{
                      pathname: "/" + props.countryUrl +
                        "-" + props.language +
                        "/items/products",
                      search: "?filter_by_brand%5B%5D=464"

                    }}
                  >
                    <img
                      src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/ogXffP3jNbDzq69cZVYMLN.png"
                      alt="dummyoffers"
                      height="auto"
                      width="100%"
                      className="brand-img"
                    />
                  </Link>
                </div>
              </div>


            </div>
          </div>

          <div className="emptyspacermob"></div>
          <div className="emptyspacermob"></div>

          <div className="stage-heading">
            <h2 className="title1">From Foundation to Your Dream Home</h2>
            <h2 className="title2">Build Easy</h2>
          </div>

          <div className="emptyspacermob"></div>
          <div className="whitecard">
            <div className="products-section">
              <div className="sectiontitle-container-left">
                <h3 className="section-title lft catsec">
                  {props.positionOne[`title_` + props.language]}
                </h3>
              </div>
              <div className="emptyspacermob"></div>
              <div className="brands-section">
                <div className="sectiontitle-container-left">

                  <h2 className="section-title lft catsec">
                    {
                      props.language == "en" ?
                        en_translation["homePage"]["trusted-brands"]
                        :
                        ar_translation["homePage"]["trusted-brands"]
                    }
                  </h2>
                </div>
                <Swiper
                  spaceBetween={5}
                  slidesPerView={4}
                  navigation={true}
                  className="px-2"
                >
                  <SwiperSlide>
                    <Link
                      to={{
                        pathname: "/" + props.countryUrl +
                          "-" + props.language +
                          "/items/products",
                        search: "?filter_by_brand%5B%5D=585"
                      }}
                    >
                      <img
                        src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/is4XcrZa9WmArknYMPf1qx.png"
                        alt="RAK Cement"
                        height="80" width="80"
                      />
                    </Link>
                  </SwiperSlide>
                  <SwiperSlide>
                    <Link
                      to={{
                        pathname: "/" + props.countryUrl +
                          "-" + props.language +
                          "/items/products",
                        search: "?filter_by_brand%5B%5D=574"
                      }}
                    >
                      <img
                        src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/amiYTXLVAtC3p5AgBVUPEr.png"
                        alt="dummyoffers"
                        height="80" width="80" />
                    </Link>
                  </SwiperSlide>
                  <SwiperSlide>
                    <Link
                      to={{
                        pathname: "/" + props.countryUrl +
                          "-" + props.language +
                          "/items/products",
                        search: "?filter_by_brand%5B%5D=581"
                      }}
                    >
                      <img
                        src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/mAXC6na2acmPzXYFdcDpVs.png"
                        alt="dummyoffers"
                        height="80" width="80" />
                    </Link>
                  </SwiperSlide>
                  <SwiperSlide>
                    <Link
                      to={{
                        pathname: "/" + props.countryUrl +
                          "-" + props.language +
                          "/items/products",
                        search: "?filter_by_brand%5B%5D=366"
                      }}
                    >
                      <img
                        src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/watsiGA3kuupnsfdVR3G25.png"
                        alt="dummyoffers"
                        height="80" width="80" />
                    </Link>
                  </SwiperSlide>
                  <SwiperSlide>
                    <Link
                      to={{
                        pathname: "/" + props.countryUrl +
                          "-" + props.language +
                          "/items/products",
                        search: "?filter_by_brand%5B%5D=345"
                      }}
                    >
                      <img
                        src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/ovsxAZ5CFwnnsoanPniR8q.png"
                        alt="dummyoffers"
                        height="80" width="80" />
                    </Link>
                  </SwiperSlide>
                  <SwiperSlide>
                    <Link
                      to={{
                        pathname: "/" + props.countryUrl +
                          "-" + props.language +
                          "/items/products",
                        search: "?filter_by_brand%5B%5D=153"
                      }}
                    >
                      <img
                        src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/9T22r3rquvB1aMV8tyYkGd.png"
                        alt="dummyoffers"
                        height="80" width="80" />
                    </Link>
                  </SwiperSlide>
                  <SwiperSlide>
                    <Link
                      to={{
                        pathname: "/" + props.countryUrl +
                          "-" + props.language +
                          "/items/products",
                        search: "?filter_by_brand%5B%5D=488"
                      }}
                    >
                      <img
                        src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/qToBx3WMZ3TVBUy9GQugeX.png"
                        alt="dummyoffers"
                        height="80" width="80" />
                    </Link>
                  </SwiperSlide>
                  <SwiperSlide>
                    <Link
                      to={{
                        pathname: "/" + props.countryUrl +
                          "-" + props.language +
                          "/items/products",
                        search: "?filter_by_brand%5B%5D=288"
                      }}
                    >
                      <img
                        src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/htuk69yLp7qyNdEFag6TPt.png"
                        alt="dummyoffers"
                        height="80" width="80" />
                    </Link>
                  </SwiperSlide>
                  <SwiperSlide>
                    <Link
                      to={{
                        pathname: "/" + props.countryUrl +
                          "-" + props.language +
                          "/items/products",
                        search: "?filter_by_brand%5B%5D=47"
                      }}
                    >
                      <img
                        src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/kpv3vBixhX8CFhrriGETTQ.png"
                        alt="dummyoffers"
                        height="80" width="80" />
                    </Link>
                  </SwiperSlide>
                  <SwiperSlide>
                    <Link
                      to={{
                        pathname: "/" + props.countryUrl +
                          "-" + props.language +
                          "/items/products",
                        search: "?filter_by_brand%5B%5D=453"
                      }}
                    >
                      <img
                        src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/waTUrjpjRM9TYip1d4oHws.jpeg"
                        alt="dummyoffers"
                        height="80" width="80" />
                    </Link>
                  </SwiperSlide>
                </Swiper>
              </div>
              <div className="emptyspacermob"></div>
              <div className="sectiontitle-container-left">
                <h2 className="section-title lft catsec">
                  {
                    props.language == "en" ?
                      en_translation["homePage"]["essential-products"]
                      :
                      ar_translation["homePage"]["essential-products"]
                  }

                  <div className="ViewAllbtn catsecbtn">
                    <Link to={{
                      pathname: "/" + props.countryUrl +
                        "-" + props.language +
                        "/section/products/" + props.positionOne.id
                    }}>
                      {
                        props.language == "en" ?
                          en_translation["all-categories"]["view-all"]
                          :
                          ar_translation["all-categories"]["view-all"]
                      }
                      {/* {t("all-categories.view-all")} */}
                    </Link>
                  </div>
                </h2>
              </div>
              {props.positionOne?.data?.length > 0 && (
                <div className="product-cont mt-3">
                  {props.positionOne?.data?.map((data) => (
                    <Link
                      style={{ textDecoration: "none" }}
                      to={{
                        pathname:
                          "/" +
                          props.countryUrl +
                          "-" +
                          props.language +
                          `/${data.category_title_en
                            .toLowerCase()
                            .replace(/ /g, "-")}/${data.slug}`,
                        state: {
                          id: data.id,
                          type: data.variants ? "products" : "services",
                        },
                        search: data.variants ? "?sku=" + data.variants[0].sku : "?sku=" + data.sku
                      }}
                      key={data.id + Math.random()}
                    >
                      <div
                        className="image-cont"
                        key={data.id + Math.random() + "prod"}
                      >
                        <ProductCard
                          key={data.id + Math.random()}
                          setShowNotifyModel={setShowNotifyModel}
                          setOrderableId={setOrderableId}
                          // img={getMobileImage(
                          //   data.variants
                          //     ? data.variants[0].featured_image_resized
                          //     : data.featured_image_resized,
                          //   data.variants
                          //     ? data.variants[0].featured_image
                          //     : data.featured_image
                          // )}
                          img={
                            data.variants
                              ? data.variants[0].featured_image
                              : data.featured_image
                          }
                          storeLogo={data.store_logo}
                          wishlisted={
                            data.variants
                              ? data.variants[0].wishlisted
                              : data.wishlisted
                          }
                          home={true}
                          type={data.variants ? "products" : "services"}
                          id={data.variants ? data.variants[0].id : data.id}
                          rating={data.average_rating}
                          reviews={data.reviews_count}
                          name={data[`name_${props.language}`]}
                          data={data.variants ? data.variants[0] : data}
                          coupon={data.coupon}
                          is_inquiry={data.is_inquiry}
                          product_type={data.product_type}
                          start_price={data.variants ? data.variants[0].start_price : data.start_price}
                          end_price={data.variants ? data.variants[0].end_price : data.end_price}
                        />
                      </div>
                    </Link>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="emptyspacermob-small"></div>
          <div className="whitecard">
            <div className="products-section">
              <div className="sectiontitle-container-left">
                <h3 className="section-title lft catsec">
                  {props.positionTwo[`title_` + props.language]}
                </h3>
              </div>
              <div className="emptyspacermob"></div>
              <div className="brands-section">
                <div className="sectiontitle-container-left">

                  <h2 className="section-title lft catsec">
                    {
                      props.language == "en" ?
                        en_translation["homePage"]["trusted-brands"]
                        :
                        ar_translation["homePage"]["trusted-brands"]
                    }
                  </h2>
                </div>
                <Swiper
                  spaceBetween={5}
                  slidesPerView={4}
                  navigation={true}
                >

                  <SwiperSlide>
                    <Link
                      to={{
                        pathname: "/" + props.countryUrl +
                          "-" + props.language +
                          "/items/products",
                        search: "?filter_by_brand%5B%5D=575"
                      }}
                    >
                      <img
                        src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/8YybkzypSFabXL1DGuNzQ.png"
                        alt="alisuqbrand"
                        height="80" width="80"
                      />
                    </Link>
                  </SwiperSlide>
                  <SwiperSlide>
                    <Link
                      to={{
                        pathname: "/" + props.countryUrl +
                          "-" + props.language +
                          "/items/products",
                        search: "?filter_by_brand%5B%5D=488"
                      }}
                    >
                      <img
                        src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/qToBx3WMZ3TVBUy9GQugeX.png"
                        alt="alisuqbrand"
                        height="80" width="80"
                      />
                    </Link>
                  </SwiperSlide>
                  <SwiperSlide>
                    <Link
                      to={{
                        pathname: "/" + props.countryUrl +
                          "-" + props.language +
                          "/items/products",
                        search: "?filter_by_brand%5B%5D=151"
                      }}
                    >
                      <img
                        src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/tCfr8YiVG9BUpcQdTrffhL.png"
                        alt="alisuqbrand"
                        height="80" width="80"
                      />
                    </Link>
                  </SwiperSlide>
                  <SwiperSlide>
                    <Link
                      to={{
                        pathname: "/" + props.countryUrl +
                          "-" + props.language +
                          "/items/products",
                        search: "?filter_by_brand%5B%5D=121"
                      }}
                    >
                      <img
                        src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/xmW6KgMXiecKrG7hCzJwjN.png"
                        alt="alisuqbrand"
                        height="80" width="80"
                      />
                    </Link>
                  </SwiperSlide>
                  <SwiperSlide>
                    <Link
                      to={{
                        pathname: "/" + props.countryUrl +
                          "-" + props.language +
                          "/items/products",
                        search: "?filter_by_brand%5B%5D=249"
                      }}
                    >
                      <img
                        src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/jYNCJU5oiVukTXdv1aNwwP.png"
                        alt="alisuqbrand"
                        height="80" width="80"
                      />
                    </Link>
                  </SwiperSlide>
                  <SwiperSlide>
                    <Link
                      to={{
                        pathname: "/" + props.countryUrl +
                          "-" + props.language +
                          "/items/products",
                        search: "?filter_by_brand%5B%5D=413"
                      }}
                    >
                      <img
                        src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/cjrqdRMj5VQPTBvSRBtAwU.png"
                        alt="alisuqbrand"
                        height="80" width="80"
                      />
                    </Link>
                  </SwiperSlide>
                  <SwiperSlide>
                    <Link
                      to={{
                        pathname: "/" + props.countryUrl +
                          "-" + props.language +
                          "/items/products",
                        search: "?filter_by_brand%5B%5D=73"
                      }}
                    >
                      <img
                        src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/8YvGp9qJBuUhj6nhbQX3ue.png"
                        alt="alisuqbrand"
                        height="80" width="80"
                      />
                    </Link>
                  </SwiperSlide>
                </Swiper>
              </div>
              <div className="emptyspacermob"></div>
              <div className="sectiontitle-container-left">
                <h2 className="section-title lft catsec">
                  {
                    props.language == "en" ?
                      en_translation["homePage"]["essential-products"]
                      :
                      ar_translation["homePage"]["essential-products"]
                  }

                  <div className="ViewAllbtn catsecbtn">
                    <Link to={{
                      pathname: "/" + props.countryUrl +
                        "-" + props.language +
                        "/section/products/" + props.positionTwo.id
                    }}>
                      {
                        props.language == "en" ?
                          en_translation["all-categories"]["view-all"]
                          :
                          ar_translation["all-categories"]["view-all"]
                      }
                      {/* {t("all-categories.view-all")} */}
                    </Link>
                  </div>
                </h2>
              </div>
              {props.positionTwo?.data?.length > 0 && (
                <div className="product-cont mt-3">
                  {props.positionTwo?.data?.map((data) => (
                    <Link
                      style={{ textDecoration: "none" }}
                      to={{
                        pathname:
                          "/" +
                          props.countryUrl +
                          "-" +
                          props.language +
                          `/${data.category_title_en
                            .toLowerCase()
                            .replace(/ /g, "-")}/${data.slug}`,
                        state: {
                          id: data.id,
                          type: data.variants ? "products" : "services",
                        },
                        search: data.variants ? "?sku=" + data.variants[0].sku : "?sku=" + data.sku
                      }}
                      key={data.id + Math.random()}
                    >
                      <div
                        className="image-cont"
                        key={data.id + Math.random() + "prod"}
                      >
                        <ProductCard
                          key={data.id + Math.random()}
                          setShowNotifyModel={setShowNotifyModel}
                          setOrderableId={setOrderableId}
                          // img={getMobileImage(
                          //   data.variants
                          //     ? data.variants[0].featured_image_resized
                          //     : data.featured_image_resized,
                          //   data.variants
                          //     ? data.variants[0].featured_image
                          //     : data.featured_image
                          // )}
                          img={
                            data.variants
                              ? data.variants[0].featured_image
                              : data.featured_image
                          }
                          storeLogo={data.store_logo}
                          wishlisted={
                            data.variants
                              ? data.variants[0].wishlisted
                              : data.wishlisted
                          }
                          home={true}
                          type={data.variants ? "products" : "services"}
                          id={data.variants ? data.variants[0].id : data.id}
                          rating={data.average_rating}
                          reviews={data.reviews_count}
                          name={data[`name_${props.language}`]}
                          data={data.variants ? data.variants[0] : data}
                          coupon={data.coupon}
                          is_inquiry={data.is_inquiry}
                          product_type={data.product_type}
                          start_price={data.variants ? data.variants[0].start_price : data.start_price}
                          end_price={data.variants ? data.variants[0].end_price : data.end_price}
                        />
                      </div>
                    </Link>
                  ))}
                </div>
              )}
            </div>
          </div>

          <div className="emptyspacermob-small"></div>
          <div className="whitecard">
            <div className="products-section">
              <div className="sectiontitle-container-left">
                <h3 className="section-title lft catsec">
                  {props.positionThree[`title_` + props.language]}
                </h3>
              </div>
              <div className="emptyspacermob"></div>
              <div className="brands-section">
                <div className="sectiontitle-container-left">

                  <h2 className="section-title lft catsec">
                    {
                      props.language == "en" ?
                        en_translation["homePage"]["trusted-brands"]
                        :
                        ar_translation["homePage"]["trusted-brands"]
                    }
                  </h2>
                </div>
                <Swiper
                  spaceBetween={5}
                  slidesPerView={4}
                  navigation={true}
                >
                  <SwiperSlide>
                    <Link
                      to={{
                        pathname: "/" + props.countryUrl +
                          "-" + props.language +
                          "/items/products",
                        search: "?filter_by_brand%5B%5D=290"
                      }}
                    >
                      <img
                        src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/89Wo3T64Zcb53cJyvHNqad.png"
                        alt="alisuqbrand"
                        height="80" width="80"
                      />
                    </Link>
                  </SwiperSlide>
                  <SwiperSlide>
                    <Link
                      to={{
                        pathname: "/" + props.countryUrl +
                          "-" + props.language +
                          "/items/products",
                        search: "?filter_by_brand%5B%5D=302"
                      }}
                    >
                      <img
                        src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/28YHHtx2Yw7MqvRzzTh2Ao.png"
                        alt="alisuqbrand"
                        height="80" width="80"
                      />
                    </Link>
                  </SwiperSlide>
                  <SwiperSlide>
                    <Link
                      to={{
                        pathname: "/" + props.countryUrl +
                          "-" + props.language +
                          "/items/products",
                        search: "?filter_by_brand%5B%5D=295"
                      }}
                    >
                      <img
                        src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/pQoGPQvsm9zVb8sSfQe2cf.png"
                        alt="alisuqbrand"
                        height="80" width="80"
                      />
                    </Link>
                  </SwiperSlide>
                  <SwiperSlide>
                    <Link
                      to={{
                        pathname: "/" + props.countryUrl +
                          "-" + props.language +
                          "/items/products",
                        search: "?filter_by_brand%5B%5D=73"
                      }}
                    >
                      <img
                        src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/8YvGp9qJBuUhj6nhbQX3ue.png"
                        alt="alisuqbrand"
                        height="80" width="80"
                      />
                    </Link>
                  </SwiperSlide>
                  <SwiperSlide>
                    <Link
                      to={{
                        pathname: "/" + props.countryUrl +
                          "-" + props.language +
                          "/items/products",
                        search: "?filter_by_brand%5B%5D=488"
                      }}
                    >
                      <img
                        src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/qToBx3WMZ3TVBUy9GQugeX.png"
                        alt="alisuqbrand"
                        height="80" width="80"
                      />
                    </Link>
                  </SwiperSlide>
                  <SwiperSlide>
                    <Link
                      to={{
                        pathname: "/" + props.countryUrl +
                          "-" + props.language +
                          "/items/products",
                        search: "?filter_by_brand%5B%5D=151"
                      }}
                    >
                      <img
                        src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/tCfr8YiVG9BUpcQdTrffhL.png"
                        alt="alisuqbrand"
                        height="80" width="80"
                      />
                    </Link>
                  </SwiperSlide>
                </Swiper>
              </div>
              <div className="emptyspacermob"></div>
              <div className="sectiontitle-container-left">
                <h2 className="section-title lft catsec">
                  {
                    props.language == "en" ?
                      en_translation["homePage"]["essential-products"]
                      :
                      ar_translation["homePage"]["essential-products"]
                  }

                  <div className="ViewAllbtn catsecbtn">
                    <Link to={{
                      pathname: "/" + props.countryUrl +
                        "-" + props.language +
                        "/section/products/" + props.positionThree.id
                    }}>
                      {
                        props.language == "en" ?
                          en_translation["all-categories"]["view-all"]
                          :
                          ar_translation["all-categories"]["view-all"]
                      }
                      {/* {t("all-categories.view-all")} */}
                    </Link>
                  </div>
                </h2>
              </div>
              {props.positionThree?.data?.length > 0 && (
                <div className="product-cont mt-3">
                  {props.positionThree?.data?.map((data) => (
                    <Link
                      style={{ textDecoration: "none" }}
                      to={{
                        pathname:
                          "/" +
                          props.countryUrl +
                          "-" +
                          props.language +
                          `/${data.category_title_en
                            .toLowerCase()
                            .replace(/ /g, "-")}/${data.slug}`,
                        state: {
                          id: data.id,
                          type: data.variants ? "products" : "services",
                        },
                        search: data.variants ? "?sku=" + data.variants[0].sku : "?sku=" + data.sku
                      }}
                      key={data.id + Math.random()}
                    >
                      <div
                        className="image-cont"
                        key={data.id + Math.random() + "prod"}
                      >
                        <ProductCard
                          key={data.id + Math.random()}
                          setShowNotifyModel={setShowNotifyModel}
                          setOrderableId={setOrderableId}
                          // img={getMobileImage(
                          //   data.variants
                          //     ? data.variants[0].featured_image_resized
                          //     : data.featured_image_resized,
                          //   data.variants
                          //     ? data.variants[0].featured_image
                          //     : data.featured_image
                          // )}
                          img={
                            data.variants
                              ? data.variants[0].featured_image
                              : data.featured_image
                          }
                          storeLogo={data.store_logo}
                          wishlisted={
                            data.variants
                              ? data.variants[0].wishlisted
                              : data.wishlisted
                          }
                          home={true}
                          type={data.variants ? "products" : "services"}
                          id={data.variants ? data.variants[0].id : data.id}
                          rating={data.average_rating}
                          reviews={data.reviews_count}
                          name={data[`name_${props.language}`]}
                          data={data.variants ? data.variants[0] : data}
                          coupon={data.coupon}
                          is_inquiry={data.is_inquiry}
                          product_type={data.product_type}
                          start_price={data.variants ? data.variants[0].start_price : data.start_price}
                          end_price={data.variants ? data.variants[0].end_price : data.end_price}
                        />
                      </div>
                    </Link>
                  ))}
                </div>
              )}
            </div>
          </div>

          <div className="emptyspacermob-small"></div>
          <div className="whitecard">

            <div className="products-section">
              <div className="sectiontitle-container-left">
                <h3 className="section-title lft catsec">
                  {props.positionFour[`title_` + props.language]}
                </h3>
              </div>
              <div className="emptyspacermob"></div>
              <div className="brands-section">
                <div className="sectiontitle-container-left">

                  <h2 className="section-title lft catsec">
                    {
                      props.language == "en" ?
                        en_translation["homePage"]["trusted-brands"]
                        :
                        ar_translation["homePage"]["trusted-brands"]
                    }
                  </h2>
                </div>
                <Swiper
                  spaceBetween={5}
                  slidesPerView={4}
                  navigation={true}
                >
                  <SwiperSlide>
                    <Link
                      to={{
                        pathname: "/" + props.countryUrl +
                          "-" + props.language +
                          "/items/products",
                        search: "?filter_by_brand%5B%5D=319"
                      }}
                    >
                      <img
                        src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/vWWpKe9FBYPnd9p7QZ1Yh8.png"
                        alt="carrier"
                        height="80" width="80"
                      />
                    </Link>
                  </SwiperSlide>
                  <SwiperSlide>
                    <Link
                      to={{
                        pathname: "/" + props.countryUrl +
                          "-" + props.language +
                          "/items/products",
                        search: "?filter_by_brand%5B%5D=170"
                      }}
                    >
                      <img
                        src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/3SHa9cFYRwT1g1wqeg5NrU.jpeg"
                        alt="super general"
                        height="80" width="80"
                      />
                    </Link>
                  </SwiperSlide>
                  <SwiperSlide>
                    <Link
                      to={{
                        pathname: "/" + props.countryUrl +
                          "-" + props.language +
                          "/items/products",
                        search: "?filter_by_brand%5B%5D=332"
                      }}
                    >
                      <img
                        src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/t6UGQtKPpCMh3KfzHZyj85.png"
                        alt="york"
                        height="80" width="80"
                      />
                    </Link>
                  </SwiperSlide>
                  <SwiperSlide>
                    <Link
                      to={{
                        pathname: "/" + props.countryUrl +
                          "-" + props.language +
                          "/items/products",
                        search: "?filter_by_brand%5B%5D=464"
                      }}
                    >
                      <img
                        src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/ogXffP3jNbDzq69cZVYMLN.png"
                        alt="alisuqbrand"
                        height="80" width="80"
                      />
                    </Link>
                  </SwiperSlide>
                </Swiper>
              </div>
              <div className="emptyspacermob"></div>
              <div className="sectiontitle-container-left">
                <h2 className="section-title lft catsec">
                  {
                    props.language == "en" ?
                      en_translation["homePage"]["essential-products"]
                      :
                      ar_translation["homePage"]["essential-products"]
                  }

                  <div className="ViewAllbtn catsecbtn">
                    <Link to={{
                      pathname: "/" + props.countryUrl +
                        "-" + props.language +
                        "/section/products/" + props.positionFour.id
                    }}>
                      {
                        props.language == "en" ?
                          en_translation["all-categories"]["view-all"]
                          :
                          ar_translation["all-categories"]["view-all"]
                      }
                      {/* {t("all-categories.view-all")} */}
                    </Link>
                  </div>
                </h2>
              </div>
              {props.positionFour?.data?.length > 0 && (
                <div className="product-cont mt-3">
                  {props.positionFour?.data?.map((data) => (
                    <Link
                      style={{ textDecoration: "none" }}
                      to={{
                        pathname:
                          "/" +
                          props.countryUrl +
                          "-" +
                          props.language +
                          `/${data.category_title_en
                            .toLowerCase()
                            .replace(/ /g, "-")}/${data.slug}`,
                        state: {
                          id: data.id,
                          type: data.variants ? "products" : "services",
                        },
                        search: data.variants ? "?sku=" + data.variants[0].sku : "?sku=" + data.sku
                      }}
                      key={data.id + Math.random()}
                    >
                      <div
                        className="image-cont"
                        key={data.id + Math.random() + "prod"}
                      >
                        <ProductCard
                          key={data.id + Math.random()}
                          setShowNotifyModel={setShowNotifyModel}
                          setOrderableId={setOrderableId}
                          // img={getMobileImage(
                          //   data.variants
                          //     ? data.variants[0].featured_image_resized
                          //     : data.featured_image_resized,
                          //   data.variants
                          //     ? data.variants[0].featured_image
                          //     : data.featured_image
                          // )}
                          img={
                            data.variants
                              ? data.variants[0].featured_image
                              : data.featured_image
                          }
                          storeLogo={data.store_logo}
                          wishlisted={
                            data.variants
                              ? data.variants[0].wishlisted
                              : data.wishlisted
                          }
                          home={true}
                          type={data.variants ? "products" : "services"}
                          id={data.variants ? data.variants[0].id : data.id}
                          rating={data.average_rating}
                          reviews={data.reviews_count}
                          name={data[`name_${props.language}`]}
                          data={data.variants ? data.variants[0] : data}
                          coupon={data.coupon}
                          is_inquiry={data.is_inquiry}
                          product_type={data.product_type}
                          start_price={data.variants ? data.variants[0].start_price : data.start_price}
                          end_price={data.variants ? data.variants[0].end_price : data.end_price}
                        />
                      </div>
                    </Link>
                  ))}
                </div>
              )}
            </div></div>

          <div className="emptyspacermob-small"></div>
          <div className="whitecard">

            <div className="products-section">
              <div className="sectiontitle-container-left">
                <h3 className="section-title lft catsec">
                  {props.positionFive[`title_` + props.language]}
                </h3>
              </div>
              <div className="emptyspacermob"></div>
              <div className="brands-section">
                <div className="sectiontitle-container-left">

                  <h2 className="section-title lft catsec">
                    {
                      props.language == "en" ?
                        en_translation["homePage"]["trusted-brands"]
                        :
                        ar_translation["homePage"]["trusted-brands"]
                    }
                  </h2>
                </div>
                <Swiper
                  spaceBetween={5}
                  slidesPerView={4}
                  navigation={true}
                >
                  <SwiperSlide>
                    <Link
                      to={{
                        pathname: "/" + props.countryUrl +
                          "-" + props.language +
                          "/items/products",
                        search: "?filter_by_brand%5B%5D=89"
                      }}
                    >
                      <img
                        src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/ZtdMF9jWsKTPPrAoozTQb.png"
                        alt="3M"
                        height="80" width="80"
                      />
                    </Link>
                  </SwiperSlide>
                  <SwiperSlide>
                    <Link
                      to={{
                        pathname: "/" + props.countryUrl +
                          "-" + props.language +
                          "/items/products",
                        search: "?filter_by_brand%5B%5D=456"
                      }}
                    >
                      <img
                        src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/rxNA6v3gBZe7weUX8RHsED.png"
                        alt="alisuqbrand"
                        height="80" width="80"
                      />
                    </Link>
                  </SwiperSlide>
                  <SwiperSlide>
                    <Link
                      to={{
                        pathname: "/" + props.countryUrl +
                          "-" + props.language +
                          "/items/products",
                        search: "?filter_by_brand%5B%5D=201"
                      }}
                    >
                      <img
                        src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/3SJPX3is7wh98KzcGNToDq.jpeg"
                        alt="alisuqbrand"
                        height="80" width="80"
                      />
                    </Link>
                  </SwiperSlide>
                  <SwiperSlide>
                    <Link
                      to={{
                        pathname: "/" + props.countryUrl +
                          "-" + props.language +
                          "/items/products",
                        search: "?filter_by_brand%5B%5D=168"
                      }}
                    >
                      <img
                        src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/3PwLdMpgncAARimuFawCwK.jpeg"
                        alt="alisuqbrand"
                        height="80" width="80"
                      />
                    </Link>
                  </SwiperSlide>
                  <SwiperSlide>
                    <Link
                      to={{
                        pathname: "/" + props.countryUrl +
                          "-" + props.language +
                          "/items/products",
                        search: "?filter_by_brand%5B%5D=412"
                      }}
                    >
                      <img
                        src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/kbohaKtdGdPcCEfkpyXuwe.png"
                        alt="alisuqbrand"
                        height="80" width="80"
                      />
                    </Link>
                  </SwiperSlide>
                  <SwiperSlide>
                    <Link
                      to={{
                        pathname: "/" + props.countryUrl +
                          "-" + props.language +
                          "/items/products",
                        search: "?filter_by_brand%5B%5D=366"
                      }}
                    >
                      <img
                        src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/watsiGA3kuupnsfdVR3G25.png"
                        alt="alisuqbrand"
                        height="80" width="80"
                      />
                    </Link>
                  </SwiperSlide>
                  <SwiperSlide>
                    <Link
                      to={{
                        pathname: "/" + props.countryUrl +
                          "-" + props.language +
                          "/items/products",
                        search: "?filter_by_brand%5B%5D=488"
                      }}
                    >
                      <img
                        src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/qToBx3WMZ3TVBUy9GQugeX.png"
                        alt="alisuqbrand"
                        height="80" width="80"
                      />
                    </Link>
                  </SwiperSlide>
                  <SwiperSlide>
                    <Link
                      to={{
                        pathname: "/" + props.countryUrl +
                          "-" + props.language +
                          "/items/products",
                        search: "?filter_by_brand%5B%5D=288"
                      }}
                    >
                      <img
                        src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/htuk69yLp7qyNdEFag6TPt.png"
                        alt="alisuqbrand"
                        height="80" width="80"
                      />
                    </Link>
                  </SwiperSlide>
                  <SwiperSlide>
                    <Link
                      to={{
                        pathname: "/" + props.countryUrl +
                          "-" + props.language +
                          "/items/products",
                        search: "?filter_by_brand%5B%5D=383"
                      }}
                    >
                      <img
                        src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/qt1o965TPpRLZLGXy4p1je.png"
                        alt="alisuqbrand"
                        height="80" width="80"
                      />
                    </Link>
                  </SwiperSlide>
                </Swiper>
              </div>
              <div className="emptyspacermob"></div>
              <div className="sectiontitle-container-left">
                <h2 className="section-title lft catsec">
                  {
                    props.language == "en" ?
                      en_translation["homePage"]["essential-products"]
                      :
                      ar_translation["homePage"]["essential-products"]
                  }

                  <div className="ViewAllbtn catsecbtn">
                    <Link to={{
                      pathname: "/" + props.countryUrl +
                        "-" + props.language +
                        "/section/products/" + props.positionFive.id
                    }}>
                      {
                        props.language == "en" ?
                          en_translation["all-categories"]["view-all"]
                          :
                          ar_translation["all-categories"]["view-all"]
                      }
                      {/* {t("all-categories.view-all")} */}
                    </Link>
                  </div>
                </h2>
              </div>
              {props.positionFive?.data?.length > 0 && (
                <div className="product-cont mt-3">
                  {props.positionFive?.data?.map((data) => (
                    <Link
                      style={{ textDecoration: "none" }}
                      to={{
                        pathname:
                          "/" +
                          props.countryUrl +
                          "-" +
                          props.language +
                          `/${data.category_title_en
                            .toLowerCase()
                            .replace(/ /g, "-")}/${data.slug}`,
                        state: {
                          id: data.id,
                          type: data.variants ? "products" : "services",
                        },
                        search: data.variants ? "?sku=" + data.variants[0].sku : "?sku=" + data.sku
                      }}
                      key={data.id + Math.random()}
                    >
                      <div
                        className="image-cont"
                        key={data.id + Math.random() + "prod"}
                      >
                        <ProductCard
                          key={data.id + Math.random()}
                          setShowNotifyModel={setShowNotifyModel}
                          setOrderableId={setOrderableId}
                          // img={getMobileImage(
                          //   data.variants
                          //     ? data.variants[0].featured_image_resized
                          //     : data.featured_image_resized,
                          //   data.variants
                          //     ? data.variants[0].featured_image
                          //     : data.featured_image
                          // )}
                          img={
                            data.variants
                              ? data.variants[0].featured_image
                              : data.featured_image
                          }
                          storeLogo={data.store_logo}
                          wishlisted={
                            data.variants
                              ? data.variants[0].wishlisted
                              : data.wishlisted
                          }
                          home={true}
                          type={data.variants ? "products" : "services"}
                          id={data.variants ? data.variants[0].id : data.id}
                          rating={data.average_rating}
                          reviews={data.reviews_count}
                          name={data[`name_${props.language}`]}
                          data={data.variants ? data.variants[0] : data}
                          coupon={data.coupon}
                          is_inquiry={data.is_inquiry}
                          product_type={data.product_type}
                          start_price={data.variants ? data.variants[0].start_price : data.start_price}
                          end_price={data.variants ? data.variants[0].end_price : data.end_price}
                        />
                      </div>
                    </Link>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="emptyspacermob-small"></div>


          <div className="all-categories-section">
            <div className="sectiontitle-container-left allcatnew">
              <h2 className="section-title lft catsec">
                {
                  props.language == "en" ?
                    en_translation["homePage"]["categories-heading"]
                    :
                    ar_translation["homePage"]["categories-heading"]
                }
              </h2>
            </div>
            <div className="row mx-0">
              {
                Object.entries(props.categories).length !== 0 && (
                  props.categories?.map((category, key) => {
                    return (
                      <Link
                        to={`/uae-en/items/products?filter_by_category%5B%5D=${category.id}`}
                        key={key}
                        className="col-6"
                        style={{
                          paddingLeft: key % 2 != 0 ? "7.5px" : "15px",
                          paddingRight: key % 2 == 0 ? "7.5px" : "15px"
                        }}>
                        <div className="category-view d-flex">
                          {/* <img
                            src={category.image}
                            height={40}
                            width={"auto"}
                          /> */}
                          <p>
                            {
                              props.language == "en" ?
                                category.title_en
                                :
                                category.title_ar
                            }
                          </p>
                        </div>
                      </Link>
                    )
                  })
                )
              }
            </div>
          </div>

          <div className="emptyspacermob-small"></div>

          <div className="featured-brands">
            <div className="sectiontitle-container-left ftrdbrands">
              <h2 className="section-title lft catsec">
                {
                  props.language == "en" ?
                    en_translation["homePage"]["of-the-week"]
                    :
                    ar_translation["homePage"]["of-the-week"]
                }
                <div className="ViewAllbtn catsecbtn">
                  <Link to={{
                    pathname: "/" + props.countryUrl +
                      "-" + props.language +
                      "/brands-az"
                  }}>
                    {
                      props.language == "en" ?
                        en_translation["all-categories"]["view-all"]
                        :
                        ar_translation["all-categories"]["view-all"]
                    }
                  </Link>

                </div>
              </h2>
            </div>

            <div className="emptyspacermob"></div>
            <div className="brands-row ml-2 mr-2">
              <Link
                to={{
                  pathname: "/" + props.countryUrl +
                    "-" + props.language +
                    "/items/products",
                  search: "?filter_by_brand%5B%5D=44"
                }}>
                <img src={brandweek1} height="100" />
              </Link>
              <Link
                to={{
                  pathname: "/" + props.countryUrl +
                    "-" + props.language +
                    "/items/products",
                  search: "?filter_by_brand%5B%5D=125"
                }}
              >
                <img src={brandweek2} height="100" />
              </Link>
              <Link
                to={{
                  pathname: "/" + props.countryUrl +
                    "-" + props.language +
                    "/items/products",
                  search: "?filter_by_brand%5B%5D=248"
                }}
              >
                <img src={brandweek3} height="100" />
              </Link>
              <Link
                to={{
                  pathname: "/" + props.countryUrl +
                    "-" + props.language +
                    "/items/products",
                  search: "?filter_by_brand%5B%5D=164"
                }}
              >
                <img src={brandweek4} height="100" />
              </Link>
              <Link
                to={{
                  pathname: "/" + props.countryUrl +
                    "-" + props.language +
                    "/items/products",
                  search: "?filter_by_brand%5B%5D=116"
                }}
              >
                <img src={brandweek5} height="100" />
              </Link>
              <Link
                to={{
                  pathname: "/" + props.countryUrl +
                    "-" + props.language +
                    "/items/products",
                  search: "?filter_by_brand%5B%5D=69"
                }}
              >
                <img src={brandweek6} height="100" />
              </Link>
            </div>
            <div className="emptyspacer"></div>
          </div>

          <div className="emptyspacermob-small"></div>
          <div className="whitecard">
            <div className="products-slider-1">
              <div className="sectiontitle-container-left">
                <h2 className="section-title lft catsec">
                  {props.positionEight[`title_${props.language}`]}
                  {/* {
                  props.language == "en" ?
                    en_translation["homePage"]["recommended-for-u"]
                    :
                    ar_translation["homePage"]["recommended-for-u"]
                } */}
                  <div className="ViewAllbtn catsecbtn">
                    <Link to={{
                      pathname: "/" + props.countryUrl +
                        "-" + props.language +
                        "/section/products/" + props.positionEight.id
                    }}>
                      {
                        props.language == "en" ?
                          en_translation["all-categories"]["view-all"]
                          :
                          ar_translation["all-categories"]["view-all"]
                      }
                    </Link>

                  </div>
                </h2>
              </div>
              <div className="product-cont mt-3">
                {props.positionEight?.data?.map((data) => (
                  <Link
                    style={{ textDecoration: "none" }}
                    to={{
                      pathname:
                        "/" +
                        props.countryUrl +
                        "-" +
                        props.language +
                        `/${data.category_title_en
                          .toLowerCase()
                          .replace(/ /g, "-")}/${data.slug}`,
                      state: {
                        id: data.id,
                        type: data.variants ? "products" : "services",
                      },
                      search: data.variants ? "?sku=" + data.variants[0].sku : "?sku=" + data.sku
                    }}
                    key={data.id + Math.random()}
                  >
                    <div
                      className="image-cont"
                      key={data.id + Math.random() + "prod"}
                    >
                      <ProductCard
                        key={data.id + Math.random()}
                        setShowNotifyModel={setShowNotifyModel}
                        setOrderableId={setOrderableId}
                        // img={getMobileImage(
                        //   data.variants
                        //     ? data.variants[0].featured_image_resized
                        //     : data.featured_image_resized,
                        //   data.variants
                        //     ? data.variants[0].featured_image
                        //     : data.featured_image
                        // )}
                        img={
                          data.variants
                            ? data.variants[0].featured_image
                            : data.featured_image
                        }
                        storeLogo={data.store_logo}
                        wishlisted={
                          data.variants
                            ? data.variants[0].wishlisted
                            : data.wishlisted
                        }
                        home={true}
                        type={data.variants ? "products" : "services"}
                        id={data.variants ? data.variants[0].id : data.id}
                        rating={data.average_rating}
                        reviews={data.reviews_count}
                        name={data[`name_${props.language}`]}
                        data={data.variants ? data.variants[0] : data}
                        coupon={data.coupon}
                        is_inquiry={data.is_inquiry}
                        product_type={data.product_type}
                        start_price={data.variants ? data.variants[0].start_price : data.start_price}
                        end_price={data.variants ? data.variants[0].end_price : data.end_price}
                      />
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </div>
          <div className="emptyspacermob-small"></div>
          <div className="whitecard">
            <div className="products-slider-1">
              <div className="sectiontitle-container-left">
                <h2 className="section-title lft catsec">
                  {props.positionNine[`title_${props.language}`]}
                  {/* {
                  props.language == "en" ?
                    en_translation["homePage"]["recommended-for-u"]
                    :
                    ar_translation["homePage"]["recommended-for-u"]
                } */}
                  <div className="ViewAllbtn catsecbtn">
                    <Link to={{
                      pathname: "/" + props.countryUrl +
                        "-" + props.language +
                        "/section/products/" + props.positionNine.id
                    }}>
                      {
                        props.language == "en" ?
                          en_translation["all-categories"]["view-all"]
                          :
                          ar_translation["all-categories"]["view-all"]
                      }
                    </Link>

                  </div>
                </h2>
              </div>
              <div className="product-cont mt-3">
                {props.positionNine?.data?.map((data) => (
                  <Link
                    style={{ textDecoration: "none" }}
                    to={{
                      pathname:
                        "/" +
                        props.countryUrl +
                        "-" +
                        props.language +
                        `/${data.category_title_en
                          .toLowerCase()
                          .replace(/ /g, "-")}/${data.slug}`,
                      state: {
                        id: data.id,
                        type: data.variants ? "products" : "services",
                      },
                      search: data.variants ? "?sku=" + data.variants[0].sku : "?sku=" + data.sku
                    }}
                    key={data.id + Math.random()}
                  >
                    <div
                      className="image-cont"
                      key={data.id + Math.random() + "prod"}
                    >
                      <ProductCard
                        key={data.id + Math.random()}
                        setShowNotifyModel={setShowNotifyModel}
                        setOrderableId={setOrderableId}
                        // img={getMobileImage(
                        //   data.variants
                        //     ? data.variants[0].featured_image_resized
                        //     : data.featured_image_resized,
                        //   data.variants
                        //     ? data.variants[0].featured_image
                        //     : data.featured_image
                        // )}
                        img={
                          data.variants
                            ? data.variants[0].featured_image
                            : data.featured_image
                        }
                        storeLogo={data.store_logo}
                        wishlisted={
                          data.variants
                            ? data.variants[0].wishlisted
                            : data.wishlisted
                        }
                        home={true}
                        type={data.variants ? "products" : "services"}
                        id={data.variants ? data.variants[0].id : data.id}
                        rating={data.average_rating}
                        reviews={data.reviews_count}
                        name={data[`name_${props.language}`]}
                        data={data.variants ? data.variants[0] : data}
                        coupon={data.coupon}
                        is_inquiry={data.is_inquiry}
                        product_type={data.product_type}
                        start_price={data.variants ? data.variants[0].start_price : data.start_price}
                        end_price={data.variants ? data.variants[0].end_price : data.end_price}
                      />
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </div>
          <div className="emptyspacermob-small"></div>
          <div className="whitecard">
            <div className="products-slider-1">
              <div className="sectiontitle-container-left">
                <h2 className="section-title lft catsec">
                  {props.positionTen[`title_${props.language}`]}
                  {/* {
        props.language == "en" ?
          en_translation["homePage"]["recommended-for-u"]
          :
          ar_translation["homePage"]["recommended-for-u"]
      } */}
                  <div className="ViewAllbtn catsecbtn">
                    <Link to={{
                      pathname: "/" + props.countryUrl +
                        "-" + props.language +
                        "/section/products/" + props.positionTen.id
                    }}>
                      {
                        props.language == "en" ?
                          en_translation["all-categories"]["view-all"]
                          :
                          ar_translation["all-categories"]["view-all"]
                      }
                    </Link>

                  </div>
                </h2>
              </div>
              <div className="product-cont mt-3">
                {props.positionTen?.data?.map((data) => (
                  <Link
                    style={{ textDecoration: "none" }}
                    to={{
                      pathname:
                        "/" +
                        props.countryUrl +
                        "-" +
                        props.language +
                        `/${data.category_title_en
                          .toLowerCase()
                          .replace(/ /g, "-")}/${data.slug}`,
                      state: {
                        id: data.id,
                        type: data.variants ? "products" : "services",
                      },
                      search: data.variants ? "?sku=" + data.variants[0].sku : "?sku=" + data.sku
                    }}
                    key={data.id + Math.random()}
                  >
                    <div
                      className="image-cont"
                      key={data.id + Math.random() + "prod"}
                    >
                      <ProductCard
                        key={data.id + Math.random()}
                        setShowNotifyModel={setShowNotifyModel}
                        setOrderableId={setOrderableId}
                        // img={getMobileImage(
                        //   data.variants
                        //     ? data.variants[0].featured_image_resized
                        //     : data.featured_image_resized,
                        //   data.variants
                        //     ? data.variants[0].featured_image
                        //     : data.featured_image
                        // )}
                        img={
                          data.variants
                            ? data.variants[0].featured_image
                            : data.featured_image
                        }
                        storeLogo={data.store_logo}
                        wishlisted={
                          data.variants
                            ? data.variants[0].wishlisted
                            : data.wishlisted
                        }
                        home={true}
                        type={data.variants ? "products" : "services"}
                        id={data.variants ? data.variants[0].id : data.id}
                        rating={data.average_rating}
                        reviews={data.reviews_count}
                        name={data[`name_${props.language}`]}
                        data={data.variants ? data.variants[0] : data}
                        coupon={data.coupon}
                        is_inquiry={data.is_inquiry}
                        product_type={data.product_type}
                        start_price={data.variants ? data.variants[0].start_price : data.start_price}
                        end_price={data.variants ? data.variants[0].end_price : data.end_price}
                      />
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </div>

          <div className="emptyspacermob-small"></div>
          <div className="whitecard">
            <div className="products-slider-1">
              <div className="sectiontitle-container-left">
                <h2 className="section-title lft catsec">
                  {props.positionEleven[`title_${props.language}`]}
                  {/* {
        props.language == "en" ?
          en_translation["homePage"]["recommended-for-u"]
          :
          ar_translation["homePage"]["recommended-for-u"]
      } */}
                  <div className="ViewAllbtn catsecbtn">
                    <Link to={{
                      pathname: "/" + props.countryUrl +
                        "-" + props.language +
                        "/section/products/" + props.positionEleven.id
                    }}>
                      {
                        props.language == "en" ?
                          en_translation["all-categories"]["view-all"]
                          :
                          ar_translation["all-categories"]["view-all"]
                      }
                    </Link>

                  </div>
                </h2>
              </div>
              <div className="product-cont mt-3">
                {props.positionEleven?.data?.map((data) => (
                  <Link
                    style={{ textDecoration: "none" }}
                    to={{
                      pathname:
                        "/" +
                        props.countryUrl +
                        "-" +
                        props.language +
                        `/${data.category_title_en
                          .toLowerCase()
                          .replace(/ /g, "-")}/${data.slug}`,
                      state: {
                        id: data.id,
                        type: data.variants ? "products" : "services",
                      },
                      search: data.variants ? "?sku=" + data.variants[0].sku : "?sku=" + data.sku
                    }}
                    key={data.id + Math.random()}
                  >
                    <div
                      className="image-cont"
                      key={data.id + Math.random() + "prod"}
                    >
                      <ProductCard
                        key={data.id + Math.random()}
                        setShowNotifyModel={setShowNotifyModel}
                        setOrderableId={setOrderableId}
                        // img={getMobileImage(
                        //   data.variants
                        //     ? data.variants[0].featured_image_resized
                        //     : data.featured_image_resized,
                        //   data.variants
                        //     ? data.variants[0].featured_image
                        //     : data.featured_image
                        // )}
                        img={
                          data.variants
                            ? data.variants[0].featured_image
                            : data.featured_image
                        }
                        storeLogo={data.store_logo}
                        wishlisted={
                          data.variants
                            ? data.variants[0].wishlisted
                            : data.wishlisted
                        }
                        home={true}
                        type={data.variants ? "products" : "services"}
                        id={data.variants ? data.variants[0].id : data.id}
                        rating={data.average_rating}
                        reviews={data.reviews_count}
                        name={data[`name_${props.language}`]}
                        data={data.variants ? data.variants[0] : data}
                        coupon={data.coupon}
                        is_inquiry={data.is_inquiry}
                        product_type={data.product_type}
                        start_price={data.variants ? data.variants[0].start_price : data.start_price}
                        end_price={data.variants ? data.variants[0].end_price : data.end_price}
                      />
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </div>

          <div className="emptyspacermob-small"></div>
          <div className="whitecard">
            <div className="products-slider-1">
              <div className="sectiontitle-container-left">
                <h2 className="section-title lft catsec">
                  {props.positionTwelve[`title_${props.language}`]}
                  {/* {
        props.language == "en" ?
          en_translation["homePage"]["recommended-for-u"]
          :
          ar_translation["homePage"]["recommended-for-u"]
      } */}
                  <div className="ViewAllbtn catsecbtn">
                    <Link to={{
                      pathname: "/" + props.countryUrl +
                        "-" + props.language +
                        "/section/products/" + props.positionTwelve.id
                    }}>
                      {
                        props.language == "en" ?
                          en_translation["all-categories"]["view-all"]
                          :
                          ar_translation["all-categories"]["view-all"]
                      }
                    </Link>

                  </div>
                </h2>
              </div>
              <div className="product-cont mt-3">
                {props.positionTwelve?.data?.map((data) => (
                  <Link
                    style={{ textDecoration: "none" }}
                    to={{
                      pathname:
                        "/" +
                        props.countryUrl +
                        "-" +
                        props.language +
                        `/${data.category_title_en
                          .toLowerCase()
                          .replace(/ /g, "-")}/${data.slug}`,
                      state: {
                        id: data.id,
                        type: data.variants ? "products" : "services",
                      },
                      search: data.variants ? "?sku=" + data.variants[0].sku : "?sku=" + data.sku
                    }}
                    key={data.id + Math.random()}
                  >
                    <div
                      className="image-cont"
                      key={data.id + Math.random() + "prod"}
                    >
                      <ProductCard
                        key={data.id + Math.random()}
                        setShowNotifyModel={setShowNotifyModel}
                        setOrderableId={setOrderableId}
                        // img={getMobileImage(
                        //   data.variants
                        //     ? data.variants[0].featured_image_resized
                        //     : data.featured_image_resized,
                        //   data.variants
                        //     ? data.variants[0].featured_image
                        //     : data.featured_image
                        // )}
                        img={
                          data.variants
                            ? data.variants[0].featured_image
                            : data.featured_image
                        }
                        storeLogo={data.store_logo}
                        wishlisted={
                          data.variants
                            ? data.variants[0].wishlisted
                            : data.wishlisted
                        }
                        home={true}
                        type={data.variants ? "products" : "services"}
                        id={data.variants ? data.variants[0].id : data.id}
                        rating={data.average_rating}
                        reviews={data.reviews_count}
                        name={data[`name_${props.language}`]}
                        data={data.variants ? data.variants[0] : data}
                        coupon={data.coupon}
                        is_inquiry={data.is_inquiry}
                        product_type={data.product_type}
                        start_price={data.variants ? data.variants[0].start_price : data.start_price}
                        end_price={data.variants ? data.variants[0].end_price : data.end_price}
                      />
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </div>

          <div className="emptyspacermob-small"></div>
          <div className="whitecard">
            <div className="products-slider-1">
              <div className="sectiontitle-container-left">
                <h2 className="section-title lft catsec">
                  {props.positionThirteen[`title_${props.language}`]}
                  {/* {
        props.language == "en" ?
          en_translation["homePage"]["recommended-for-u"]
          :
          ar_translation["homePage"]["recommended-for-u"]
      } */}
                  <div className="ViewAllbtn catsecbtn">
                    <Link to={{
                      pathname: "/" + props.countryUrl +
                        "-" + props.language +
                        "/section/products/" + props.positionThirteen.id
                    }}>
                      {
                        props.language == "en" ?
                          en_translation["all-categories"]["view-all"]
                          :
                          ar_translation["all-categories"]["view-all"]
                      }
                    </Link>

                  </div>
                </h2>
              </div>
              <div className="product-cont mt-3">
                {props.positionThirteen?.data?.map((data) => (
                  <Link
                    style={{ textDecoration: "none" }}
                    to={{
                      pathname:
                        "/" +
                        props.countryUrl +
                        "-" +
                        props.language +
                        `/${data.category_title_en
                          .toLowerCase()
                          .replace(/ /g, "-")}/${data.slug}`,
                      state: {
                        id: data.id,
                        type: data.variants ? "products" : "services",
                      },
                      search: data.variants ? "?sku=" + data.variants[0].sku : "?sku=" + data.sku
                    }}
                    key={data.id + Math.random()}
                  >
                    <div
                      className="image-cont"
                      key={data.id + Math.random() + "prod"}
                    >
                      <ProductCard
                        key={data.id + Math.random()}
                        setShowNotifyModel={setShowNotifyModel}
                        setOrderableId={setOrderableId}
                        // img={getMobileImage(
                        //   data.variants
                        //     ? data.variants[0].featured_image_resized
                        //     : data.featured_image_resized,
                        //   data.variants
                        //     ? data.variants[0].featured_image
                        //     : data.featured_image
                        // )}
                        img={
                          data.variants
                            ? data.variants[0].featured_image
                            : data.featured_image
                        }
                        storeLogo={data.store_logo}
                        wishlisted={
                          data.variants
                            ? data.variants[0].wishlisted
                            : data.wishlisted
                        }
                        home={true}
                        type={data.variants ? "products" : "services"}
                        id={data.variants ? data.variants[0].id : data.id}
                        rating={data.average_rating}
                        reviews={data.reviews_count}
                        name={data[`name_${props.language}`]}
                        data={data.variants ? data.variants[0] : data}
                        coupon={data.coupon}
                        is_inquiry={data.is_inquiry}
                        product_type={data.product_type}
                        start_price={data.variants ? data.variants[0].start_price : data.start_price}
                        end_price={data.variants ? data.variants[0].end_price : data.end_price}
                      />
                    </div>
                  </Link>
                ))}
              </div>
            </div></div>

          <div className="emptyspacermob-small"></div>

          <div className="featured-brands catsbot">
            <div className="sectiontitle-container-left allcatnew">
              <h2 className="section-title lft catsec">
                {
                  props.language == "en" ?
                    en_translation["homePage"]["categories-heading"]
                    :
                    ar_translation["homePage"]["categories-heading"]
                }
                {/* {t("homePage.paints-heading")} */}
                <div className="ViewAllbtn catsecbtn">
                  <Link
                    to={{
                      pathname: "/" + props.countryUrl +
                        "-" + props.language +
                        "/items/products",
                      search: "?filter_by_category%5B%5D=194&filter_by_category%5B%5D=271&filter_by_category%5B%5D=269&filter_by_category%5B%5D=270&filter_by_category%5B%5D=272&filter_by_category%5B%5D=274&filter_by_category%5B%5D=275&filter_by_category%5B%5D=273"
                    }}
                  >
                    {
                      props.language == "en" ?
                        en_translation["all-categories"]["view-all"]
                        :
                        ar_translation["all-categories"]["view-all"]
                    }
                    {/* {t("all-categories.view-all")} */}
                  </Link>
                </div>
              </h2>
            </div>
            <div className="emptyspacermob"></div>
            <div className="catsbot-row ml-2 mr-2">
              <Link
                to={{
                  pathname: "/" + props.countryUrl +
                    "-" + props.language +
                    "/items/products",
                  search: "?filter_by_category%5B%5D=269&filter_by_category%5B%5D=423&filter_by_category%5B%5D=425&filter_by_category%5B%5D=426&filter_by_category%5B%5D=427&filter_by_category%5B%5D=428&filter_by_category%5B%5D=430&filter_by_category%5B%5D=424&filter_by_category%5B%5D=429&filter_by_category%5B%5D=431"
                }}
              >
                <img src={catbot1} height="100" />
              </Link>
              <Link
                to={{
                  pathname: "/" + props.countryUrl +
                    "-" + props.language +
                    "/items/products",
                  search: "?filter_by_category%5B%5D=270&filter_by_category%5B%5D=432&filter_by_category%5B%5D=436&filter_by_category%5B%5D=437&filter_by_category%5B%5D=438"
                }}
              >
                <img src={catbot2} height="100" />
              </Link>
              <Link
                to={{
                  pathname: "/" + props.countryUrl +
                    "-" + props.language +
                    "/items/products",
                  search: "?filter_by_category%5B%5D=271"
                }}
              >
                <img src={catbot3} height="100" />
              </Link>
              <Link
                to={{
                  pathname: "/" + props.countryUrl +
                    "-" + props.language +
                    "/items/products",
                  search: "?filter_by_category%5B%5D=272&filter_by_category%5B%5D=439&filter_by_category%5B%5D=441&filter_by_category%5B%5D=442&filter_by_category%5B%5D=440&filter_by_category%5B%5D=443"
                }}
              >
                <img src={catbot4} height="100" />
              </Link>
              <Link
                to={{
                  pathname: "/" + props.countryUrl +
                    "-" + props.language +
                    "/items/products",
                  search: "?filter_by_category%5B%5D=273&filter_by_category%5B%5D=444&filter_by_category%5B%5D=445"
                }}
              >
                <img src={catbot5} height="100" />
              </Link>
              <Link
                to={{
                  pathname: "/" + props.countryUrl +
                    "-" + props.language +
                    "/items/products",
                  search: "?filter_by_category%5B%5D=274"
                }}
              >
                <img src={catbot6} height="100" />
              </Link>
              <Link
                to={{
                  pathname: "/" + props.countryUrl +
                    "-" + props.language +
                    "/items/products",
                  search: "?filter_by_category%5B%5D=275&filter_by_category%5B%5D=446&filter_by_category%5B%5D=447"
                }}
              >
                <img src={catbot7} height="100" />
              </Link>
            </div>
            <div className="emptyspacer"></div>
          </div>

          <div className="emptyspacermob-small"></div>

          <div className="featured-brands catsbot">
            <div className="sectiontitle-container-left allcatnew">
              <h2 className="section-title lft catsec">
                {
                  props.language == "en" ?
                    en_translation["homePage"]["fasteners"]
                    :
                    ar_translation["homePage"]["fasteners"]
                }
                {/* {t("homePage.fasteners")} */}
                <div className="ViewAllbtn catsecbtn">
                  <Link
                    to={{
                      pathname: "/" + props.countryUrl +
                        "-" + props.language +
                        "/items/products",
                      search: "?filter_by_category%5B%5D=257&filter_by_category%5B%5D=381&filter_by_category%5B%5D=382&filter_by_category%5B%5D=383&filter_by_category%5B%5D=384"
                    }}
                  >
                    {
                      props.language == "en" ?
                        en_translation["all-categories"]["view-all"]
                        :
                        ar_translation["all-categories"]["view-all"]
                    }
                    {/* {t("all-categories.view-all")} */}
                  </Link>
                </div>
              </h2>
            </div>
            <div className="emptyspacermob"></div>
            <div className="catsbot-row ml-2 mr-2">
              <Link
                to={{
                  pathname: "/" + props.countryUrl +
                    "-" + props.language +
                    "/items/products",
                  search: "filter_by_category%5B%5D=381"
                }}
              >
                <img src={catbot8} height="100" />
              </Link>
              <Link
                to={{
                  pathname: "/" + props.countryUrl +
                    "-" + props.language +
                    "/items/products",
                  search: "filter_by_category%5B%5D=382"
                }}
              >
                <img src={catbot9} height="100" />
              </Link>
              <Link
                to={{
                  pathname: "/" + props.countryUrl +
                    "-" + props.language +
                    "/items/products",
                  search: "filter_by_category%5B%5D=382"
                }}
              >
                <img src={catbot10} height="100" />
              </Link>
              <Link
                to={{
                  pathname: "/" + props.countryUrl +
                    "-" + props.language +
                    "/items/products",
                  search: "filter_by_category%5B%5D=383"
                }}
              >
                <img src={catbot11} height="100" />
              </Link>
              <Link
                to={{
                  pathname: "/" + props.countryUrl +
                    "-" + props.language +
                    "/items/products",
                  search: "filter_by_category%5B%5D=384"
                }}
              >
                <img src={catbot12} height="100" />
              </Link>
              <Link
                to={{
                  pathname: "/" + props.countryUrl +
                    "-" + props.language +
                    "/items/products",
                  search: "filter_by_category%5B%5D=407"
                }}
              >
                <img src={catbot13} height="100" />
              </Link>
              <Link
                to={{
                  pathname: "/" + props.countryUrl +
                    "-" + props.language +
                    "/items/products",
                  search: "filter_by_category%5B%5D=405"
                }}
              >
                <img src={catbot14} height="100" />
              </Link>
            </div>
            <div className="emptyspacer"></div>
          </div>
          <div className="emptyspacermob-small"></div>

          <div className="featured-brands catsbot">
            <div className="sectiontitle-container-left allcatnew">
              <h2 className="section-title lft catsec">
                {
                  props.language == "en" ?
                    en_translation["homePage"]["hand-tools"]
                    :
                    ar_translation["homePage"]["hand-tools"]
                }
                {/* {t("homePage.hand-tools")} */}
                <div className="ViewAllbtn catsecbtn">
                  <Link
                    to={{
                      pathname: "/" + props.countryUrl +
                        "-" + props.language +
                        "/items/products",
                      search: "?filter_by_category%5B%5D=261&filter_by_category%5B%5D=405&filter_by_category%5B%5D=406&filter_by_category%5B%5D=407&filter_by_category%5B%5D=408&filter_by_category%5B%5D=409&filter_by_category%5B%5D=410&filter_by_category%5B%5D=411&filter_by_category%5B%5D=412&filter_by_category%5B%5D=413&filter_by_category%5B%5D=414&filter_by_category%5B%5D=415&filter_by_category%5B%5D=416"
                    }}
                  >
                    {
                      props.language == "en" ?
                        en_translation["all-categories"]["view-all"]
                        :
                        ar_translation["all-categories"]["view-all"]
                    }
                    {/* {t("all-categories.view-all")} */}
                  </Link>
                </div>
              </h2>
            </div>
            <div className="emptyspacermob"></div>
            <div className="catsbot-row ml-2 mr-2">
              <Link
                to={{
                  pathname: "/" + props.countryUrl +
                    "-" + props.language +
                    "/items/products",
                  search: "filter_by_category%5B%5D=406"
                }}>
                <img src={catbot15} height="100" />
              </Link>
              <Link
                to={{
                  pathname: "/" + props.countryUrl +
                    "-" + props.language +
                    "/items/products",
                  search: "filter_by_category%5B%5D=408"
                }}>
                <img src={catbot16} height="100" />
              </Link>
              <Link
                to={{
                  pathname: "/" + props.countryUrl +
                    "-" + props.language +
                    "/items/products",
                  search: "filter_by_category%5B%5D=409"
                }}>
                <img src={catbot17} height="100" />
              </Link>
              <Link
                to={{
                  pathname: "/" + props.countryUrl +
                    "-" + props.language +
                    "/items/products",
                  search: "filter_by_category%5B%5D=410"
                }}>
                <img src={catbot18} height="100" />
              </Link>
              <Link
                to={{
                  pathname: "/" + props.countryUrl +
                    "-" + props.language +
                    "/items/products",
                  search: "filter_by_category%5B%5D=369"
                }}>
                <img src={catbot19} height="100" />
              </Link>
              <Link
                to={{
                  pathname: "/" + props.countryUrl +
                    "-" + props.language +
                    "/items/products",
                  search: "filter_by_category%5B%5D=412"
                }}>
                <img src={catbot20} height="100" />
              </Link>
              <Link
                to={{
                  pathname: "/" + props.countryUrl +
                    "-" + props.language +
                    "/items/products",
                  search: "filter_by_category%5B%5D=366"
                }}>
                <img src={catbot21} height="100" />
              </Link>
            </div>
            <div className="emptyspacer"></div>
          </div>

          <div className="emptyspacermob-small"></div>


          <div className="banner-sec">
            <Link
              to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language +
                  "/paints"

              }}
            >
              <img src={paintpagebanner} width="100%">
              </img>
            </Link>

          </div>

          <div className="emptyspacermob-small"></div>




          <div className="featured-brands brands-list">




            <div className="sectiontitle-container-left allcatnew">
              <h2 className="section-title lft catsec">


                {
                  props.language == "en" ?
                    en_translation["homePage"]["alisuq"]
                    :
                    ar_translation["homePage"]["alisuq"]
                }
                <div className="ViewAllbtn catsecbtn">
                  <Link to={{
                    pathname: "/" + props.countryUrl +
                      "-" + props.language +
                      "/brands-az"
                  }}>
                    {
                      props.language == "en" ?
                        en_translation["all-categories"]["view-all"]
                        :
                        ar_translation["all-categories"]["view-all"]
                    }
                  </Link>

                </div>
              </h2>
            </div>

            <div className="emptyspacer"></div>
            <Swiper
              spaceBetween={0}
              slidesPerView={3}
              pagination={true}
              autoplay={true}
            >
              <SwiperSlide>
                <Link
                  to={{
                    pathname: "/" + props.countryUrl +
                      "-" + props.language +
                      "/items/products",
                    search: "?filter_by_brand%5B%5D=44"
                  }}
                >
                  <img
                    src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/2gbCVfRpwUsgffJzgihfmH.png"
                    alt="dummyoffers"
                    height="100"
                  />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link
                  to={{
                    pathname: "/" + props.countryUrl +
                      "-" + props.language +
                      "/items/products",
                    search: "?filter_by_brand%5B%5D=125"
                  }}
                >
                  <img
                    src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/dxZDu1N13rDU9PNWi1zg57.png"
                    alt="dummyoffers"
                    height="100"
                  />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link
                  to={{
                    pathname: "/" + props.countryUrl +
                      "-" + props.language +
                      "/items/products",
                    search: "?filter_by_brand%5B%5D=63"
                  }}
                >
                  <img
                    src={topbrand3}
                    alt="dummyoffers"
                    height="100"
                  />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link
                  to={{
                    pathname: "/" + props.countryUrl +
                      "-" + props.language +
                      "/items/products",
                    search: "?filter_by_brand%5B%5D=86"
                  }}
                >
                  <img
                    src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/qjvsNBkNNiWX9jroUg6jZN.png"
                    alt="dummyoffers"
                    height="100"
                  />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link
                  to={{
                    pathname: "/" + props.countryUrl +
                      "-" + props.language +
                      "/items/products",
                    search: "?filter_by_brand%5B%5D=248"
                  }}
                >
                  <img
                    src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/vdpKaszW2wnD3FRwadFG8c.png"
                    alt="dummyoffers"
                    height="100"
                  />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link
                  to={{
                    pathname: "/" + props.countryUrl +
                      "-" + props.language +
                      "/items/products",
                    search: "?filter_by_brand%5B%5D=42"
                  }}
                >
                  <img
                    src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/hSZ6J8vxQ8pENansr4opLk.png"
                    alt="dummyoffers"
                    height="100"
                  />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link
                  to={{
                    pathname: "/" + props.countryUrl +
                      "-" + props.language +
                      "/items/products",
                    search: "?filter_by_brand%5B%5D=62"
                  }}
                >
                  <img
                    src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/9TaBpJzvVLkxxZjmbUsVDn.png"
                    alt="dummyoffers"
                    height="100"
                  />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link
                  to={{
                    pathname: "/" + props.countryUrl +
                      "-" + props.language +
                      "/items/products",
                    search: "?filter_by_brand%5B%5D=43"
                  }}
                >
                  <img
                    src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/iReSSYpWhmJcPBKxns3BgJ.png"
                    alt="dummyoffers"
                    height="100"
                  />
                </Link>
              </SwiperSlide>
            </Swiper>

          </div>
        </div>
        <OffersModal
          show={offersModalShow}
          onHide={() => setOffersModalShow(false)}
        />
        {redirect.value && (
          <Redirect
            push
            to={{ pathname: redirect.path, state: redirect.state }}
          />
        )}
      </div>
      <NotifyUser
        setShowNotifyModel={setShowNotifyModel}
        showNotifyModel={showNotifyModel}
        id={orderableId}
        language={props.language}
        isSmall={true}
      />
    </MobileLayoutRenderer>
  );
}

function OffersModal(props) {
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="offers-modal"
      style={{ padding: "20px", height: "auto", position: "absolute", top: "30%" }}
    >
      <Modal.Body>
        <Link to="/uae-en/electrical-accessories-sale">
          <img src={OffersBanner} className="img-fluid" width="100%" height="100%" />
        </Link>
      </Modal.Body>
    </Modal>
  )
}

const mapStateToProps = (state) => {
  return {
    categories: state.home.categories,
    products: state.vendor.products,
    services: state.vendor.services,
    sections: state.home.sections,
    positionOne: state.home.positionOne,
    positionTwo: state.home.positionTwo,
    positionThree: state.home.positionThree,
    positionFour: state.home.positionFour,
    positionFive: state.home.positionFive,
    positionSix: state.home.positionSix,
    positionSeven: state.home.positionSeven,
    positionEight: state.home.positionEight,
    positionNine: state.home.positionNine,
    positionTen: state.home.positionTen,
    positionEleven: state.home.positionEleven,
    positionTwelve: state.home.positionTwelve,
    positionThirteen: state.home.positionThirteen,
    load: state.layout.loading,
    countryId: state.language.countryId,
    language: state.language.language,
    country: state.language.country,
    countryUrl: state.language.countryUrl,
    redirect: state.notFound.redirect,
    lang: state.language.language,
    cart_items: state.cart.cart_items,
    countryUrl: state.language.countryUrl,
    cart_items_length: state.cart.cart_items_length,
  };
};

const mapDispatchToProps = {
  set_vendor_data,
  set_home_categories,
  set_country,
  set_cart_items,
  set_redirect_home,
  set_loading,
  set_user,
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePageMobileComp);
