import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";

import "./createmodal.scss";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";
import { apiJson } from "../../Api";
import { Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CreateModal = (props) => {
  const { t } = useTranslation();
  const [description, setDescription] = useState("");
  const [quantity, setQuantity] = useState("");
  const [loading, setLoading] = useState(false);
  let message = "";
  const [redirect, setRedirect] = useState({ value: false });
  const [error, setError] = useState(false);
  const [quantityError, setQuantityError] = useState(false);
  const refundOptions_en = [
    { value: "Refund to wallet", label: "Refund to wallet" },
    { value: "Refund to bank account", label: "Refund to bank account" },
  ];
  const refundOptions_ar = [
    { value: "رد الأموال إلى المحفظة", label: "رد الأموال إلى المحفظة" },
    { value: "رد الأموال إلى حساب مصرفي", label: "رد الأموال إلى حساب مصرفي" },
  ];
  const [refundMethod, setRefundMethod] = useState(
    props.language === "en" ? refundOptions_en[0] : refundOptions_ar[0]
  );
  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const showSuccess = () => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const Create = () => {
    setError(false);
    setQuantityError(false);
    if (description) {
      if (props.name === "disputes") {
        setLoading(true);
        apiJson.disputes
          .createDispute({
            dispute: {
              order_id: props.item.order_id,
              line_item_id: props.item.id,
              vendor_id: props.item.vendor_id,
              title: "",
              description: description,
              role: "customer",
            },
            role: "customer",
          })
          .then((response) => {
            if (response.success) {
              setLoading(false);
              message = "Dispute created successfully.";
              showSuccess();
              setRedirect({
                value: true,
                path:
                  "/" + props.countryUrl + "-" + props.language + "/disputes",
              });
            } else if (!response.status) {
              setLoading(false);
              message = response.message
                ? response.message !== ""
                  ? response.message
                  : t("errors.wrong")
                : t("errors.wrong");
              showError();
            } else {
              setLoading(false);
              if (response.status >= 400 && response.status < 500) {
                message = response.message
                  ? response.message
                  : response.errors
                    ? response.errors
                    : t("errors.wrong");
                showError();
              } else {
                message = response.message
                  ? response.message !== ""
                    ? response.message
                    : t("errors.wrong")
                  : t("errors.wrong");
                showError();
              }
            }
          })
      }
      else {
        if (quantity) {
          setLoading(true);
          apiJson.returnItems
            .createRefund({
              refund: {
                reason: description,
                quantity: parseInt(quantity),
                order_id: props.item.order_id,
                line_item_id: props.item.id,
                vendor_id: props.item.vendor_id,
                refund_method:
                  props.language === "ar"
                    ? refundMethod.value === refundOptions_ar[0].value
                      ? "wallet"
                      : "bank"
                    : refundMethod.value === refundOptions_en[0].value
                      ? "wallet"
                      : "bank",
              },
              address: {
                country: props.item.customer_address.country,
                state: props.item.customer_address.state,
                address1: props.item.customer_address.address1,
                contact_number: props.item.customer_address.contact_number,
              },
            })
            .then((response) => {
              if (response.success) {
                setLoading(false);
                message = "Refund created successfully.";
                showSuccess();
                setRedirect({
                  value: true,
                  path:
                    "/" + props.countryUrl + "-" + props.language + "/returns",
                });
              } else if (!response.status) {
                setLoading(false);
                message = response.message
                  ? response.message !== ""
                    ? response.message
                    : t("errors.wrong")
                  : t("errors.wrong");
                showError();
              } else {
                setLoading(false);
                if (response.status >= 400 && response.status < 500) {
                  message = response.message
                    ? response.message
                    : response.errors
                      ? response.errors
                      : t("errors.wrong");
                  showError();
                } else {
                  message = response.message
                    ? response.message !== ""
                      ? response.message
                      : t("errors.wrong")
                    : t("errors.wrong");
                  showError();
                }
              }
            });
        }
        else {
          setQuantityError(true);
        }
      }
    } else {
      setError(true);
    }
  };

  const handleClose = () => {
    props.close(false);
  };
  useEffect(() => {
    if (props.language?.toLowerCase() === "en") {
      if (refundMethod.value === refundOptions_ar[0].value) {
        setRefundMethod(refundOptions_en[0]);
      } else if (refundMethod.value === refundOptions_ar[1].value) {
        setRefundMethod(refundOptions_en[1]);
      }
    } else {
      if (refundMethod.value === refundOptions_en[0].value) {
        setRefundMethod(refundOptions_ar[0]);
      } else if (refundMethod.value === refundOptions_en[1].value) {
        setRefundMethod(refundOptions_ar[1]);
      }
    }
    // eslint-disable-next-line
  }, [props.language]);

  const onReasonChanged = (e) => {
    setDescription(e.target.value);
  }

  return (
    <Modal show={props.show} dialogClassName="mt-70" onHide={handleClose}>
      <Modal.Body>
        <div className="create-modal">
          <div className="header-row w-100">
            {props.name === "disputes"
              ? t("create-dispute.create-dispute")
              : t("create-dispute.create-refund")}
          </div>
          <div
            className="boday-row w-100"
            style={{
              direction: props.language.toLowerCase() === "en" ? "ltr" : "rtl",
            }}
          >
            <div className="input-group-row w-100 d-flex flex-column">
              <div className="input-row d-flex align-items-center w-100">
                <div className="label">{t("create-dispute.order-no")}</div>
                <div className="value">
                  <div className="select">{props.item.order_id}</div>
                </div>
              </div>
              <div className="input-row d-flex align-items-center w-100">
                <div className="label">{t("create-dispute.item")}</div>
                <div className="value">
                  <div className="select">
                    {(props.item.orderable[`product_name_${props.language}`]
                      ? props.item.orderable[`product_name_${props.language}`]
                      : "") +
                      "" +
                      props.item.orderable[`name_${props.language}`]}
                  </div>
                </div>
              </div>
              <div className="input-row d-flex align-items-center w-100">
                <div className="label">{t("returns.quantity-purchased")}:</div>
                <div className="value">
                  <div className="select">{props.item.quantity}</div>
                </div>
              </div>
              {props.name === "refund" && (
                <div className="input-row d-flex align-items-center w-100">
                  <div className="label">
                    {t("create-dispute.refund-method")}
                  </div>
                  <div className="value">
                    {props.item.payment_methods?.payment_card ? (
                      <div className="select">
                        <Select
                          value={refundMethod}
                          onChange={(value) => setRefundMethod(value)}
                          options={
                            props.language === "en"
                              ? refundOptions_en
                              : refundOptions_ar
                          }
                          isSearchable={false}
                        />
                      </div>
                    ) : (
                      refundMethod.value
                    )}
                  </div>
                </div>
              )}

              {
                props.name === "refund" && (
                  <div className="input-row description-row d-flex align-items-center w-100">
                    <div className="label">
                      {t("returns.quantity")}
                    </div>
                    <div className="value">
                      <input
                        name="quantity"
                        type="number"
                        className="form-control"
                        value={quantity}
                        onChange={(e) => setQuantity(e.target.value)}
                        rows={5}
                        style={{ padding: "5px" }}
                      />
                      {quantityError && (
                        <div className="error">
                          Quantity is required
                        </div>
                      )}
                    </div>
                  </div>
                )
              }

              <div className="input-row description-row d-flex align-items-center w-100">
                <div className="label">
                  {props.name === "disputes"
                    ? t("create-dispute.description")
                    : t("create-dispute.reason")}
                  :
                </div>
                <div className="value">
                  <label>
                    <input
                      type="radio"
                      value="Product doesn't meet expectations"
                      checked={description == "Product doesn't meet expectations" ? true : false}
                      onChange={onReasonChanged}
                    />&nbsp;Product doesn't meet expectations
                  </label><br />
                  <label>
                    <input
                      type="radio"
                      value="Inaccurate website description"
                      checked={description == "Inaccurate website description" ? true : false}
                      onChange={onReasonChanged}
                    />&nbsp;Inaccurate website description
                  </label><br />
                  <label>
                    <input
                      type="radio"
                      value="Better price available"
                      checked={description == "Better price available" ? true : false}
                      onChange={onReasonChanged}
                    />&nbsp;Better price available
                  </label><br />
                  <label>
                    <input
                      type="radio"
                      value="Bought by mistake"
                      checked={description == "Bought by mistake" ? true : false}
                      onChange={onReasonChanged}
                    />&nbsp;Bought by mistake
                  </label><br />
                  <label>
                    <input
                      type="radio"
                      value="No longer needed"
                      checked={description == "No longer needed" ? true : false}
                      onChange={onReasonChanged}
                    />&nbsp;No longer needed
                  </label><br />
                  <label>
                    <input
                      type="radio"
                      value="Wrong item was sent"
                      checked={description == "Wrong item was sent" ? true : false}
                      onChange={onReasonChanged}
                    />&nbsp;Wrong item was sent
                  </label><br />
                  <label>
                    <input
                      type="radio"
                      value="Item arrived too late"
                      checked={description == "Item arrived too late" ? true : false}
                      onChange={onReasonChanged}
                    />&nbsp;Item arrived too late
                  </label><br />
                  <label>
                    <input
                      type="radio"
                      value="Didn't approve purchase"
                      checked={description == "Didn't approve purchase" ? true : false}
                      onChange={onReasonChanged}
                    />&nbsp;Didn't approve purchase
                  </label><br />
                  <label>
                    <input
                      type="radio"
                      value="Product and shipping box both damaged"
                      checked={description == "Product and shipping box both damaged" ? true : false}
                      onChange={onReasonChanged}
                    />&nbsp;Product and shipping box both damaged
                  </label><br />
                  <label>
                    <input
                      type="radio"
                      value="Missing or broken parts"
                      checked={description == "Missing or broken parts" ? true : false}
                      onChange={onReasonChanged}
                    />&nbsp;Missing or broken parts
                  </label><br />
                  <label>
                    <input
                      type="radio"
                      value="Received extra item I didn't buy (no refund needed)"
                      checked={description == "Received extra item I didn't buy (no refund needed)" ? true : false}
                      onChange={onReasonChanged}
                    />&nbsp;Missing or broken parts
                  </label><br />
                  <label>
                    <input
                      type="radio"
                      value="Product damaged, but shipping box OK"
                      checked={description == "Product damaged, but shipping box OK" ? true : false}
                      onChange={onReasonChanged}
                    />&nbsp;Product damaged, but shipping box OK
                  </label><br />
                  <label>
                    <input
                      type="radio"
                      value="Incompatible or not useful"
                      checked={description == "Incompatible or not useful" ? true : false}
                      onChange={onReasonChanged}
                    />&nbsp;Incompatible or not useful
                  </label><br />
                  <label>
                    <input
                      type="radio"
                      value="Item defective or doesn't work"
                      checked={description == "Item defective or doesn't work" ? true : false}
                      onChange={onReasonChanged}
                    />&nbsp;Item defective or doesn't work
                  </label>
                </div>
                {error && (
                  <div className="error">
                    {props.name === "disputes" ? "Description " : "Reason "}
                    is required
                  </div>
                )}
              </div>
            </div>
            <div className="buttons-row w-100 d-fex justify-content-start align-items-center">
              <button
                className="cancel-button"
                type="button"
                onClick={handleClose}
              >
                {t("create-dispute.cancel-btn")}
              </button>
              <button
                className="create-button btn"
                type="button"
                onClick={Create}
              >
                {loading ? (
                  <div className="d-flex justify-content-center align-items-center w-100">
                    <ClipLoader color="#fff" loading size={20} />
                  </div>
                ) : (
                  t("create-dispute.create-btn")
                )}
              </button>
            </div>
          </div>
        </div>
        {redirect.value && <Redirect push to={redirect.path} />}
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    countryUrl: state.language.countryUrl,
    language: state.language.language,
  };
};

export default connect(mapStateToProps, null)(CreateModal);
