import React, { Fragment, useEffect, useRef } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import ProductCard from "./ProductCard";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation } from "swiper/core";

import "swiper/swiper.scss";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";

import "../../assets/styles/product/productlist.scss";

import { getDesktopImage } from "../../utils/utils.js";
import CartSidebar from "../customer/cart/CartSidebar";
import { useOutsideAlerter } from "../../utils/CustomHooks";

const screenBreakpoints = {
  md: 768,
  lg: 992,
  xl: 1024,
  xxl: 1200,
  xxxl: 1440,
};
SwiperCore.use([Pagination, Navigation]);

const OffersVariantList = (props) => {
  const [width, setWidth] = useState(window.innerWidth);
  const sideCartRef = useRef(null);
  const [showCart, setShowCart] = useState(false);
  useOutsideAlerter(sideCartRef, setShowCart);

  const setShowCartSidebar = (val) => {
    setShowCart(val);
  }

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  window.addEventListener("resize", handleWindowSizeChange);

  const productCountPerSlideView = () => {
    if (width >= screenBreakpoints.xxxl) {
      return 5;
    } else if (width >= screenBreakpoints.xxl) {
      return 5;
    } else if (width >= screenBreakpoints.xl) {
      return 4;
    } else if (width >= screenBreakpoints.lg) {
      return 3;
    } else if (width >= screenBreakpoints.md) {
      return 3;
    } else {
      return 5;
    }
  };

  return (
    <Fragment>
      <div className="product-list-desktop">
        <div className="product-list-container mx-auto">
          <Swiper
            spaceBetween={15}
            slidesPerView={productCountPerSlideView()}
            pagination={{
              clickable: true,
            }}
            navigation={true}
          >
            {props.products.map((product) =>
              <SwiperSlide key={product.id + Math.random() + "prod"}>
                <ProductCard
                  key={product.id + Math.random() + "prod"}
                  home={props.home}
                  product={{
                    id: product.variant.product_id,
                    slug: product.variant.product_slug,
                    line_item_id: product.variant.id,
                    img: getDesktopImage(
                      product.variant.featured_image_resized,
                      product.variant.featured_image
                    ),
                    type: "products",
                    description: product.variant.product_name_en,
                    storeLogo: product.variant.store_logo,
                    description_en: product.variant.product_name_en,
                    category_en: product.variant.category_title_en,
                    price_after_discount: parseFloat(
                      product.variant.discounted_price
                    ),
                    price_before_discount: parseFloat(
                      product.variant.price
                    ),
                    rating: parseInt(product.variant.average_rating),
                    count: product.variant.reviews_count,
                    wishlisted: product.variant.wishlisted,
                    sku: product.variant.sku,
                    coupon: product.variant.coupon,
                    is_inquiry: product.is_inquiry,
                    product_type: product.variant.product_type,
                    quantity: product.variant.quantity
                  }}
                  onQuickViewClicked={props.onQuickViewClicked}
                  setShowCartSidebar={setShowCartSidebar}
                />
              </SwiperSlide>
            )}
          </Swiper>
        </div>
        {showCart && <CartSidebar refs={sideCartRef} bool={showCart} />}
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    language: state.language.language,
  };
};

export default connect(mapStateToProps, null)(OffersVariantList);
