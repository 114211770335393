import React, { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import "../../../assets/styles/pages/customer/AllItems/ItemsPage.scss";
import { set_all_items } from "../../../redux/actions/AllItemsAction";
import { connect } from "react-redux";
import { apiJson } from "../../../Api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Row from "react-bootstrap/Row";
import MobileLayoutRenderer from "../../mobile/layout/MobileLayoutRenderer";
import { set_loading } from "../../../redux/actions/LayoutAction";
import ProductCard from "../../../components/mobile/Home/ProductCardMobile";
import pagesbannerimg from "../../../assets/images/footer-page-images/offers-top-banner.jpg";
import ramadanimg1 from "../../../assets/images/offers/Mobile-HOT-DEALS.jpg";
import ramadanimg2 from "../../../assets/images/offers/center-img-mobile.jpg";
import ramadanimg3 from "../../../assets/images/offers/right-img-mobile.jpg";
import "../../../assets/styles/footer-pages/offersmob.scss";
import HeaderMobile from "../../../components/mobile/HeaderMobile";
import { FiTruck } from "react-icons/fi";
import { useTranslation } from "react-i18next";
import NotifyUser from "../../../components/product/NotifyUser";

import a1 from "../../../assets/images/individual-pages/paints/Rollers.png";
import a2 from "../../../assets/images/individual-pages/paints/Interior-Paint.png";
import a3 from "../../../assets/images/individual-pages/paints/Exterior-Paint.png";
import a4 from "../../../assets/images/individual-pages/paints/Primers-_-Stucco.png";
import a5 from "../../../assets/images/individual-pages/paints/Wood-Finishes.png";
import a6 from "../../../assets/images/individual-pages/paints/Epoxy.png";
import a7 from "../../../assets/images/individual-pages/paints/Brushes.png";
import a8 from "../../../assets/images/individual-pages/paints/All-Purpose-Paint.png";
import a9 from "../../../assets/images/individual-pages/paints/Marking-Paints.png";

function Offers(props) {
  const [offers_sections, setOffersSections] = useState([]);
  const [top_banner, setTopBanner] = useState();
  const [boxes_banners, setBoxesBanners] = useState([]);
  const [showNotifyModel, setShowNotifyModel] = useState(false);
  const [orderableId, setOrderableId] = useState(null);
  const { t } = useTranslation();

  let message = "";

  useEffect(() => {
    document.title = "Alisuq.com - Offers";
  }, []);

  useEffect(() => {
    apiJson.offers.getTopBanner(1).then(async (response) => {
      if (response.success) {
        setTopBanner(response.data);
      }
    });

    apiJson.offers.getBoxesBanners(1).then(async (response) => {
      if (response.success) {
        setBoxesBanners(response.data);
      }
    });
    apiJson.offers.offers_sections(props.countryId)
      .then(response => {
        if (response.success) {
          setOffersSections(response.data.sections);
        }
      });
  }, []);

  return (
    <div className="items-page-mobile items-page-desktop">

      <MobileLayoutRenderer
        NavbarTop={false}
        BackBtn={false}
        CancelBtn={false}
        NavbarBottom={true}
        SearchIcon={true}
      >
        <div className="home-mob">
          <HeaderMobile />
        </div>
        <img src={top_banner?.mobile_image} style={{ width: "100%", height: "140px" }} />
        <div className="freeshipmob">

          <p className="m-0 text-center freshiptxt"><FiTruck size={18} />&nbsp;&nbsp;Free shipping on orders above AED 99</p>

        </div>
        <div className="row offerrow xyz"
          style={{
            marginLeft: "0px !important",
            marginRight: "0px !important",
            width: "100%",
          }}
        >
          {
            boxes_banners.length > 0 ?
              boxes_banners.map(banner => {
                return (
                  <div className="col-lg-4 col-md-4 col-sm-4 col-4">
                    <a href={banner.link}>
                      <img className="img-fluid"
                        src={banner.mobile_image}
                        alt="category"
                        style={{
                          width: "100%",
                        }}
                      ></img>
                      <h6 className="offctxt">{banner.title}</h6>
                    </a>
                  </div>
                )
              })
              :
              <></>
          }
          {/* <div className="col-lg-4 col-md-4 col-sm-4 col-4">
            <a href="offers/section/168">
              <img className="img-fluid"
                src={ramadanimg2}
                alt="category"
                style={{
                  width: "100%",
                }}
              ></img>
              <h6 className="offctxt">Summer Specials</h6>
            </a>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4 col-4">
            <a href="offers/section/167">
              <img className="img-fluid"
                src={ramadanimg3}
                alt="category"
                style={{
                  width: "100%",
                }}
              ></img>
              <h6 className="offctxt">Buy 1 Get 1</h6>
            </a>
          </div> */}
        </div>

        {/* <div className="row offerrow xyz"
          style={{
            marginLeft: "0px !important",
            marginRight: "0px !important",
            width: "100%",
          }}
        >
          <div className="col-lg-4 col-md-4 col-sm-4 col-4">
            <a href="offers/section/166">
              <img className="img-fluid"
                src={ramadanimg1}
                alt="category"
                style={{
                  width: "100%",
                }}
              ></img>
              <h6 className="offctxt">Cleaners</h6>
            </a>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4 col-4">
            <a href="offers/section/168">
              <img className="img-fluid"
                src={ramadanimg2}
                alt="category"
                style={{
                  width: "100%",
                }}
              ></img>
              <h6 className="offctxt">Best Sellers</h6>
            </a>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4 col-4">
            <a href="offers/section/167">
              <img className="img-fluid"
                src={ramadanimg3}
                alt="category"
                style={{
                  width: "100%",
                }}
              ></img>
              <h6 className="offctxt">Up To 70% OFF</h6>
            </a>
          </div>
        </div> */}


        <div className="all-page-container m-auto  d-flex flex-row">
          <div className="mobile-sections">
            <div className="col-12">
              <h2 className="sectiontitle-individualpage-mob">Shop by Category</h2>
              <div className="emptyspacer10"></div>
            </div>
            <div className="container">

              <div className="row justify-content-center" >
                <div className="col d-flex justify-content-center"
                  style={{ padding: "10px" }}
                >
                  <Link to={{
                    pathname: "/" + props.countryUrl +
                      "-" + props.language + "/items/products",
                    search: "filter_by_category%5B%5D=274"
                  }}>
                    <img
                      src={a5}
                      className="img-fluid image paintcat"
                      style={{ width: "120px" }} alt="Image 5"
                    />
                  </Link>
                </div>
                <div className="col d-flex justify-content-center"
                  style={{ padding: "10px" }}
                >
                  <Link to={{
                    pathname: "/" + props.countryUrl +
                      "-" + props.language + "/items/products",
                    search: "filter_by_category%5B%5D=445"
                  }}>
                    <img
                      src={a8}
                      className="img-fluid image paintcat"
                      style={{ width: "120px" }}
                      alt="Image 5"
                    />
                  </Link>
                </div>
                <div className="col d-flex justify-content-center"
                  style={{ padding: "10px" }}
                >
                  <Link to={{
                    pathname: "/" + props.countryUrl +
                      "-" + props.language + "/items/products",
                    search: "filter_by_category%5B%5D=442"
                  }}>
                    <img
                      src={a4}
                      className="img-fluid image paintcat"
                      style={{ width: "120px" }} alt="Image 4"
                    />
                  </Link>
                </div>
              </div>


              <div className="row justify-content-center">
                <div className="col d-flex justify-content-center"
                  style={{ padding: "10px" }}
                >
                  <Link to={{
                    pathname: "/" + props.countryUrl +
                      "-" + props.language + "/items/products",
                    search: "filter_by_category%5B%5D=440"
                  }}>
                    <img
                      src={a3}
                      className="img-fluid image paintcat"
                      style={{ width: "120px" }} alt="Image 3"
                    />
                  </Link>
                </div>
                <div className="col d-flex justify-content-center"
                  style={{ padding: "10px" }}
                >
                  <Link to={{
                    pathname: "/" + props.countryUrl +
                      "-" + props.language + "/items/products",
                    search: "filter_by_category%5B%5D=444"
                  }}>
                    <img
                      src={a9}
                      className="img-fluid image paintcat"
                      style={{ width: "120px" }}
                      alt="Image 6"
                    />
                  </Link>
                </div>
                <div className="col d-flex justify-content-center"
                  style={{ padding: "10px" }}
                >
                  <Link to={{
                    pathname: "/" + props.countryUrl +
                      "-" + props.language + "/items/products",
                    search: "filter_by_category%5B%5D=424"
                  }}>
                    <img
                      src={a7}
                      className="img-fluid image paintcat"
                      style={{ width: "120px" }}
                      alt="Image 4"
                    />
                  </Link>
                </div>
              </div>
            </div>

            {
              offers_sections.map(section => {
                return (
                  <Row
                    key={0}
                    className={`product-list-row mx-auto`}
                  >
                    <div className="title-row">
                      <div className="title mobtit"
                        dangerouslySetInnerHTML={{
                          __html: section.title_en,
                        }}
                      ></div>
                      <div class="viewalldiv">
                        <Link
                          to={
                            "/" + props.countryUrl + "-" + props.language + "/offers/section/" + section.url
                          }
                          className="view-all-text">
                          {t("all-categories.view-all")}
                        </Link>
                      </div>
                    </div>
                    <div className="product-cont mt-3">
                      {section.offers_section_variants.map((data) => (
                        <Link
                          style={{ textDecoration: "none" }}
                          to={{
                            pathname:
                              "/" +
                              props.countryUrl +
                              "-" +
                              props.language +
                              `/${data.variant.category_title_en
                                .toLowerCase()
                                .replace(/ /g, "-")}/${data.variant.product_slug}`,
                            state: {
                              id: data.variant.id,
                              type: data.variant ? "products" : "services",
                              sku: data.variant.sku
                            },
                            search: "?sku=" + data.variant.sku
                          }}
                          key={data.variant.id + Math.random()}
                        >
                          <div
                            className="image-cont"
                            key={data.variant.id + Math.random() + "prod"}
                          >
                            <ProductCard
                              key={data.variant.id + Math.random()}
                              setShowNotifyModel={setShowNotifyModel}
                              setOrderableId={setOrderableId}
                              img={
                                data.variant
                                  ? data.variant.featured_image
                                  : data.featured_image
                              }
                              storeLogo={data.variant.store_logo}
                              wishlisted={
                                data.variant
                                  ? data.variant.wishlisted
                                  : data.wishlisted
                              }
                              home={true}
                              type={data.variant ? "products" : "services"}
                              id={data.variant ? data.variant.id : data.id}
                              rating={data.variant.average_rating}
                              reviews={data.variant.reviews_count}
                              name={data.variant[`product_name_${props.language}`] + " " + data.variant[`name_${props.language}`]}
                              data={data.variant ? data.variant : data}
                              coupon={data.variant?.coupon}
                              is_inquiry={data.is_inquiry}
                            />
                          </div>
                        </Link>
                      ))}
                    </div>
                    <div />
                  </Row>
                )
              })
            }
            <div className="emptyspacer"></div>
            <div className="row">
              <div className="col-12">
                <h2 className="sectiontitle-individualpage-mob">Shop by Brands</h2>
                <div className="emptyspacer10"></div>
              </div>
              <div className="container">

                <div className="row justify-content-center mx-0" >
                  <div className="col d-flex justify-content-center"
                    style={{ padding: "5px" }}
                  >
                    <Link to={{
                      pathname: "/" + props.countryUrl +
                        "-" + props.language + "/items/products",
                      search: "filter_by_brand%5B%5D=42"
                    }}>
                      <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/hSZ6J8vxQ8pENansr4opLk.png"

                        className="img-fluid image paintcat"
                        style={{ width: "120px" }} alt="Image 1"
                      />
                    </Link>
                  </div>
                  <div className="col d-flex justify-content-center"
                    style={{ padding: "5px" }}
                  >
                    <Link to={{
                      pathname: "/" + props.countryUrl +
                        "-" + props.language + "/items/products",
                      search: "filter_by_brand%5B%5D=48"
                    }}>
                      <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/fwyPyYsDLWYNkLtUk8MS4E.png"
                        className="img-fluid image paintcat"
                        style={{ width: "120px" }} alt="Image 3"
                      />
                    </Link>
                  </div>
                  <div className="col d-flex justify-content-center"
                    style={{ padding: "5px" }}
                  >
                    <Link to={{
                      pathname: "/" + props.countryUrl +
                        "-" + props.language + "/items/products",
                      search: "filter_by_brand%5B%5D=43"
                    }}>
                      <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/iReSSYpWhmJcPBKxns3BgJ.png"
                        className="img-fluid image paintcat"
                        style={{ width: "120px" }} alt="Image 3"
                      />
                    </Link>
                  </div>
                </div>


                <div className="row justify-content-center mx-0" >
                  <div className="col d-flex justify-content-center"
                    style={{ padding: "5px" }}
                  >
                    <Link to={{
                      pathname: "/" + props.countryUrl +
                        "-" + props.language + "/items/products",
                      search: "filter_by_brand%5B%5D=50"
                    }}>
                      <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/7H64tmqZpw7VVwrbm5eB4v.png"
                        className="img-fluid image paintcat"
                        style={{ width: "120px" }} alt="Image 3"
                      />
                    </Link>
                  </div>
                  <div className="col d-flex justify-content-center"
                    style={{ padding: "5px" }}
                  >
                    <Link to={{
                      pathname: "/" + props.countryUrl +
                        "-" + props.language + "/items/products",
                      search: "filter_by_brand%5B%5D=49"
                    }}>
                      <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/7ZMiKGr5RjQgKztQv6iQjt.png"
                        className="img-fluid image paintcat"
                        style={{ width: "120px" }} alt="Image 3"
                      />
                    </Link>
                  </div>
                  <div className="col d-flex justify-content-center"
                    style={{ padding: "5px" }}
                  >
                    <Link to={{
                      pathname: "/" + props.countryUrl +
                        "-" + props.language + "/items/products",
                      search: "filter_by_brand%5B%5D=49"
                    }}>
                      <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/7ZMiKGr5RjQgKztQv6iQjt.png"
                        className="img-fluid image paintcat"
                        style={{ width: "120px" }} alt="Image 3"
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MobileLayoutRenderer>

      <NotifyUser
        setShowNotifyModel={setShowNotifyModel}
        showNotifyModel={showNotifyModel}
        id={orderableId}
        language={props.language}
        isSmall={true}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    seller: state.allItems.seller,
    filter_by: state.allItems.filter_by,
    brand: state.allItems.brand,
    items: state.allItems.items,
    countryId: state.language.countryId,
    countryUrl: state.language.countryUrl,
    width: state.layout.screenWidth,
    language: state.language.language,
  };
};

const mapDispatchToProps = {
  set_all_items,
  set_loading,
};

export default connect(mapStateToProps, mapDispatchToProps)(Offers);