import React, { Fragment } from "react";
import "../../../assets/styles/customer/account/returns.scss";
import { FiCreditCard } from "react-icons/fi";

import { Link } from "react-router-dom";
import { getDesktopImage } from "../../../utils/utils.js";

import EmptyPage from "../../../utils/EmptyPage";

import EmptyImage from "../../../assets/images/returns_art.svg";
import moment from "moment";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

const Returns = (props) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <div className="customer-returns w-100">
        <div className="d-flex justify-content-between header-row">
          <div className="left">
            <h3>{t("returns.title")}</h3>
          </div>
          <div className="right">
            <Link
              to={
                "/" +
                props.countryUrl +
                "-" +
                props.language +
                "/order/itemslist/refund"
              }
            >
              <button type="button" className="btn return-request">
                {t("returns.file-new-btn")}
              </button>
            </Link>
          </div>
        </div>
        {props.items.length > 0 ? (
          props.items.map((details, index) => {
            return (
              <div className="customer-returns w-100" key={index}>
                <div className="returns-data">
                  <div className=" return-head upper row no-gutters w-100">
                    <div className=" return-head-text">
                      {t("returns.return-id")} {details.id}
                    </div>
                    <div
                      className="return-fade-text"
                      style={{ marginInlineStart: "24px" }}
                    >
                      {t("returns.placed-date")}{" "}
                      {moment(new Date(details.created_at)).format("ll")}
                    </div>
                  </div>
                  <div className="return-detail-box w-100">
                    <div className="row first-box border-bottom no-gutters">
                      <div
                        className="col-md-6 col-lg-7 return-details"
                        style={{ borderInlineEnd: "1px solid #dad8d8" }}
                      >
                        <h5 className="return-head-text header">
                          {t("returns.pickup-adrs")}
                        </h5>
                        <div className="return-fade-text">
                          <p>
                            {details.address.first_name}
                          </p>
                          <p> {details.address.address1}</p>
                          <p className="mb-2">
                            {details.address.contact_number}
                          </p>
                        </div>
                      </div>
                      <div
                        className={`return-details col-md-6 col-lg-5${
                          details?.status === "rejected" ||
                          details?.status === "requested"
                            ? " d-flex justify-flex-start align-items-center"
                            : ""
                        }`}
                      >
                        {(details?.status === "approved" ||
                          details?.status === "completed") && (
                          <div>
                            <div className="return-head-text header">
                              {t("returns.refund-method")}
                            </div>
                            {details?.status === "approved" && (
                              <div className="return-fade-text">
                                <FiCreditCard className="credit-card" />
                                {details?.refund_method
                                  ? t(
                                      "returns.refund-to-" +
                                        details?.refund_method
                                    )
                                  : t("returns.refund-to-wallet")}
                              </div>
                            )}
                            {details?.status === "completed" && (
                              <div className="return-fade-text">
                                <FiCreditCard className="credit-card" />
                                {details?.refund_method
                                  ? t(
                                      "returns.refunded-to-" +
                                        details?.refund_method
                                    )
                                  : t("returns.refunded-to-wallet")}
                              </div>
                            )}
                          </div>
                        )}
                        <div
                          className="return-fade-text d-flex align-items-center"
                          style={{ marginTop: "5px", marginBottom: "10px" }}
                        >
                          <div
                            style={{
                              marginInlineEnd: "50px",
                              fontSize: "0.9rem",
                              fontWeight: "500",
                            }}
                          >
                            {t("returns.refund-status")}
                          </div>
                          <div
                            style={{
                              backgroundColor: "#e1f3f2",
                              borderRadius: "4px",
                              width: "fit-content",
                              padding: "2px 10px",
                              textTransform: "capitalize",
                            }}
                          >
                            {t(`returns.status.${details.status}`)}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row product-returns-details flex-row flex-nowrap no-gutters align-items-center w-100 ">
                      <div className="image-col">
                        <img
                          src={getDesktopImage(
                            details.line_item.featured_image_resized,
                            details.line_item.featured_image
                          )}
                          alt="Returns"
                          style={{
                            width: "auto",
                            height: "100%",
                            maxWidth: "100%",
                          }}
                        />
                      </div>
                      <div className="description-col d-flex flex-column">
                        <div className="return-fade-text mb-1">
                          {details.line_item[`store_name_${props.language}`]}
                        </div>
                        {details.line_item[`sku`] && (
                          <div className="return-fade-text mb-1">
                            {details.line_item[`sku`]}
                          </div>
                        )}
                        <div className="return-text mb-3">
                          {details.line_item[`product_name_${props.language}`]
                            ? details.line_item[
                                `product_name_${props.language}`
                              ] +
                              " " +
                              details.line_item[
                                `variant_name_${props.language}`
                              ]
                            : details.line_item[
                                `service_name_${props.language}`
                              ]}
                        </div>
                        <div className="return-text">
                          {details.line_item.currency.toUpperCase()}
                          <span className="font-weight ml-1">
                            {details.line_item.variant_price * details.quantity}
                          </span>
                        </div>
                        <div className="return-text">
                          {t("returns.quantity")}
                          <span className="font-weight ml-1">
                            {details.quantity}
                          </span>
                        </div>
                        <div className="return-text">
                          {t("returns.sold-by")}
                          <span className="font-weight ml-1">
                            {details.line_item[`store_name_${props.language}`]}
                          </span>
                        </div>
                        <div className="return-text">
                          <span
                            className="return-fade-text"
                            style={{ marginInlineEnd: "4px" }}
                          >
                            {t("returns.reason")}
                          </span>
                          {details.reason}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <EmptyPage
            img={EmptyImage}
            heading={t("returns.empty.heading")}
            subHeading={t("returns.empty.subheading")}
          />
        )}
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    direction: state.language.direction,
    language: state.language.language,
    countryUrl: state.language.countryUrl,
  };
};

export default connect(mapStateToProps, null)(Returns);
