import { Modal } from "react-bootstrap";
import "../../assets/styles/product/productcard.scss";
import { AiFillCheckCircle } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import "../../assets/styles/customer/assemblyservicemodel.scss";
import wrench from "../../assets/images/wrench.png";
import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";

const data = [
  "Team of 2 will arrive at the address you provide",
  "They will consult with you on where to assemble the item & assess any safety concerns",
  "Assemble your item and check that it works properly",
];

function AssemblyServiceModel({
  isSmall,
  addAssembly,
  setAddAssembly,
  assemblyPrice,
  onUpdate,
  showButton = false,
  isOpen = false,
  setIsOpen,
}) {
  const { t } = useTranslation();

  return (
    <>
      {!showButton && (
        <div
          style={{
            background: "#eff2f7",
            padding: "1rem 1.5rem",
            margin: "1rem 0",
            border: "1px solid #eff2f7",
            borderRadius: ".25rem",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <label style={{ display: "flex", gap: "10px" }}>
              <div
                className="addcheck"
                onClick={(e) => setAddAssembly(!addAssembly)}
              >
                {addAssembly ? (
                  <MdCheckBox color="#742013" size={24} />
                ) : (
                  <MdCheckBoxOutlineBlank color="#742013" size={24} />
                )}
              </div>
              <span style={{ display: "flex", alignItems: "center" }}>
                <img src={wrench} height={16} width={16} />
                {t("cart-sidebar.add-assembly")}
              </span>
            </label>
            <span style={{ fontWeight: 700, color: "#000"}} className="text">{assemblyPrice}</span>
          </div>

          <a
            href="#"
            style={{
              color: "#004ba0",
              fontWeight: 700,
              cursor: "pointer",
            }}
            className="text"
            onClick={(e) => {
              e.preventDefault();
              setIsOpen(true);
            }}
          >
            {t("cart-sidebar.add-assembly-more")}
          </a>
        </div>
      )}
      <Modal
        centered
        size={isSmall ? "sm" : "lg"}
        show={isOpen}
        onHide={() => {
          setIsOpen(false);
          onUpdate && onUpdate(false);
        }}
        closeModal={() => {
          setIsOpen(false);
          onUpdate && onUpdate(false);
        }}
        className={isSmall ? "service-modal-mobile" : "service-modal"}
      >
        <Modal.Header
          style={{
            justifyContent: "center",
            paddingBottom: "0px",
          }}
        >
          <Modal.Title>
            <h5
              style={{
                fontSize: "24px",
                padding: "0 1rem 1rem",
                margin: "0",
                fontWeight: 700,
              }}
              className="text"
            >
              {t("cart-sidebar.add-assembly-model")}
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              padding: isSmall ? "20px 40px" : "20px 50px",
              display: "grid",
              rowGap: "10px",
            }}
          >
            <span>{t("cart-sidebar.add-assembly-model-title1")}</span>
            <span>{t("cart-sidebar.add-assembly-model-title2")}</span>

            {data.map((item, index) => (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  marginBottom: "10px",
                }}
                key={index}
              >
                <div>
                  <AiFillCheckCircle
                    style={{ color: "#1f9f47", fontSize: "24px" }}
                  />
                </div>
                <span>{item}</span>
              </div>
            ))}
            {showButton && (
              <span
                type="button"
                className="quick-view-btn btn add_assembly"
                style={{
                  background: "#742013",
                  fontWeight: 700,
                  color: "white",
                  fontFamily: "Futura Heavy"
                }}
                key={assemblyPrice}
                onClick={() => {
                  onUpdate(true);
                  setIsOpen(false);
                }}
              >
                Add for {assemblyPrice}
              </span>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AssemblyServiceModel;
