import React, { useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import "../../assets/styles/header/allcategories.scss";
import { menu_categories } from "../../assets/allcategoriesmenu";

const AllCategories = (props) => {
  const [popular, setPopular] = useState(
    props.data.length > 0 ? props.data[0].sub_categories : []
  );
  const { t } = useTranslation();
  const [brands, setBrands] = useState(
    props.data.length > 0 ? props.data[0].brands : []
  );
  const [selectedCat, setSelectedCat] = useState(
    props.data.length > 0 ? props.data[0][`title_${props.language}`] : ""
  );
  const [isChild1Hover, setIsChild1Hover] = useState(false);
  const [child1Id, setChild1Id] = useState("");
  const [child1Title, setChild1Title] = useState("");
  const [child1Link, setChild1Link] = useState("");
  const [child1SearchQuery, setChild1SearchQuery] = useState("");
  const [child1Categories, setChild1Categories] = useState([]);

  const [isChild2Hover, setIsChild2Hover] = useState(false);
  const [child2Id, setChild2Id] = useState("");
  const [child2Title, setChild2Title] = useState("");
  const [child2Link, setChild2Link] = useState("");
  const [child2SearchQuery, setChild2SearchQuery] = useState("");
  const [child2Categories, setChild2Categories] = useState([]);

  const [isChild3Hover, setIsChild3Hover] = useState(false);
  const [child3Id, setChild3Id] = useState("");
  const [child3Title, setChild3Title] = useState("");
  const [child3Link, setChild3Link] = useState("");
  const [child3SearchQuery, setChild3SearchQuery] = useState("");
  const [child3Categories, setChild3Categories] = useState([]);

  const onParentHover = (category) => {
    setIsChild1Hover(true);
    setChild1Title(category[`title_${props.language}`]);
    setChild1Id(category.id);
    setChild1Link(category.link);
    setChild1SearchQuery(category.search);
    setChild1Categories(category.sub_categories);

    setIsChild2Hover(false);
    setChild2Id("");
    setChild2Title("");
    setChild2Link("");
    setChild2SearchQuery("");
    setChild2Categories([]);

    setIsChild3Hover(false);
    setChild3Id("");
    setChild3Title("");
    setChild3Link("");
    setChild3SearchQuery("");
    setChild3Categories([]);
  }

  const onChildHover = (category) => {
    setIsChild2Hover(true);
    setChild2Id(category.id);
    setChild2Title(category[`title_${props.language}`]);
    setChild2Link(category.link);
    setChild2SearchQuery(category.search);
    setChild2Categories(category.sub_categories);

    setIsChild3Hover(false);
    setChild3Id("");
    setChild3Title("");
    setChild3Link("");
    setChild3SearchQuery("");
    setChild3Categories([]);
  }

  const onChild2Hover = (category) => {
    setIsChild3Hover(true);
    setChild3Id(category.id);
    setChild3Title(category[`title_${props.language}`]);
    setChild3Link(category.link);
    setChild3SearchQuery(category.search);
    setChild3Categories(category.sub_categories);
  }

  const onViewMoreHover = (categories) => {
    setIsChild3Hover(true);
    setChild3Id("");
    setChild3Title("");
    setChild3Link("");
    setChild3SearchQuery("");
    setChild3Categories(categories);
  }

  return (
    <div className="all-categories">
      <div className="all-categories-row mx-0">
        <div className="all-categories-container">
          <div className="overlay"></div>
          <div
            className="colum-wrapper  d-flex justify-content-start"
            onMouseLeave={props.changestate}
            ref={props.allRef}
          >
            <div className={props.language === "ar" ? "all-categories-col all-categories-col-ar-padding" : "all-categories-col"}>
              <ul className="text-align">
                {props.data?.map((category) => (
                  <li
                    key={category.id}
                    onMouseEnter={() => {
                      console.log("hello", category.sub_categories)
                      setPopular(category.sub_categories);
                      setBrands(category.brands);
                      setSelectedCat(category[`title_${props.language}`]);
                    }}
                  >
                    <Link
                      to={{
                        pathname:
                          "/" +
                          props.countryUrl +
                          "-" +
                          props.language +
                          (window.location.href.includes("/wholesale") ? "/wholesale" : "") +
                          "/items/products",
                        // category.link,
                        // search: category.search
                        search: "?filter_by_category%5B%5D=" + category.id
                      }}
                      onMouseOver={() => onParentHover(category)}
                    >
                      {category[`title_${props.language}`]}
                    </Link>
                    {
                      isChild1Hover ?
                        child1Categories?.length > 0 ?
                          <ul className={props.language === "ar" ? "child-dropdown-ar child-dropdown1-ar" : "child-dropdown child-dropdown1"}>
                            <Link
                              to={{
                                pathname:
                                  "/" +
                                  props.countryUrl +
                                  "-" +
                                  props.language +
                                  (window.location.href.includes("/wholesale") ? "/wholesale" : "") +
                                  //   child1Link,
                                  // search: child1SearchQuery
                                  "/items/products",
                                search: "?filter_by_category%5B%5D=" + child1Id
                              }}
                              className="top-title">
                              {child1Title}
                            </Link>
                            {
                              child1Categories.map(subcategory => {
                                return (
                                  <li>
                                    <Link
                                      to={{
                                        pathname:
                                          "/" +
                                          props.countryUrl +
                                          "-" +
                                          props.language +
                                          (window.location.href.includes("/wholesale") ? "/wholesale" : "") +
                                          //   subcategory.link,
                                          // search: subcategory.search
                                          "/items/products",
                                        search: "?filter_by_category%5B%5D=" + subcategory.id
                                      }}
                                      onMouseOver={() => onChildHover(subcategory)}>
                                      {subcategory[`title_${props.language}`]}
                                    </Link>
                                    {
                                      isChild2Hover ?
                                        child2Categories?.length > 0 ?
                                          <ul className={props.language === "ar" ? "child-dropdown-ar child-dropdown2-ar" : "child-dropdown child-dropdown2"}>
                                            <Link
                                              to={{
                                                pathname:
                                                  "/" +
                                                  props.countryUrl +
                                                  "-" +
                                                  props.language +
                                                  (window.location.href.includes("/wholesale") ? "/wholesale" : "") +
                                                  //   child2Link,
                                                  // search: child2SearchQuery
                                                  "/items/products",
                                                search: "?filter_by_category%5B%5D=" + child2Id
                                              }}
                                              className="top-title">
                                              {child2Title}
                                            </Link>
                                            {
                                              child2Categories.map((child2, key) => {
                                                if (key < 15) {
                                                  return (
                                                    <li>
                                                      <Link
                                                        to={{
                                                          pathname:
                                                            "/" +
                                                            props.countryUrl +
                                                            "-" +
                                                            props.language +
                                                            (window.location.href.includes("/wholesale") ? "/wholesale" : "") +
                                                            //   child2.link,
                                                            // search: child2.search
                                                            "/items/products",
                                                          search: "?filter_by_category%5B%5D=" + child2.id
                                                        }}
                                                        onMouseOver={() => onChild2Hover(child2)}>
                                                        {child2[`title_${props.language}`]}
                                                      </Link>
                                                      {
                                                        isChild3Hover ?
                                                          child3Categories?.length > 0 ?
                                                            <ul className={props.language === "ar" ? "child-dropdown-ar child-dropdown3-ar" : "child-dropdown child-dropdown3"}>
                                                              <Link
                                                                to={{
                                                                  pathname:
                                                                    "/" +
                                                                    props.countryUrl +
                                                                    "-" +
                                                                    props.language +
                                                                    (window.location.href.includes("/wholesale") ? "/wholesale" : "") +
                                                                    //   child3Link,
                                                                    // search: child3SearchQuery
                                                                    "/items/products",
                                                                  search: "?filter_by_category%5B%5D=" + child3Id
                                                                }}
                                                                className="top-title">
                                                                {child3Title}
                                                              </Link>
                                                              {
                                                                child3Categories.map(child3 => {
                                                                  return (
                                                                    <li>
                                                                      <Link
                                                                        to={{
                                                                          pathname:
                                                                            "/" +
                                                                            props.countryUrl +
                                                                            "-" +
                                                                            props.language +
                                                                            (window.location.href.includes("/wholesale") ? "/wholesale" : "") +
                                                                            //   child3.link,
                                                                            // search: child3.search
                                                                            "/items/products",
                                                                          search: "?filter_by_category%5B%5D=" + child3.id
                                                                        }}
                                                                      >
                                                                        {child3[`title_${props.language}`]}
                                                                      </Link>
                                                                    </li>
                                                                  )
                                                                })
                                                              }
                                                            </ul>
                                                            :
                                                            <></>
                                                          :
                                                          <></>
                                                      }
                                                    </li>
                                                  )
                                                }
                                              })
                                            }
                                            {
                                              child2Categories?.length > 15 ?
                                                <li>
                                                  <Link onMouseOver={() => onViewMoreHover(child2Categories.splice(15, child2Categories?.length))}>View More</Link>
                                                </li>
                                                :
                                                <></>
                                            }
                                          </ul>
                                          :
                                          <></>
                                        :
                                        <></>
                                    }
                                  </li>
                                )
                              })
                            }
                          </ul>
                          :
                          <></>
                        :
                        <></>
                    }
                  </li>
                ))}
                <li>
                  <a className="ofrtxt-cat" href="/uae-en/offers">End of Year Sale</a>
                </li>
              </ul>
            </div>
            {/* <div className="category-col bg-white">
              <div className="all-category-header mx-3 mt-4 pb-2">
                {selectedCat}
              </div>
              <div className="category-detail d-flex flex-row justify-content-start">
                <div className="most-popular d-flex flex-column justify-contnet-start">
                  <div className="header mb-2 text-left">
                    {t("all-categories.most-popular")}
                  </div>
                  <ul>
                    {popular.map((subCat) => (
                      <li key={subCat.id}>
                        <Link
                          to={{
                            pathname:
                              "/" +
                              props.countryUrl +
                              "-" +
                              props.language +
                              "/all-categories",
                            state: {
                              title:
                                subCat.sub_categories?.length > 0
                                  ? [
                                      subCat.id,
                                      ...subCat.sub_categories.map(
                                        (subCat2) => subCat2.id
                                      ),
                                    ]
                                  : [subCat.id],
                            },
                          }}
                        >
                          {subCat[`title_${props.language}`]}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="top-brand d-flex flex-column justify-contnet-start">
                  <div className="header mb-2 text-left">
                    {t("all-categories.top-brands")}
                  </div>
                  <ul>
                    {brands.map((brand) => (
                      <li key={brand.id}>
                        <Link
                          to={{
                            pathname:
                              "/" +
                              props.countryUrl +
                              "-" +
                              props.language +
                              "/items/products",
                            state: { brand: brand.id },
                          }}
                        >
                          {brand[`name_${props.language}`]}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    language: state.language.language,

    countryUrl: state.language.countryUrl,
  };
};

export default connect(mapStateToProps, null)(AllCategories);
