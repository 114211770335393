import React, { useEffect } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { IoMdClose } from "react-icons/io";
import Logo from "../../assets/images/logo.png";
import "../../assets/styles/user-Auth-Option-1/signupmoboption1.scss";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import { useState } from "react";
import $ from "jquery";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import { set_user } from "../../redux/actions/AuthAction";
import { Redirect, useHistory, Link } from "react-router-dom";
import { apiJson } from "../../Api";

function SignupMobOption1(props) {
  const [redirectToSignIn, setRedirectToSignIn] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [redirect, setRedirect] = useState(false);
  const [data, setData] = useState(false);
  const history = useHistory();

  let message = "";
  const { t } = useTranslation();

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (firstName.length > 2 && lastName.length > 2) {
      const data =
        props.language === "en"
          ? {
            email,
            password,
            password_confirmation: password,
            first_name_en: firstName,
            last_name_en: lastName,
            user_key: localStorage.getItem("user_key")
              ? localStorage.getItem("user_key")
              : "",
          }
          : {
            email,
            password,
            password_confirmation: password,
            first_name_ar: firstName,
            last_name_ar: lastName,
            user_key: localStorage.getItem("user_key")
              ? localStorage.getItem("user_key")
              : "",
          };
      setError(false);
      if (password.length < 6) {
        setError(true);
        setErrorMessage("Password length must be 6 characters long");
      } else {
        setData(true);
        $.auth
          .emailSignUp(data)
          .then((response) => {
            localStorage.removeItem("user_key");
            localStorage.removeItem("guest_user_address");
            localStorage.setItem(
              "access_token",
              $.auth.retrieveData("authHeaders")["access-token"]
            );
            localStorage.setItem(
              "client",
              $.auth.retrieveData("authHeaders").client
            );
            localStorage.setItem("uid", $.auth.retrieveData("authHeaders").uid);

            props.set_user({ isSignedIn: true, user: response.data });
            setData(false);

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              'event': 'signup',
              'signupMethod': 'email',
              'page': 'Thank You Page'
            });

            setRedirect(true);
          })
          .fail((error) => {
            setData(false);
            if (error.data.message) {
              message = error.data.message;
              showError();
            } else if (error.data.errors) {
              message = error.data.errors.full_messages
                ? error.data.errors.full_messages[0]
                : error.data.errors[0]
                  ? error.data.errors[0]
                  : error.data.errors;
              showError();
            } else {
              message = t("errors.network");
              showError();
            }
          });
      }
    } else {
      message = "First and Last name length must be greater than 2 characters";
      showError();
    }
  };

  var getCookies = function () {
    var pairs = document.cookie?.split(";");
    var cookies = {};
    for (var i = 0; i < pairs.length; i++) {
      var pair = pairs[i].split("=");
      cookies[(pair[0] + "").trim()] = unescape(pair.slice(1).join("="));
    }
    return cookies;
  };

  useEffect(() => {
    if (
      getCookies()?.authHeaders &&
      $?.auth?.retrieveData("authHeaders")?.client
    ) {
      apiJson.auth
        .validateLoginToken({
          headers: {
            client: $?.auth?.retrieveData("authHeaders")?.client,
            "access-token":
              $?.auth?.retrieveData("authHeaders")["access-token"],
            uid: $?.auth?.retrieveData("authHeaders")?.uid,
          },
        })
        .then((response) => {
          const role = response?.data?.data?.roles?.filter(
            (role) => role === "customer"
          )?.length;
          if (response.success && role > 0) {
            localStorage.removeItem("user_key");
            localStorage.removeItem("guest_user_address");
            localStorage.setItem(
              "access_token",
              $.auth.retrieveData("authHeaders")["access-token"]
            );
            localStorage.setItem(
              "client",
              $.auth.retrieveData("authHeaders").client
            );
            localStorage.setItem("uid", $.auth.retrieveData("authHeaders").uid);

            props.set_user({
              isSignedIn: true,
              user: response.data.data,
            });
            setRedirect(true);
          } else {
            props.set_user({ isSignedIn: false, user: {} });
            localStorage.removeItem("access_token");
            localStorage.removeItem("client");
            localStorage.removeItem("uid");
            document.cookie =
              "authHeaders=; path=/; expires=Thu, 21 Aug 2014 20:00:00 UTC";
          }
        });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="signup-mob-option-1 ml-auto mr-auto">
      <div className="wrapper">
        <div className="logo-and-icon ">
          <div className="logo-cont">
            <div className="logo-size">
              <Link to={"/" + props.countryUrl + "-" + props.language}>
                <img
                  onClick={() => setRedirect(true)}
                  src={Logo}
                  width="100%"
                  height="100%"
                  alt=""
                />
              </Link>
            </div>
          </div>
          <div
            onClick={() =>
              props.address ? history.goBack() : setRedirect(true)
            }
            className="cross-icon"
          >
            <IoMdClose size="40" />
          </div>
        </div>
        <div className="create-account-title">
          <span>{t("signup.mobile.create")}</span>
        </div>
        <label className="e-label">{t("signup.mobile.first-name")}</label>
        <div className="d-flex email">
          <input
            className="e-input"
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            placeholder={t("signup.mobile.fn-placeholder")}
          />
        </div>
        <label className="e-label">{t("signup.mobile.last-name")}</label>

        <div className="d-flex email">
          <input
            className="e-input"
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            placeholder={t("signup.mobile.ln-placeholder")}
          />
        </div>
        <label className="e-label">{t("signup.mobile.email")}</label>

        <div className="d-flex email">
          <input
            className="e-input"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder={t("signup.mobile.e-placeholder")}
          />
        </div>
        <label className="e-label">{t("signup.mobile.pw-label")}</label>

        <div className="d-flex pass-cont justify-content-center align-items-center">
          <input
            className="password"
            type={`${showPass ? "text" : "password"}`}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder={t("signup.mobile.pw-placeholder")}
          />
          {showPass ? (
            <BsFillEyeFill
              onClick={() => setShowPass(!showPass)}
              className="eye-icon"
              size="14px"
              color="rgb(126, 133, 155)"
            />
          ) : (
            <BsFillEyeSlashFill
              onClick={() => setShowPass(!showPass)}
              className="eye-icon"
              size="14px"
              color="rgb(126, 133, 155)"
            />
          )}
        </div>
        {error && (
          <div
            style={{
              color: "red",
              fontSize: "12px",
              textAlign: "center",
              marginTop: "4px",
            }}
          >
            {errorMessage}
          </div>
        )}
        <div onClick={handleSubmit} className="sign-up">
          {data ? (
            <div className="d-flex justify-content-center align-items-center w-100">
              <ClipLoader color="#fff" loading size={20} />
            </div>
          ) : (
            t("signup.mobile.sign-up")
          )}
        </div>
        <div
          className="signin"
          onClick={() =>
            props.address
              ? props.signinClicked(false)
              : setRedirectToSignIn(true)
          }
        >
          {t("signup.already-have-account")}{" "}
          <span className="signin-text">{t("signup.sign-in")}</span>
        </div>
        <p>By creating an account, you agree to Alisuq's&nbsp;<Link to={props.countryUrl + "-" + props.language + "/terms-and-conditions"}>Conditions of Use</Link> and <Link to={props.countryUrl + "-" + props.language + "/privacy-policy"}>Privacy Policy</Link></p>
        {redirect && !props.address && (
          <Redirect
            push
            to={"/" + props.countryUrl + "-" + props.language + "/"}
          />
        )}
        {redirectToSignIn && <Redirect push to="/login" />}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    language: state.language.language,
    countryUrl: state.language.countryUrl,
  };
};

const mapDispatchToProps = {
  set_user,
};

export default connect(mapStateToProps, mapDispatchToProps)(SignupMobOption1);
