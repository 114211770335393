import React, {
  Fragment,
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import ClipLoader from "react-spinners/ClipLoader";
import "../../../assets/styles/component/customer/checkout/quantitycounter.scss";

const QuantityCounter = (props, ref) => {
  const [count, setCount] = useState(props.value ? props.value : 1);

  const handleIncrement = () => {
    setCount(count + 1);
    props.addValue(count + 1);
  };

  const handleDecrement = () => {
    count !== 1 && setCount(count - 1);
    count !== 1 && props.subValue(count - 1);
    count === 1 && props.remove();
  };

  useImperativeHandle(
    ref,
    () => ({
      setCounValue(val) {
        setCount(val);
      },
    }),
    []
  );

  useEffect(() => setCount(props.value), [props?.value]);

  return (
    <Fragment>
      <div className="d-flex buttons-desktop">
        {!props.isQunatityUpdatedable && (
          <button
            disabled={props.loading}
            type="button"
            onClick={handleDecrement}
            className="bg-white border-end"
          >
            -
          </button>
        )}
        <span
          className="bg-white same-width fs-15 d-flex align-items-center justify-content-center"
          style={{ width: "40px", padding: "5px 0px", height: "31px" }}
        >
          {props.loading ? (
            <div className="d-flex justify-content-center align-items-center w-100 h-100">
              <ClipLoader color="#000" size={17} />
            </div>
          ) : (
            count
          )}
        </span>
        {!props.isQunatityUpdatedable && (
          <button
            disabled={props.loading}
            type="button"
            onClick={handleIncrement}
            className="bg-white border-start"
          >
            +
          </button>
        )}
      </div>
    </Fragment>
  );
};

export default forwardRef(QuantityCounter);
